import { connect } from 'react-redux'
import DashStatic from '../../../../pages/main/Entrepot/dashboard/TabPanels/DashStatic'

import { getCollaborateur } from '../../../../redux/user/selectors'

const mapStateToProps = (state) => ({
  collaborateur: getCollaborateur(state),
})

export default connect(mapStateToProps)(DashStatic)
