import { connect } from 'react-redux'
import ModificationCourses from '../../../pages/main/Programmer/Plannification/UnservedOrders/modificationCoursesDialog'

import {
  getGoogleMapDirectionRequest,
  resetDirections,
} from '../../../redux/googleMapDirections/actions'
import {
  getDirectionRoute,
  getIsGetDirectionRouteLoading,
} from '../../../redux/googleMapDirections/selectors'
import {
  updateCoursePolaris,
  updateActionCoursePolaris,
  updateCourseOptimisationFile,
  changeStatusModificationAdresse,
} from '../../../redux/simulateTournee/actions'
import {
  getIsLoadingUpdateCoursePolaris,
  getisUpdateCoursePolarisAction,
  getSimulationIsError,
  getIsOptimisationCoursesFiles,
  getFileNameCoursesOptimisation,
} from '../../../redux/simulateTournee/selectors'

const mapStateToProps = (state) => ({
  isOptimisationCoursesFiles: getIsOptimisationCoursesFiles(state),
  /* */
  isGetDirectionRouteLoading: getIsGetDirectionRouteLoading(state),
  directionRoute: getDirectionRoute(state),
  isLoadingUpdateCoursePolaris: getIsLoadingUpdateCoursePolaris(state),
  isErrorUpdateCoursePolaris: getSimulationIsError(state),
  isUpdateCoursePolarisAction: getisUpdateCoursePolarisAction(state),
  fileNameCoursesOptimisation: getFileNameCoursesOptimisation(state),
})

const mapDisptachToProps = (dispatch) => ({
  changeStatusModificationAdresse: () => dispatch(changeStatusModificationAdresse()),
  /* */

  getGoogleMapDirection: (id, origin, destination) =>
    dispatch(getGoogleMapDirectionRequest(id, origin, destination)),
  resetDirections: () => dispatch(resetDirections()),

  updateCourse: (course) => dispatch(updateCoursePolaris(course)),
  updateActionCoursePolaris: () => dispatch(updateActionCoursePolaris()),
  updateCourseOptimisationFile: (course) =>
    dispatch(updateCourseOptimisationFile(course)),
})

export default connect(mapStateToProps, mapDisptachToProps)(ModificationCourses)
