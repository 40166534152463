import queryString from 'query-string'
import { httpFetch, httpPost } from '../http/actions'
import moment from 'moment'
import {
  GET_ETIQUETTE_LIST,
  GET_ETIQUETTE_LIST_SUCCESS,
  GET_ETIQUETTE_LIST_FAILURE,
  GENERATION_ETIQUETTE,
  GENERATION_PREPARATION,
  GENERATION_TRANSFERT,
  GENERATION_BONLIVRAISON,
  GENERATION_BONLIVRAISON_COURSE,
  GET_LIVRAISON_LIST,
  GET_LIVRAISON_LIST_SUCCESS,
  GET_LIVRAISON_LIST_FAILURE,
  GET_FREIGHTS_LIST,
  GET_FREIGHTS_LIST_SUCCESS,
  GET_FREIGHTS_LIST_FAILURE,
  CLOTURE_RECEPTION_FREIGHTS,
  GET_HISTORIQUE_FREIGHTS,
  GET_HISTORIQUE_FREIGHTS_SUCCESS,
  GET_HISTORIQUE_FREIGHTS_FAILURE,
  GENERATION_QRCODE,
  GET_ETIQUETTE_LIST_MORE,
  GET_ETIQUETTE_LIST_MORE_SUCCESS,
  GET_ETIQUETTE_LIST_MORE_FAILURE,
  GENERATION_TOURNEE,
  DOWNLOAD_KILOMETRAGE_TOURS,
  GET_CANAL_VENTE_DASH,
  GET_CANAL_VENTE_DASH_SUCCESS,
  GET_CANAL_VENTE_DASH_MORE,
  GET_CANAL_VENTE_DASH_MORE_SUCCESS,
  GET_CANAL_VENTE_DASH_FAILURE,
} from './constants'
import { axiosHTTP } from '../../utils'
import { clotureReceptionSuccess, clotureReceptionFailure } from './actions'

const api = process.env.REACT_APP_API_URL

let CancelToken = axiosHTTP.CancelToken
let cancel
let cancelDash
export default (store) => (next) => (action) => {
  const token = localStorage.getItem('token')
  const today = new Date()
  const tomorrow = new Date(today)
  tomorrow.setDate(tomorrow.getDate() + 1)

  if (action.type === GET_ETIQUETTE_LIST) {
    store.dispatch(
      httpFetch({
        endPoint: `${api}stock/lettreDeVoiture/byBarCode?${queryString.stringify(
          action.payload
        )}`,
        success: GET_ETIQUETTE_LIST_SUCCESS,
        failure: GET_ETIQUETTE_LIST_FAILURE,
      })
    )
  }

  if (action.type === GET_ETIQUETTE_LIST_MORE) {
    store.dispatch(
      httpFetch({
        endPoint: `${api}stock/lettreDeVoiture/byBarCode?${queryString.stringify(
          action.payload
        )}`,
        success: GET_ETIQUETTE_LIST_MORE_SUCCESS,
        failure: GET_ETIQUETTE_LIST_MORE_FAILURE,
      })
    )
  }

  if (action.type === GENERATION_ETIQUETTE) {
    const { barcode, ldv } = action.payload

    const dataSend = {
      barcode: barcode,
      ldv: ldv,
      token: token,
    }
    window.open(`${api}generateEtiquette?${queryString.stringify(dataSend)}`)
  }

  if (action.type === GENERATION_TRANSFERT) {
    const {
      filters: { filter },
    } = store.getState()

    const filterSend = {
      dateLivraison: filter.dropOffStart,
      warehouses:
        filter?.warehouse?.length > 0 ? filter.warehouse.join(',') : undefined,
      macroCanals: filter?.client?.length > 0 ? filter.client.join(',') : undefined,
      token: token,
    }
    if (filterSend.dateLivraison == undefined) {
      filterSend.dateLivraison = moment(tomorrow).format('YYYY-MM-DD')
    }

    window.open(`${api}GenerateTransferts?${queryString.stringify(filterSend)}`)
  }

  if (action.type === GENERATION_PREPARATION) {
    const {
      filters: { filter },
    } = store.getState()
    const filterSend = {
      dateLivraison: filter.dropOffStart,
      warehouses:
        filter?.warehouse?.length > 0 ? filter.warehouse.join(',') : undefined,
      macroCanals: filter?.client?.length > 0 ? filter.client.join(',') : undefined,
      token: token,
    }
    if (filterSend.dateLivraison == undefined) {
      filterSend.dateLivraison = moment(tomorrow).format('YYYY-MM-DD')
    }
    window.open(`${api}GeneratePreparations?${queryString.stringify(filterSend)}`)
  }

  if (action.type === GENERATION_BONLIVRAISON) {
    const {
      filters: { filter },
    } = store.getState()
    const filterSend = {
      dateLivraison: filter.dropOffStart,
      warehouses:
        filter?.warehouse?.length > 0 ? filter.warehouse.join(',') : undefined,
      macroCanals: filter?.client?.length > 0 ? filter.client.join(',') : undefined,
      token: token,
    }
    if (filterSend.dateLivraison == undefined) {
      filterSend.dateLivraison = moment(tomorrow).format('YYYY-MM-DD')
    }
    window.open(`${api}GenerateBls?${queryString.stringify(filterSend)}`)
  }

  if (action.type === GENERATION_BONLIVRAISON_COURSE) {
    const { ldv } = action.payload

    const dataSend = {
      ldv,
      token,
    }

    window.open(`${api}GenerateBls?${queryString.stringify(dataSend)}`)
  }

  if (action.type === GET_LIVRAISON_LIST) {
    // cancel the request
    if (cancel != undefined) {
      cancel()
    }

    const {
      filters: { filter },
    } = store.getState()

    const data = {
      dateLivraison: filter.dropOffStart,
      warehouses: filter?.warehouse?.length > 0 ? filter.warehouse : undefined,
      client: filter?.client?.length > 0 ? filter.client : undefined,
      limit: filter.limit,
      offset: filter.offset,
      courseType: filter?.courseType?.length > 0 ? filter.courseType : undefined,
      deliveryRelated:
        filter?.deliveryRelated != null ? filter.deliveryRelated : undefined,
    }
    store.dispatch(
      httpPost({
        endPoint: `${api}getCoursesByWarehouses`,
        data: data,
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c
        }),
        success: GET_LIVRAISON_LIST_SUCCESS,
        failure: GET_LIVRAISON_LIST_FAILURE,
      })
    )
  }

  if (action.type === GET_FREIGHTS_LIST) {
    // cancel the request
    if (cancel != undefined) {
      cancel()
    }

    const {
      filters: { filter },
    } = store.getState()
    const data = {
      receptionDateStart: filter.receptionDateStart,
      receptionDateEnd: filter.receptionDateEnd,
      limit: filter.limit,
      offset: filter.offset,
    }

    store.dispatch(
      httpFetch({
        endPoint: `${api}getAllFreights?${queryString.stringify(data)}`,
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c
        }),
        success: GET_FREIGHTS_LIST_SUCCESS,
        failure: GET_FREIGHTS_LIST_FAILURE,
      })
    )
  }
  if (action.type === CLOTURE_RECEPTION_FREIGHTS) {
    const { ids } = action.payload
    const connectedUser = JSON.parse(localStorage.getItem('currentUser'))

    Promise.all(
      ids.map((freight) => {
        try {
          const data = {
            freight,
            collaborateur: connectedUser.id,
            action: 'closed',
          }
          return axiosHTTP.post(`${api}freights/create`, data)
        } catch (err) {
          return err
        }
      })
    )
      .then((resp) => {
        console.log(resp)
        store.dispatch(clotureReceptionSuccess())
      })
      .catch((err) => {
        console.log(err)
        store.dispatch(clotureReceptionFailure())
      })
  }

  if (action.type === GET_HISTORIQUE_FREIGHTS) {
    const { id } = action.payload
    const data = {
      id,
    }

    store.dispatch(
      httpFetch({
        endPoint: `${api}getFreightTraceability?${queryString.stringify(data)}`,
        success: GET_HISTORIQUE_FREIGHTS_SUCCESS,
        failure: GET_HISTORIQUE_FREIGHTS_FAILURE,
      })
    )
  }

  if (action.type === GENERATION_QRCODE) {
    const { ids } = action.payload

    const dataSend = {
      ids: ids,
      token: token,
    }
    window.open(`${api}generateQrCode?${queryString.stringify(dataSend)}`)
  }

  if (action.type === GENERATION_TOURNEE) {
    const { id } = action.payload

    const dataSend = {
      tour: id,
      token: token,
    }
    window.open(`${api}generateTourPDF?${queryString.stringify(dataSend)}`)
  }

  if (action.type === DOWNLOAD_KILOMETRAGE_TOURS) {
    const dataSend = {
      ...action.payload,
      token: token,
    }
    window.open(`${api}generateKMTour?${queryString.stringify(dataSend)}`)
  }

  if (action.type === GET_CANAL_VENTE_DASH) {
    // cancel the request
    if (cancelDash != undefined) {
      cancelDash()
    }

    store.dispatch(
      httpFetch({
        endPoint: `${api}canalVente/getwithmetabaseurl?${queryString.stringify(
          action.payload
        )}`,
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancelDash = c
        }),
        success: GET_CANAL_VENTE_DASH_SUCCESS,
        failure: GET_CANAL_VENTE_DASH_FAILURE,
      })
    )
  }

  if (action.type === GET_CANAL_VENTE_DASH_MORE) {
    // cancel the request
    if (cancelDash != undefined) {
      cancelDash()
    }

    store.dispatch(
      httpFetch({
        endPoint: `${api}canalVente/getwithmetabaseurl?${queryString.stringify(
          action.payload
        )}`,
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancelDash = c
        }),
        success: GET_CANAL_VENTE_DASH_MORE_SUCCESS,
        failure: GET_CANAL_VENTE_DASH_FAILURE,
      })
    )
  }

  next(action)
}
