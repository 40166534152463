import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Badge from '@mui/material/Badge'

import MenuIcon from '@mui/icons-material/Menu'
import Drawer from './Drawer/TopMenuDrawer'
import CloseIcon from '@mui/icons-material/Close'
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Paper from '@mui/material/Paper'
import { AllLinks, leftLinks } from '../../../routes/NavRoutes'
import { useHistory, Link, useLocation } from 'react-router-dom'
import logoB2h from '../../../assets/icons/greenB.svg'
import LinkMaterial from '@mui/material/Link'
import SubNavListItem from './SubNavBar/subNavListItem'
import { styled } from '@mui/material/styles'

import ActionsMenu from './ActionsMenu'
import ActionsProfile from '../ActionsProfile'
import SettingsIcon from '../../../assets/icons/settingsCorner/settingsAppIcon'
import { Box, IconButton } from '@mui/material'
import {
  getActionRole,
  getPathRedirectRole,
  isPathInRoutes,
  routeCanAccess,
} from '../../../utils/roleManagement'

const iconStyle = {
  marginLeft: '8px',
  color: 'white',
  fontSize: '24px!important',
}
const StyledCloseIcon = styled(CloseIcon)(() => iconStyle)
const StyledMenuIcon = styled(MenuIcon)(() => iconStyle)

const StyledPaper = styled(Paper)(({ theme }) => ({
  '&.MuiPaper-root': {
    color: 'white',
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    boxShadow:
      '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 500,

    height: '55px',
  },
}))
const StyledTabs = styled(Tabs)(() => ({
  '&.MuiTabs-root': {
    overflow: 'auto',
  },
}))

const StyledDisabledLinkTab = styled(LinkTab)(() => ({
  minWidth: 30,
  opacity: 1,
  height: '55px !important',
  width: '54px !important',
  marginLeft: '5px !important',
}))

const StyledLinkTab = styled(LinkTab)(() => ({
  fontWeight: 500,
  opacity: 0.7,
  fontSize: 12,
  minWidth: 158,
  display: 'flex',
  flexDirection: 'row-reverse',
  lineHeight: 1.75,
  letterSpacing: '0.02857em',
  width: 182,
  padding: '6px 12px',
}))
const StyledSubNavListItem = styled(SubNavListItem)(() => ({
  opacity: 0.7,
  fontSize: 12,
  minWidth: 158,
  display: 'flex',
  flexDirection: 'row-reverse',
  lineHeight: 1.75,
  letterSpacing: '0.02857em',
  width: 182,
  padding: '6px 12px',
  fontFamily: `'Segoe UI', Roboto, 'Helvetica Neue', sans-serif`,
}))

export default function NavBar({
  userLogout,
  collaborateur,
  issocketNotRegistred,
  getNotificationConversationAction,
  nbNotif,
  registerSocket,
  getNotificationSocket,
  userRole,
}) {
  const CURRENT_USER = 'currentUser'
  const [connectedUser] = useState(JSON.parse(localStorage.getItem(CURRENT_USER)))
  const [isOrderPage, setisOrderPage] = useState(
    window.location.pathname.includes('Passer-une-commande')
  )
  const [isCommandeEnCours, setIsCommandeEnCours] = useState(
    window.location.pathname.includes('commandes') ||
      window.location.pathname === '/'
  )
  const [isProgrammer, setIsProgrammer] = useState(
    window.location.pathname.includes('programmer')
  )
  let history = useHistory()

  useEffect(() => {
    return history.listen((location) => {
      setisOrderPage(location.pathname.includes('Passer-une-commande'))
      setIsCommandeEnCours(
        location.pathname.includes('commandes') || window.location.pathname === '/'
      )
      setIsProgrammer(location.pathname.includes('programmer'))
    })
  }, [history])

  useEffect(() => {
    if (issocketNotRegistred) {
      registerSocket()
    }
  }, [])

  useEffect(() => {
    if (!issocketNotRegistred) {
      getNotificationSocket()
    }
  }, [issocketNotRegistred])

  useEffect(() => {
    getNotificationConversationAction(connectedUser.id)
  }, [])

  const matches = useMediaQuery('(min-width:1050px)')

  const [open, setOpen] = useState(false)
  const handleDrawer = () => {
    setOpen(!open)
    setValue(0)
  }
  const handleClose = () => {
    setOpen(false)
  }
  if (matches && open) {
    setOpen(false)
  }

  const [value, setValue] = useState(0)
  const handleChange = (event, newValue) => {
    setValue(matches ? newValue : 0)
  }

  const selectionnerMisePoint = (pathname) => {
    const mappedPaths = leftLinks.map((e) => {
      if (e?.children != undefined) {
        return e.children.some((c) => pathname === c.path)
      }
      return e.path == pathname
    })
    if (mappedPaths.indexOf(true) !== -1) {
      setValue(mappedPaths.indexOf(true) + 1)
    } else setValue(0)
  }

  useEffect(() => {
    if (matches) {
      selectionnerMisePoint(window.location.pathname)
    } else setValue(0)
  }, [])

  useEffect(() => {
    history.listen((location) => {
      if (matches) {
        selectionnerMisePoint(location.pathname)
      } else setValue(0)
    })
  }, [history])
  const location = useLocation()

  return (
    <>
      {isPathInRoutes(AllLinks, location.pathname, userRole) && (
        <Drawer
          handleDrawerClose={handleClose}
          open={open}
          userLogout={userLogout}
          nbNotif={nbNotif}
          userRole={userRole}
        />
      )}
      <StyledPaper>
        <StyledTabs
          value={value}
          onChange={handleChange}
          variant={'standard'}
          scrollButtons="auto"
          aria-label="auto tabs example"
          TabIndicatorProps={{
            style: {
              backgroundColor: matches ? '#F61057' : 'transparent',
              height: 3,
            },
          }}
        >
          {isPathInRoutes(AllLinks, location.pathname, userRole) && (
            <>
              {!matches && (
                <Tab
                  id="tab-responsive"
                  sx={{ minWidth: 30, padding: '16px 8px' }}
                  onClick={() => handleDrawer()}
                  icon={
                    <LinkMaterial variant="button">
                      {open ? <StyledCloseIcon /> : <StyledMenuIcon />}
                    </LinkMaterial>
                  }
                />
              )}
              <StyledDisabledLinkTab
                sx={{ minWidth: 40 }}
                icon={
                  <img
                    src={logoB2h}
                    id="logo-b2h"
                    alt="logo-b2h"
                    style={{ width: 30, height: 43.2 }}
                  />
                }
                value={1}
                to={getPathRedirectRole(userRole)}
              />
            </>
          )}
          {matches &&
            routeCanAccess(leftLinks, userRole).map((tab, index) =>
              tab.label === 'Discussion' ? (
                <Badge badgeContent={nbNotif} color="error" key={index}>
                  <StyledLinkTab
                    id="discussion"
                    sx={{
                      fontFamily: `'Segoe UI', Roboto, 'Helvetica Neue', sans-serif`,
                    }}
                    key={`LinkTab_${index}`}
                    label={tab.label}
                    value={index + 1}
                    to={tab.path[0]}
                  />
                </Badge>
              ) : (
                <>
                  {tab.children ? (
                    <StyledSubNavListItem
                      tab={tab}
                      value={index + 1}
                      index={`SubNavListItem_${index}`}
                    />
                  ) : (
                    <StyledLinkTab
                      id={`tab-${index}`}
                      key={`LinkTab_${index}`}
                      label={tab.label}
                      value={index + 1}
                      to={tab.path[0]}
                    />
                  )}
                </>
              )
            )}
        </StyledTabs>
        <div style={{ display: 'flex' }}>
          {getActionRole(userRole, 'actionCommandeMain') && (
            <ActionsMenu
              isOrderPage={isOrderPage}
              isCommandeEnCours={isCommandeEnCours}
              isProgrammer={isProgrammer}
              userRole={userRole}
            />
          )}
          {getActionRole(userRole, 'redirectSettingsMain') && (
            <Box sx={{ padding: '8px 14px' }}>
              <IconButton
                component={Link}
                to={'/settings/configuration-cle'}
                id={'button-settings-navbar'}
                sx={{
                  color: '#1976d2',
                  backgroundColor: '#8dbbe7',
                  width: '40px',
                  height: '40px',
                  '&:hover': {
                    color: '#ffff',
                  },
                }}
              >
                <SettingsIcon style={{ width: '26px', height: '26px' }} />
              </IconButton>
            </Box>
          )}
          <ActionsProfile userLogout={userLogout} collaborateur={collaborateur} />
        </div>
      </StyledPaper>
    </>
  )
}
NavBar.propTypes = {
  collaborateur: PropTypes.object.isRequired,
  userLogout: PropTypes.func.isRequired,
  socketNotRegistred: PropTypes.func,
  issocketNotRegistred: PropTypes.bool,
  getNotificationConversationAction: PropTypes.func,
  nbNotif: PropTypes.number,
  receiveAddNotification: PropTypes.func,
  receiveRemoveNotification: PropTypes.func,
  registerSocket: PropTypes.func,
  getNotificationSocket: PropTypes.func,
  userRole: PropTypes.string,
}

function LinkTab(props) {
  const handleClick = (event) => {
    if (props.to === window.location.pathname) {
      event.preventDefault() // prevent reloading if the link points to the current page
    }
  }

  return <Tab {...props} onClick={handleClick} component={Link} />
}

LinkTab.propTypes = {
  to: PropTypes.string,
}
