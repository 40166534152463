import {
  SET_FILTER_COMMANDE,
  SET_FILTER_PLANIFICATION,
  SET_FILTER_ENTREPOT,
  SET_FILTER_ERROR,
  REMOVE_FILTER_ERROR,
  GET_BUSINESS_OWNER,
  RESET_FILTER_COMMANDE,
  RESET_FILTER_PLANIFICATION,
  RESET_FILTER_ENTREPOT,
  SAVE_SEARCH_CORNER_ONGLET,
  SET_FILTER_FREIGHTS,
  RESET_FILTER_FREIGHTS,
  RESET_ALL_FILTER,
  SET_FILTER_TOUR,
  RESET_FILTER_TOUR,
  SET_FILTER_DISCUSSION,
  RESET_FILTER_DISCUSSION,
  GET_ALL_SUBJECT,
  FILTER_CHANGED,
  PAGE_CHANGED,
  PAGE_LIMIT_CHANGED,
  IS_OPEN_CHANGED,
  SEARCH_DISCUSSION,
  SET_SELECTED_DATE,
  FETCH_DRIVERS_FILTER,
  GET_PRESTATIONS,
  GET_DEPARTEMENTS,
  GET_MACRO_CANAL,
  GET_SALES_CHANNEL,
  GET_TOUR_LIST,
  GET_TOUR_LIST_MORE,
  GET_WAREHOUSE,
  GET_FILTER_PARAMS,
  RESET_FROMAPPLIQUER,
  GET_FILTER_COLLABORATEUR,
  SET_DEFAULT_FILTER_COMMANDE,
  SET_DEFAULT_FILTER_PLANIFICATION,
  SET_DEFAULT_FILTER_ENTREPOT,
  SAVE_DEFAULT_FILTER_COMMANDE,
  RESET_DEFAULT_FILTER_COMMANDE,
  DISPLAY_MODE_CHANGED,
  CREATE_FILTER_COLLABORATEUR,
  RESET_ACTIONS_CREATION_FILTER,
  DELETE_FILTER_COLLABORATEUR,
  RESET_ACTION_DELETE_FILTER,
  RENOMMER_FILTER_COLLABORATEUR,
  RESET_ACTION_FILTER,
  SET_ONGLET_FILTER_OPEN,
  MODIFICATION_FILTER_COLLABORATEUR,
  RESET_ACTIONS_MODIFICATION_FILTER,
  SAVE_SEARCH_ONGLET,
  SAVE_SEARCH_CORNER,
  RESET_FILTER_COLLABORATEUR,
} from './actionsConsts'

export const filterChanged = (filterValues) => ({
  type: FILTER_CHANGED,
  payload: { filterValues },
})

export const pageChanged = (offset) => ({
  type: PAGE_CHANGED,
  payload: { offset },
})

export const pageLimitChanged = (limit) => ({
  type: PAGE_LIMIT_CHANGED,
  payload: { limit },
})

export const isOpenChanged = (isOpen) => ({
  type: IS_OPEN_CHANGED,
  payload: { isOpen },
})

export const SearchChanged = (ldvSearch) => ({
  type: SEARCH_DISCUSSION,
  payload: { ldvSearch },
})

export const setSelectedDate = (pickedDate) => ({
  type: SET_SELECTED_DATE,
  payload: { pickedDate },
})

export const setFilterCommande = (filter, fromAppliquer) => ({
  type: SET_FILTER_COMMANDE,
  payload: { filter, fromAppliquer },
})

export const setFilterPlanification = (filter, fromAppliquer) => ({
  type: SET_FILTER_PLANIFICATION,
  payload: { filter, fromAppliquer },
})

export const setFilterEntrepot = (filter, fromAppliquer) => ({
  type: SET_FILTER_ENTREPOT,
  payload: { filter, fromAppliquer },
})

export const setFilterFreights = (filter, fromAppliquer) => ({
  type: SET_FILTER_FREIGHTS,
  payload: { filter, fromAppliquer },
})
export const setFilterTour = (filter, fromAppliquer) => ({
  type: SET_FILTER_TOUR,
  payload: { filter, fromAppliquer },
})
export const setFilterDiscussion = (filter, fromAppliquer) => ({
  type: SET_FILTER_DISCUSSION,
  payload: { filter, fromAppliquer },
})

export const setError = () => ({
  type: SET_FILTER_ERROR,
})
export const removeErrorFilter = () => ({
  type: REMOVE_FILTER_ERROR,
})

export const saveSearchCornerOnglet = (search) => ({
  type: SAVE_SEARCH_CORNER_ONGLET,
  payload: { search },
})

export const resetFilterCommande = () => ({
  type: RESET_FILTER_COMMANDE,
})

export const resetFilterDiscussion = () => ({
  type: RESET_FILTER_DISCUSSION,
})

export const resetFilterPlanification = () => ({
  type: RESET_FILTER_PLANIFICATION,
})

export const resetFilterEntrepot = () => ({
  type: RESET_FILTER_ENTREPOT,
})

export const resetFilterFreights = () => ({
  type: RESET_FILTER_FREIGHTS,
})

export const resetFilterTour = () => ({
  type: RESET_FILTER_TOUR,
})

export const resetAllFilter = () => ({
  type: RESET_ALL_FILTER,
})

//GET BUSINESS OWNER
export const onSearchBusinessOwner = (search) => ({
  type: GET_BUSINESS_OWNER,
  payload: { search },
})

//GET ALL SUBJECT
export const getAllSubject = () => ({
  type: GET_ALL_SUBJECT,
})

// GET DRIVERS
export const fetchtDriversFilter = () => ({
  type: FETCH_DRIVERS_FILTER,
  payload: {},
})

//GET MACRO CANAL
export const getMacroCanalRequest = () => ({
  type: GET_MACRO_CANAL,
  payload: {},
})

//GET DEPARTEMENT
export const getDepartementsListRequest = () => ({
  type: GET_DEPARTEMENTS,
  payload: {},
})

//GET PRESTATION
export const getPrestationsListRequest = () => ({
  type: GET_PRESTATIONS,
  payload: {},
})

//GET SALESCHANNELS
export const getSalesChannelListRequest = () => ({
  type: GET_SALES_CHANNEL,
  payload: {},
})

//get Tour list for filter
export const getTourList = (filter) => ({
  type: GET_TOUR_LIST,
  payload: filter,
})

export const getTourListMore = (filter) => ({
  type: GET_TOUR_LIST_MORE,
  payload: filter,
})

//GET WAREHOUSE
export const getWareHouseListRequest = () => ({
  type: GET_WAREHOUSE,
  payload: {},
})

//CHANGE PARAMS FILTER
export const getFilterParams = ({ count, limit, offset }) => ({
  type: GET_FILTER_PARAMS,
  payload: { count, limit, offset },
})

//RESET CLICK BUTTON APPLICQUER
export const resetFromAppliquer = () => ({
  type: RESET_FROMAPPLIQUER,
})

export const setDefaultFilterCommande = (filter) => ({
  type: SET_DEFAULT_FILTER_COMMANDE,
  payload: { filter },
})

export const setDefaultFilterPlanification = (filter) => ({
  type: SET_DEFAULT_FILTER_PLANIFICATION,
  payload: { filter },
})

export const setDefaultFilterEntrepot = (filter) => ({
  type: SET_DEFAULT_FILTER_ENTREPOT,
  payload: { filter },
})

//GET FILTER BY COLLABORATEUR
export const getFilterByCollaborateur = (collaborateurId, pageFilter) => ({
  type: GET_FILTER_COLLABORATEUR,
  payload: { collaborateurId, pageFilter },
})

// Save DefaultFilter Commande en cours
export const saveDefaultFilterCommander = () => ({
  type: SAVE_DEFAULT_FILTER_COMMANDE,
})

// RESET DefaultFilter Commande en cours
export const resetDefaultFilterCommander = () => ({
  type: RESET_DEFAULT_FILTER_COMMANDE,
})

//Change display mode (grid/table) (commande en cours)
export const displayModeChanged = () => ({
  type: DISPLAY_MODE_CHANGED,
})

//Creation new Filter
export const onCreateFilter = (payload) => ({
  type: CREATE_FILTER_COLLABORATEUR,
  payload,
})

//Reset Action Creation new Filter
export const resetActionCreationFilter = () => ({
  type: RESET_ACTIONS_CREATION_FILTER,
})

//Delete Filter
export const onDeleteFilter = (payload) => ({
  type: DELETE_FILTER_COLLABORATEUR,
  payload,
})

//Reset Action DELETE Filter
export const resetActionDeleteFilter = () => ({
  type: RESET_ACTION_DELETE_FILTER,
})

//Renommer Filter
export const onRennomerFilter = (payload) => ({
  type: RENOMMER_FILTER_COLLABORATEUR,
  payload,
})

//Reset Action DELETE Filter
export const resetActionFilter = () => ({
  type: RESET_ACTION_FILTER,
})

//Set id onglet filter  opnened
export const setOngletFilterIsOpen = (id) => ({
  type: SET_ONGLET_FILTER_OPEN,
  payload: { id },
})

//Modificaiton Filter
export const onModifierFilter = (payload) => ({
  type: MODIFICATION_FILTER_COLLABORATEUR,
  payload,
})

//Reset Action Modification new Filter
export const resetActionModifierFilter = () => ({
  type: RESET_ACTIONS_MODIFICATION_FILTER,
})

// SAVE SEARCH ONGLET
export const saveSearchOnglet = (search, posCollabFilter) => ({
  type: SAVE_SEARCH_ONGLET,
  payload: { search, posCollabFilter },
})

// SAVE SEARCH IN SEARCH AND DEFAULTSEARCH STATE
export const saveSearchCorner = (search) => ({
  type: SAVE_SEARCH_CORNER,
  payload: { search },
})

// Reset FiltersCollaborateur
export const resetFiltersCollaborateur = () => ({
  type: RESET_FILTER_COLLABORATEUR,
})
