export const OPTIMISER_DRIVER_GET = 'OPTIMISER_DRIVER_GET'
export const OPTIMISER_DRIVER_GET_SUCCESS = 'OPTIMISER_DRIVER_GET_SUCCESS'
export const OPTIMISER_DRIVER_GET_FAILURE = 'OPTIMISER_DRIVER_GET_FAILURE'

export const GET_DRIVER_WARRNING = 'GET_DRIVER_WARRNING'
export const GET_DRIVER_WARRNING_SUCCESS = 'GET_DRIVER_WARRNING_SUCCESS'
export const GET_DRIVER_WARRNING_FAILURE = 'GET_DRIVER_WARRNING_FAILURE'

export const GET_DRIVER_WARRNING_MORE = 'GET_DRIVER_WARRNING_MORE'
export const GET_DRIVER_WARRNING_MORE_SUCCESS = 'GET_DRIVER_WARRNING_MORE_SUCCESS'
export const GET_DRIVER_WARRNING_MORE_FAILURE = 'GET_DRIVER_WARRNING_MORE_FAILURE'
