import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import Dialog from '../../../../../components/ModalDialog'
import EditIcon from '@mui/icons-material/Edit'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Box from '@mui/material/Box'
import { TableVirtuoso } from 'react-virtuoso'

import {
  columnsListUservedOrdersModif,
  columnsOptimisationCoursesFileUservedOrdersModif,
} from './data'

import ColumnAddressComponentEditable from './TableContent/columnAddressComponentEditable'
import ColumnTextComponentEditable from './TableContent/columnTextComponentEditable'
import ColumnTimeRangeComponentEditable from './TableContent/columnTimeRangeComponentEditable'
import ColumnTextComponent from './TableContent/columnTextComponent'
import { getCreneauHoursMinutes } from '../../../../../utils/utils'
import moment from 'moment'
import { parseReceptionNumber } from '../../../../../utils/tours'
import _ from 'lodash'
import { ACCURATE_ADDRESS } from '../../../../../utils/values'

const VirtuosoTableComponents = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer {...props} ref={ref} sx={{ overflowX: 'unset' }} />
  )),
  Table: (props) => (
    <Table
      {...props}
      stickyHeader
      aria-label="sticky table"
      style={{ backgroundColor: 'white' }}
    />
  ),
  TableHead: React.forwardRef((props, ref) => (
    <TableHead
      {...props}
      ref={ref}
      sx={{
        position: 'sticky',
        zIndex: '5 !important',
      }}
    />
  )),
  TableRow,
  TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
}
VirtuosoTableComponents.Scroller.displayName = 'Scroller'
VirtuosoTableComponents.Table.displayName = 'Table'
VirtuosoTableComponents.TableHead.displayName = 'TableHead'
VirtuosoTableComponents.TableBody.displayName = 'TableBody'

function fixedHeaderContent(columnsList) {
  return (
    <TableRow>
      {[...columnsList].map((column) => (
        <TableCell
          key={column.dataKey}
          variant="head"
          align="center"
          style={{ width: column.minWidth, minWidth: column.minWidth }}
          sx={{ backgroundColor: 'background.paper', padding: '10px 0px' }}
        >
          {column.label}
        </TableCell>
      ))}
    </TableRow>
  )
}

const rowTabelCellContent = (
  row,
  rowNumber,
  isOptimisationCoursesFiles,
  getGoogleMapDirection,
  onChangeContentColumn,
  directionRoute,
  tableEditableRow
) => {
  const onChange = (value) => {
    onChangeContentColumn(row.id, value)
  }
  return {
    endLocation: (
      <ColumnAddressComponentEditable
        content={{
          longitude: row.endLocation.lng,
          latitude: row.endLocation.lat,
          address: row.endLocation?.name ? row.endLocation?.name : '-',
        }}
        id={`item-Courses-${rowNumber}-endLocation`}
        onChange={onChange}
        nameAttribut={'adresseArrivee'}
        adressAut={{
          longitude: row.startLocation.lng,
          latitude: row.startLocation.lat,
          address: row.startLocation.name,
        }}
        getGoogleMapDirection={getGoogleMapDirection}
        errorsCourseEditable={tableEditableRow[row.id]?.errors || []}
        isOptimisationCoursesFiles={isOptimisationCoursesFiles}
        directionRoute={directionRoute}
        valuesmodif={{
          longitude: tableEditableRow[row.id]?.content?.adresseArrivee?.longitude,
          latitude: tableEditableRow[row.id]?.content?.adresseArrivee?.latitude,
          address: tableEditableRow[row.id]?.content?.adresseArrivee?.address || '-',
        }}
      />
    ),
    volume: (
      <ColumnTextComponentEditable
        content={row?.volume >= 0 && row?.volume != null ? row?.volume + '' : '-'}
        id={`item-Courses-${rowNumber}-volume`}
        onChange={onChange}
        nameAttribut={'volume'}
        errorsCourseEditable={tableEditableRow[row.id]?.errors || []}
        valuesmodif={tableEditableRow[row.id]?.content?.volume || '-'}
      />
    ),
    weight: (
      <ColumnTextComponentEditable
        content={row?.weight >= 0 && row?.weight != null ? row?.weight + '' : '-'}
        id={`item-Courses-${rowNumber}-weight`}
        onChange={onChange}
        nameAttribut={'weight'}
        errorsCourseEditable={tableEditableRow[row.id]?.errors || []}
        valuesmodif={tableEditableRow[row.id]?.content?.weight || '-'}
      />
    ),
    price: (
      <ColumnTextComponentEditable
        content={
          row?.montantPrestaHT >= 0 && row?.montantPrestaHT != null
            ? row?.montantPrestaHT
            : '-'
        }
        id={`item-Courses-${rowNumber}-price`}
        onChange={onChange}
        nameAttribut={'price'}
        errorsCourseEditable={tableEditableRow[row.id]?.errors || []}
        valuesmodif={tableEditableRow[row.id]?.content?.weight || '-'}
      />
    ),

    ldv: (
      <ColumnTextComponent
        content={row?.ldv ? row?.ldv : '-'}
        id={`item-Courses-${rowNumber}-ldv`}
      />
    ),

    receptionNumber: (
      <ColumnTextComponent
        content={row?.id ? parseReceptionNumber(row?.id) : '-'}
        id={`item-Courses-${rowNumber}-receptionNumber`}
      />
    ),

    client: (
      <ColumnTextComponent
        content={row?.client ? row?.client : '-'}
        id={`item-Courses-${rowNumber}-receptionNumber`}
      />
    ),
    orderGiver: (
      <ColumnTextComponent
        content={row?.orderGiver ? row?.orderGiver : '-'}
        id={`item-Courses-${rowNumber}-receptionNumber`}
      />
    ),
    creneauArivee1: (
      <ColumnTimeRangeComponentEditable
        content={
          row?.dropOffStart && row?.dropOffEnd
            ? getCreneauHoursMinutes(row?.dropOffStart, row?.dropOffEnd, '~')
            : '-- : --'
        }
        contentEditable={{
          date: moment(row?.dropOffStart).format('YYYY-MM-DD'),
          heureStart: row?.dropOffStart
            ? moment(row?.dropOffStart).format('HH:mm')
            : null,
          heureEnd: row?.dropOffEnd ? moment(row?.dropOffEnd).format('HH:mm') : null,
        }}
        id={`item-Courses-${rowNumber}-creneauArivee1`}
        onChange={onChange}
        nameAttribut={'creneauArivee1'}
        errorsCourseEditable={tableEditableRow[row.id]?.errors || []}
        valuesmodif={{
          date: tableEditableRow[row.id]?.content?.dropOffStart
            ? moment(tableEditableRow[row.id]?.content?.dropOffStart).format(
                'YYYY-MM-DD'
              )
            : '-',
          heureStart: tableEditableRow[row.id]?.content?.dropOffStart
            ? moment(tableEditableRow[row.id]?.content?.dropOffStart).format('HH:mm')
            : null,
          heureEnd: tableEditableRow[row.id]?.content?.dropOffEnd
            ? moment(tableEditableRow[row.id]?.content?.dropOffEnd).format('HH:mm')
            : null,
        }}
      />
    ),
    creneauArivee2: (
      <ColumnTimeRangeComponentEditable
        contentEditable={{
          date: moment(row?.secondDropOffStart).format('YYYY-MM-DD'),
          heureStart: row?.secondDropOffStart
            ? moment(row?.secondDropOffStart).format('HH:mm')
            : null,
          heureEnd: row?.secondDropOffEnd
            ? moment(row?.secondDropOffEnd).format('HH:mm')
            : null,
        }}
        id={`item-Courses-${rowNumber}-creneauArivee2`}
        onChange={onChange}
        nameAttribut={'creneauArivee2'}
        errorsCourseEditable={tableEditableRow[row.id]?.errors || []}
        valuesmodif={{
          date: tableEditableRow[row.id]?.content?.secondDropOffStart
            ? moment(tableEditableRow[row.id]?.content?.secondDropOffStart).format(
                'YYYY-MM-DD'
              )
            : '-',
          heureStart: tableEditableRow[row.id]?.content?.secondDropOffStart
            ? moment(tableEditableRow[row.id]?.content?.secondDropOffStart).format(
                'HH:mm'
              )
            : null,
          heureEnd: tableEditableRow[row.id]?.content?.secondDropOffEnd
            ? moment(tableEditableRow[row.id]?.content?.secondDropOffEnd).format(
                'HH:mm'
              )
            : null,
        }}
      />
    ),
    nbColis: (
      <ColumnTextComponentEditable
        content={row?.nbColis >= 0 && row?.nbColis != null ? row?.nbColis : '-'}
        id={`item-Courses-${rowNumber}-nbColis`}
        onChange={onChange}
        nameAttribut={'count'}
        errorsCourseEditable={tableEditableRow[row.id]?.errors || []}
        valuesmodif={tableEditableRow[row.id]?.content?.count || '-'}
      />
    ),
    prestaTime: (
      <ColumnTextComponentEditable
        content={
          row?.prestaTime >= 0 && row?.prestaTime != null ? row?.prestaTime : '-'
        }
        id={`item-Courses-${rowNumber}-prestaTime`}
        onChange={onChange}
        nameAttribut={'prestaTime'}
        errorsCourseEditable={tableEditableRow[row.id]?.errors || []}
        valuesmodif={tableEditableRow[row.id]?.content?.prestaTime || '-'}
      />
    ),
  }
}

function rowContent(
  row,
  rowNumber,
  isOptimisationCoursesFiles,
  columnsList,
  getGoogleMapDirection,
  onChangeContentColumn,

  directionRoute,
  tableEditableRow
) {
  return (
    <React.Fragment>
      {[...columnsList].map(
        (column) =>
          rowTabelCellContent(
            row,
            rowNumber,
            isOptimisationCoursesFiles,
            getGoogleMapDirection,
            onChangeContentColumn,

            directionRoute,
            tableEditableRow
          )[column.id]
      )}
    </React.Fragment>
  )
}

const validationCourseEditable = (course, isOptimisationCoursesFiles) => {
  let errors = []
  if (
    isOptimisationCoursesFiles &&
    (Object.keys(course).includes('secondDropOffStart') ||
      Object.keys(course).includes('secondDropOffEnd')) &&
    moment(
      _.get(course, 'secondDropOffStart')
        ? _.get(course, 'secondDropOffStart')
        : course?.dropOffStart
    ).diff(
      moment(
        _.get(course, 'secondDropOffEnd')
          ? _.get(course, 'secondDropOffEnd')
          : course?.dropOffEnd
      )
    ) > 0
  ) {
    errors.push('creneau2')
  }

  if (
    isOptimisationCoursesFiles &&
    (Object.keys(course).includes('dropOffStart') ||
      Object.keys(course).includes('dropOffEnd')) &&
    moment(
      _.get(course, 'dropOffStart')
        ? _.get(course, 'dropOffStart')
        : course?.dropOffStart
    ).diff(
      moment(
        _.get(course, 'dropOffEnd')
          ? _.get(course, 'dropOffEnd')
          : course?.dropOffEnd
      )
    ) > 0
  ) {
    errors.push('creneau1')
  }

  if (
    Object.keys(course).includes('weight') &&
    (_.isNil(_.get(course, 'weight')) ||
      _.get(course, 'weight') === '' ||
      !_.isNumber(parseFloat(_.get(course, 'weight'))))
  ) {
    errors.push('weight')
  }

  if (
    Object.keys(course).includes('volume') &&
    (_.isNil(_.get(course, 'volume')) ||
      _.get(course, 'volume') === '' ||
      !_.isNumber(parseFloat(_.get(course, 'volume'))))
  ) {
    errors.push('volume')
  }

  if (
    isOptimisationCoursesFiles &&
    Object.keys(course).includes('prestaTime') &&
    (_.isNil(_.get(course, 'prestaTime')) ||
      _.get(course, 'prestaTime') === '' ||
      !_.isNumber(parseFloat(_.get(course, 'prestaTime'))))
  ) {
    errors.push('prestaTime')
  }

  if (
    isOptimisationCoursesFiles &&
    ((Object.keys(course).includes('count') &&
      (_.isNil(_.get(course, 'count')) ||
        _.get(course, 'count') === '' ||
        !_.isNumber(parseFloat(_.get(course, 'count'))))) ||
      (Object.keys(course).includes('nbColis') &&
        !Object.keys(course).includes('count') &&
        (_.isNil(_.get(course, 'nbColis')) ||
          _.get(course, 'nbColis') === '' ||
          !_.isNumber(parseFloat(_.get(course, 'nbColis'))))))
  ) {
    errors.push('count')
  }

  if (
    (Object.keys(course).includes('adresseArrivee') &&
      (_.isNil(_.get(course, 'adresseArrivee')) ||
        _.isNil(_.get(course, 'adresseArrivee.address')) ||
        _.isNil(_.get(course, 'adresseArrivee.latitude')) ||
        _.isNil(_.get(course, 'adresseArrivee.longitude')))) ||
    (Object.keys(course).includes('endLocation') &&
      !Object.keys(course).includes('adresseArrivee') &&
      (_.isNil(_.get(course, 'endLocation')) ||
        _.isNil(_.get(course, 'endLocation.name')) ||
        _.isNil(_.get(course, 'endLocation.lat')) ||
        _.isNil(_.get(course, 'endLocation.lng'))))
  ) {
    errors.push('adresseArrivee')
  }

  return errors
}

const ModificationCoursesDialog = ({
  open,
  isOptimisationCoursesFiles,
  unservedOrders,
  handleClose,
  getGoogleMapDirection,
  isErrorUpdateCoursePolaris,
  changeStatusModificationAdresse,
  isGetDirectionRouteLoading,
  directionRoute,
  updateCourse,
  isLoadingUpdateCoursePolaris,
  isUpdateCoursePolarisAction,
  updateActionCoursePolaris,
  resetDirections,
  updateCourseOptimisationFile,
  fileNameCoursesOptimisation,
}) => {
  const columnsList = isOptimisationCoursesFiles
    ? columnsOptimisationCoursesFileUservedOrdersModif
    : columnsListUservedOrdersModif
  const [tableEditableRow, setTableEditableRow] = useState({})
  useEffect(() => {
    if (unservedOrders.length > 0) {
      let tableEditableRowParse = {}
      unservedOrders.forEach((c) => {
        tableEditableRowParse = {
          ...tableEditableRowParse,
          [c.id]: {
            id: c.id,
            codeCanal: c?.salesChanel?.code,
            content:
              c.endLocation.name != undefined &&
              c.endLocation.name != null &&
              isOptimisationCoursesFiles &&
              !ACCURATE_ADDRESS.includes(c.locationType)
                ? { adresseArrivee: { locationType: 'ROOFTOP' } }
                : {},
            errors: validationCourseEditable(c, isOptimisationCoursesFiles),
          },
        }
      })
      setTableEditableRow({ ...tableEditableRowParse })
    }
  }, [unservedOrders])

  const onChangeContentColumn = (codeCourse, value) => {
    const tabEditable = tableEditableRow
    tabEditable[codeCourse] = {
      ...tabEditable[codeCourse],
      content: {
        ...tabEditable[codeCourse].content,
        ...value,
      },
    }

    tabEditable[codeCourse] = {
      ...tabEditable[codeCourse],
      errors: validationCourseEditable(
        tabEditable[codeCourse].content,
        isOptimisationCoursesFiles
      ),
    }
    setTableEditableRow({ ...tabEditable })
  }

  const isDisabledButtonUpdate = () => {
    return (
      Object.keys(tableEditableRow).some(
        (codeCourse) =>
          tableEditableRow[codeCourse]?.errors &&
          tableEditableRow[codeCourse].errors.length > 0
      ) ||
      Object.keys(tableEditableRow).every(
        (codeCourse) =>
          tableEditableRow[codeCourse]?.content &&
          Object.keys(tableEditableRow[codeCourse].content).length == 0
      )
    )
  }

  const onToggleCancel = () => {
    setTableEditableRow({})
    resetDirections()
    changeStatusModificationAdresse()
  }

  const onToggleUpdateCourse = () => {
    const arrayUpdateCourse = Object.values(tableEditableRow)
      .filter((row) => Object.keys(row.content).length > 0)
      .map((row) => {
        return {
          ...row.content,
          ...(isOptimisationCoursesFiles
            ? { receptionNumber: row.id, fileName: fileNameCoursesOptimisation }
            : { codeCourse: row.id, codeCanal: row?.codeCanal }),
        }
      })
    isOptimisationCoursesFiles
      ? updateCourseOptimisationFile(arrayUpdateCourse)
      : updateCourse(arrayUpdateCourse)
  }

  useEffect(() => {
    if (isUpdateCoursePolarisAction) {
      onToggleCancel()
      handleClose()
      updateActionCoursePolaris()
    }
  }, [isUpdateCoursePolarisAction])

  return (
    <Dialog
      fullWidth={true}
      maxWidthDialog={'xl'}
      open={open}
      title={'Modifier les courses'}
      style={isErrorUpdateCoursePolaris ? { display: 'none' } : {}}
      iconTitle={
        <EditIcon
          sx={{
            width: '20px',
            height: '20px',
            marginRight: '9px',
          }}
        />
      }
      content={
        <Box
          style={{
            paddingRight: '16px',
            height: 400,
            width: '100%',
          }}
        >
          <TableVirtuoso
            data={unservedOrders}
            components={VirtuosoTableComponents}
            fixedHeaderContent={() => fixedHeaderContent(columnsList)}
            itemContent={(index, row) =>
              rowContent(
                row,
                index,
                isOptimisationCoursesFiles,
                columnsList,
                getGoogleMapDirection,
                onChangeContentColumn,
                directionRoute,
                tableEditableRow
              )
            }
          />
        </Box>
      }
      handleClose={() => {
        handleClose()
        onToggleCancel()
      }}
      handleClickAction={onToggleUpdateCourse}
      disabled={isDisabledButtonUpdate() || isGetDirectionRouteLoading}
      isLoadingButtonAction={isLoadingUpdateCoursePolaris}
    />
  )
}

ModificationCoursesDialog.propTypes = {
  open: PropTypes.bool,
  isModifAdresse: PropTypes.bool,
  isOptimisationCoursesFiles: PropTypes.bool,
  unservedOrders: PropTypes.object,
  handleClose: PropTypes.func,
  isErrorUpdateCoursePolaris: PropTypes.bool,
  changeStatusModificationAdresse: PropTypes.func,
  isGetDirectionRouteLoading: PropTypes.bool,
  getGoogleMapDirection: PropTypes.func,
  directionRoute: PropTypes.object,
  updateCourse: PropTypes.func,
  isLoadingUpdateCoursePolaris: PropTypes.bool,
  isUpdateCoursePolarisAction: PropTypes.bool,
  updateActionCoursePolaris: PropTypes.bool,
  resetDirections: PropTypes.func,
  updateCourseOptimisationFile: PropTypes.func,
  fileNameCoursesOptimisation: PropTypes.string,
}

export default ModificationCoursesDialog
