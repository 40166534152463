import Orders from '../../containers/main/Orders'
import PlaceOrder from '../../containers/main/PlaceOrders/PlaceOrder'
import EditOrder from '../../containers/main/EditOrders/EditOrder'
import Programmer from '../../containers/main/Programmer'
import DiscussionPage from '../../containers/main/discussion'
import DetailsDelivery from '../../containers/main/DetailsDelivery'
import EntrepotDashboard from '../../pages/main/Entrepot/dashboard'
import EntrepotDocuments from '../../containers/main/Entrepot/documents'
import Freights from '../../containers/main/Entrepot/freights'
import Tournee from '../../containers/main/Tournee'

export const mainRoutes = [
  {
    path: ['/commandes', '/'],
    component: Orders,
    canAccess: [],
  },
  {
    path: '/Passer-une-commande',
    component: PlaceOrder,
    canAccess: [],
  },
  {
    path: '/programmer',
    component: Programmer,
    canAccess: [],
  },
  {
    path: '/planification/tourPolaris',
    component: Tournee,
    canAccess: [],
  },
  {
    path: '/edit/:codeCourse',
    component: EditOrder,
    canAccess: [],
  },
  {
    path: '/discussion',
    component: DiscussionPage,
    canAccess: [],
  },
  {
    path: '/deliveries/:ldv',
    component: DetailsDelivery,
    canAccess: [],
  },
  {
    path: '/entrepot/tableau-de-bord',
    component: EntrepotDashboard,
    canAccess: [],
  },
  {
    path: '/entrepot/documents',
    component: EntrepotDocuments,
    canAccess: [],
  },
  {
    path: '/entrepot/tourneeReception',
    component: Freights,
    canAccess: [],
  },
]
