import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { rightLinks } from '../../../../routes/NavRoutes'
import { Link } from 'react-router-dom'

import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import IconMenuAction from '../../../../assets/icons/navBar/MaterialSymbolsPostAdd'

import Popover from '@mui/material/Popover'
import List from '@mui/material/List'
import { StyledListItemActions, StyledListItemTextActions } from '../../style'
import IconButtonCommander from '../../../../assets/icons/navBar/MaterialSymbolsFactCheckOutline'
import IconButtonImporter from '../../../../assets/icons/navBar/UiwDownload'
import CoursesImportation from '../../../../containers/main/NavBar/CoursesImportation'
import IconOptimisationCourse from '../../../../assets/icons/navBar/IconOptimisationCourse'
import CoursesOptimisation from '../../../../containers/main/NavBar/CoursesOptimisation'
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined'
import TourneesImportation from '../../../../containers/main/NavBar/TourneesImportation'

const ITEM_HEIGHT = 48

export default function ActionsMenu({
  isOrderPage,
  isCommandeEnCours,
  isProgrammer,
}) {
  const [anchorEl, setAnchorEl] = useState(null)
  const [openMenu, setOpenMenul] = useState(false)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
    setOpenMenul(!openMenu)
  }
  const handleCloseMenuBarre = () => {
    setAnchorEl(null)
    setOpenMenul(false)
  }

  const [openDialogImportationCourses, setOpenDialogImportationCourses] =
    useState(false)
  const [openDialogOptimisationCourses, setOpenDialogOptimisationCourses] =
    useState(false)
  const [openDialogImportationTournees, setOpenDialogImportationTournees] =
    useState(false)

  return (
    <>
      {isCommandeEnCours || isProgrammer ? (
        <>
          <div style={{ display: 'flex', padding: '8px 0px' }}>
            <IconButton
              id={'button-actions-menu'}
              onClick={handleClick}
              sx={{
                color: '#1976d2',
                backgroundColor: '#8dbbe7',
                width: '40px',
                height: '40px',
                '&:hover': {
                  color: '#ffff',
                },
              }}
              aria-describedby={'simple-popover'}
            >
              <IconMenuAction style={{ width: '26px', height: '26px' }} />
            </IconButton>
          </div>
          <Popover
            id={'popover-actions'}
            open={openMenu}
            anchorEl={anchorEl}
            onClose={handleCloseMenuBarre}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 3,
                width: '27.5ch',
              },
            }}
            sx={{ zIndex: 25001, top: '4px', left: '10px' }}
          >
            <List id={'popover-list'} component="nav">
              <StyledListItemActions
                id={'button-Commander'}
                button
                value={rightLinks[0].path[0]}
                to={rightLinks[0].path[0]}
                component={Link}
                onClick={() => handleCloseMenuBarre()}
              >
                <IconButtonCommander className="IconListItemActionDetails" />
                <StyledListItemTextActions primary="Commander" />
              </StyledListItemActions>
              {isCommandeEnCours && (
                <>
                  <StyledListItemActions
                    id={'button-importer-courses'}
                    button
                    onClick={() => {
                      setOpenDialogImportationCourses(true)
                      handleCloseMenuBarre()
                    }}
                  >
                    <IconButtonImporter className="IconListItemActionDetails" />
                    <StyledListItemTextActions primary="Importer les courses" />
                  </StyledListItemActions>
                  <StyledListItemActions
                    id={'button-importer-tournees'}
                    button
                    onClick={() => {
                      setOpenDialogImportationTournees(true)
                      handleCloseMenuBarre()
                    }}
                  >
                    <SimCardDownloadOutlinedIcon className="IconListItemActionDetails" />
                    <StyledListItemTextActions primary="Importer les tournées" />
                  </StyledListItemActions>
                </>
              )}
              {isProgrammer && (
                <StyledListItemActions
                  id={'button-optimiser-courses'}
                  button
                  onClick={() => {
                    setOpenDialogOptimisationCourses(true)
                    handleCloseMenuBarre()
                  }}
                >
                  <IconOptimisationCourse className="IconListItemActionDetails" />
                  <StyledListItemTextActions primary="Optimiser les courses" />
                </StyledListItemActions>
              )}
            </List>
          </Popover>
        </>
      ) : (
        <Button
          variant="contained"
          sx={{
            backgroundColor: '#F61057',
            height: 38,
            margin: 'auto 10px',
            '&:hover': {
              backgroundColor: '#F32A68',
            },
          }}
          disabled={isOrderPage}
          value={rightLinks[0].path[0]}
          to={rightLinks[0].path[0]}
          component={Link}
        >
          <span id="commander" style={{ fontWeight: '600' }}>
            COMMANDER
          </span>
        </Button>
      )}
      {openDialogImportationCourses && (
        <CoursesImportation
          open={openDialogImportationCourses}
          handleClose={() => setOpenDialogImportationCourses(false)}
        />
      )}
      {openDialogOptimisationCourses && (
        <CoursesOptimisation
          open={openDialogOptimisationCourses}
          handleClose={() => setOpenDialogOptimisationCourses(false)}
        />
      )}

      {openDialogImportationTournees && (
        <TourneesImportation
          open={openDialogImportationTournees}
          handleClose={() => setOpenDialogImportationTournees(false)}
        />
      )}
    </>
  )
}
ActionsMenu.propTypes = {
  isOrderPage: PropTypes.bool,
  isCommandeEnCours: PropTypes.bool,
  isProgrammer: PropTypes.bool,
}
