import { connect } from 'react-redux'
import TourneesImportation from '../../../components/NavBar/main/ActionsMenu/tourneesImportation'

import {
  importerTournee,
  resetActionImportTournees,
} from '../../../redux/order/actions'
import {
  getErrorImportTournee,
  getIsActionImportTournee,
  getIsLoadingActionImportTournee,
} from '../../../redux/order/selectors'

const mapStateToProps = (state) => ({
  isLoadingActionImporterFile: getIsLoadingActionImportTournee(state),
  errorImportTournee: getErrorImportTournee(state),
  isActionImportTournee: getIsActionImportTournee(state),
})
const mapDisptachToProps = (dispatch) => ({
  importerTournee: (payload, macroCanalCode) =>
    dispatch(importerTournee(payload, macroCanalCode)),
  resetActionImportTournees: () => dispatch(resetActionImportTournees()),
})

export default connect(mapStateToProps, mapDisptachToProps)(TourneesImportation)
