import { getValue } from '../utils'

export default function onFailure(payload) {
  const currentUser = getValue('currentUser', {})
  if (Object.entries(currentUser).length > 0) {
    if (payload && payload.data && payload.data.response) {
      if (payload.data.response.status && payload.data.response.statusText) {
        if (payload?.data?.response?.status === 401) {
          localStorage.clear()
          window.location.replace('/login')
          return
        }
        const { status, statusText } = payload.data.response
        return { status, statusText }
      } else if (
        payload.data.response?.data?.code &&
        payload.data.response?.data?.message
      ) {
        const { code, message } = payload.data.response.data
        return { status: code, statusText: message }
      } else if (payload.data.response.code && payload.data.response.message) {
        const { code, message } = payload.data.response
        return { status: code, statusText: message }
      }
    } else if (
      payload.data.toString() === 'Error: Request failed with status code 404'
    ) {
      return { status: 404, statusText: 'Not Found' }
    } else if (
      payload.data &&
      payload.data.data &&
      payload.data.data.code &&
      payload.data.data.message
    ) {
      return {
        status: payload.data.data.code,
        statusText: payload.data.data.message,
      }
    } else {
      return {
        status: 404,
        statusText: "une erreur s'est produite merci d'actualiser la page",
      }
    }
  } else {
    localStorage.clear()
    window.location.replace('/login')
    return
  }
}
