import { OrderConstants } from './constants'

//Fetch orders
export const getOrderByFilter = () => ({
  type: OrderConstants.FETCH_ORDER_BY_FILTER,
})

//Fetch orders by Agent
export const getOrderByAgents = () => ({
  type: OrderConstants.FETCH_ORDER_BY_AGENTS,
})

//Search With Categrie in Corner
export const fetchSearchCorner = (search) => ({
  type: OrderConstants.FETCH_COURSES_CORNER_SEARCH,
  payload: { search },
})

export const fetchCourses = (courses) => ({
  type: OrderConstants.FETCH_COURSES_CORNER,
  payload: { courses },
})

export const fetchSearchCornerSave = (search) => ({
  type: OrderConstants.FETCH_COURSES_CORNER_SEARCH_SAVE,
  payload: { search },
})

//Selected Course (Programmer)
export const addSelectedCourses = (data) => ({
  type: OrderConstants.ADD_SELECTED_COURSES,
  payload: { data },
})

export const removeSelectedCourses = (data) => ({
  type: OrderConstants.REMOVE_SELECTED_COURSES,
  payload: { data },
})

export const removeAllSelectedCourses = () => ({
  type: OrderConstants.REMOVE_ALL_SELECTED_COURSES,
})

//Remove Error Fetch
export const removeError = () => ({
  type: OrderConstants.REMOVE_ERROR,
})

//Remove Error Action order
export const removeErrorAction = () => ({
  type: OrderConstants.REMOVE_ERROR_ACTION,
})

//Reset orders
export const resetOrders = () => ({
  type: OrderConstants.RESET_ORDER,
})

//Fetch related course (commande en cours)
export const getRelatedCourseInfo = (code) => ({
  type: OrderConstants.FETCH_RELATED_COURSE_INFO,
  payload: { code },
})

//Fetch livraison details
export const getOrderByLdv = (ldv) => ({
  type: OrderConstants.GET_ORDER_BY_LDV,
  payload: { ldv },
})

//Reset livraison details
export const resetOrderDetails = () => ({
  type: OrderConstants.RESET_ORDER_DETAILS,
  payload: {},
})

//Upload (Photo) Signature
export const onUploadSignature = (file, codeCourse) => ({
  type: OrderConstants.UPLOAD_SIGNATURE,
  payload: { file, codeCourse },
})

//Fetch Signature Order
export const getOrderSignatureByLdv = (ldv) => ({
  type: OrderConstants.GET_ORDER_SIGNATURE_BY_LDV,
  payload: { ldv },
})

//Upload  Photos
export const onUploadPhoto = (file, codeCourse) => ({
  type: OrderConstants.UPLOAD_PHOTO,
  payload: { file, codeCourse },
})

//Fetch Photos Order
export const getOrderPhotoByLdv = (ldv) => ({
  type: OrderConstants.GET_ORDER_PHOTO_BY_LDV,
  payload: { ldv },
})

//Download Photos Order
export const downloadImageOrder = (urlImage) => ({
  type: OrderConstants.DOWNLOAD_IMAGE_ORDER,
  payload: { urlImage },
})

//Upload  Attachment
export const onUploadAttachment = (file, codeCourse) => ({
  type: OrderConstants.UPLOAD_ATTACHMENT,
  payload: { file, codeCourse },
})

//Fetch Attachment Order
export const getOrderAttachmentsByLdv = (ldv) => ({
  type: OrderConstants.GET_ORDER_ATTACHMENT_BY_LDV,
  payload: { ldv },
})

//Fetch Pieces Jointe URL (S3) (Visualisation Piece Jointe)
export const getPiecesJointeURL = (url) => ({
  type: OrderConstants.GET_PIECE_JOINTE_ORDER,
  payload: { url },
})

//Set Error upload File (Photos && Signatures && Attachement)
export const setErrorFileUpload = (isError) => ({
  type: OrderConstants.SET_ERROR_UPLOAD_FILE,
  payload: { isError },
})

//Fetch Notification de livraison
export const getOrderNotification = (codeCourse) => ({
  type: OrderConstants.GET_NOTIFICATION_ORDER,
  payload: { codeCourse },
})

//Fetch historique delta cost
export const getOrderHistoriqueDeltaCost = (ldv) => ({
  type: OrderConstants.GET_HISTORIQUE_DELTA_COST_ORDER,
  payload: { ldv },
})

//Fetch Historique de livraison
export const getOrderHistoriqueLivraison = (codeCourse) => ({
  type: OrderConstants.GET_HISTORIQUE_LIVRAISON_ORDER,
  payload: { codeCourse },
})

//Fetch Historique de RDV
export const getOrderHistoriqueRDV = (ldv) => ({
  type: OrderConstants.GET_HISTORIQUE_RDV_ORDER,
  payload: { ldv },
})

//Fetch Historique d'activité
export const getActivityHistory = (codeCourse) => ({
  type: OrderConstants.GET_ACTIVITY_ORDER_HISTORY,
  payload: { codeCourse },
})

//Changement Status Order (Deprogram Order)
export const handleDeprogramClick = (payload) => ({
  type: OrderConstants.CHANGE_STATUS_COURSE_DISPATCH,
  payload: payload,
})

//Changement Status Order (Desattribution Order)
export const handleDissociateClick = (payload) => ({
  type: OrderConstants.CHANGE_STATUS_COURSE_DISPATCH,
  payload: payload,
})

//Changement Status Order
export const onChangeStatus = (payload) => ({
  type: OrderConstants.CHANGE_STATUS_COURSE_DISPATCH,
  payload: payload,
})

//Fetch Motifs (Annulation && RDI && Retrait impossible && Livraison impossible && terminee && enlevee)
export const getOrderReasons = (payload) => ({
  type: OrderConstants.FETCH_ORDER_REASONS,
  payload: payload,
})

//Changement Status Order (Anullation Order)
export const onChangeStatusAnnuler = (payload) => ({
  type: OrderConstants.CHANGE_STATUS_COURSE_DISPATCH,
  payload: payload,
})

//Program Course
export const programCourse = (data) => ({
  type: OrderConstants.PROGRAM_COURSE,
  payload: data,
})

//Attribution Chauffeur warrning
export const assignDriver = (data) => ({
  type: OrderConstants.ASSIGN_DRIVER,
  payload: { data },
})

//Affecter à une autre agence
export const OnUpdateWarehouse = (payload) => ({
  type: OrderConstants.UPDATE_WAREHOUSE,
  payload: payload,
})

//Rendez-Vous Impossible
export const OnRDVImpossible = (payload) => ({
  type: OrderConstants.SET_RDV_IMPOSSIBLE,
  payload: payload,
})

//Set prepared order (Action)
export const setPrepared = (codeCourse, isPrepared) => ({
  type: OrderConstants.SET_PREPARED,
  payload: { codeCourse, isPrepared },
})

//Reset Action Order
export const resetOrderAction = () => ({
  type: OrderConstants.RESET_ORDER_ACTION,
})

//Fetch Collaborateur (creation discussion)
export const getCollaborateurs = (codeCourse) => ({
  type: OrderConstants.GET_COLLABORATEURS,
  payload: { codeCourse },
})

//Fetch Clients Pro (creation discussion)
export const getClientsPro = (codeCanal) => ({
  type: OrderConstants.GET_CLIENTSPRO,
  payload: { codeCanal },
})

//Set Loading
export const setLoading = () => ({
  type: OrderConstants.SET_LOADING,
})

//Search With Categrie in Corner Onglet
export const fetchSearchOngletCorner = (search) => ({
  type: OrderConstants.FETCH_COURSES_ONGLET_CORNER_SEARCH,
  payload: { search },
})

export const fetchSearchOngletCornerSave = (search) => ({
  type: OrderConstants.FETCH_COURSES_ONGLET_CORNER_SEARCH_SAVE,
  payload: { search },
})

//Fetch orders
export const refreshFetchOrder = () => ({
  type: OrderConstants.REFRESH_FETCH_ORDER_BY_FILTER,
})

//refresh course with search Onglet
export const refreshFetchOrderSearch = (search) => ({
  type: OrderConstants.REFRESH_COURSES_ONGLET_CORNER_SEARCH,
  payload: { search },
})

//Fetch MacroCanal
export const fetchMacroCanal = () => ({
  type: OrderConstants.FETCH_MACRO_CANAL,
})

//Importer Courses
export const importerCourses = (payload, macroCanalCode) => ({
  type: OrderConstants.IMPORT_COURSES,
  payload: { payload, macroCanalCode },
})

//Reset action import courses
export const resetActionImportCourses = () => ({
  type: OrderConstants.RESET_ACTION_IMPORT_COURSES,
})

//Remove error import courses
export const removeErrorImportCourses = () => ({
  type: OrderConstants.REMOVE_ERROR_IMPORT_COURSES,
})
//Ajouter a une navette
export const AddToNavette = (payload) => ({
  type: OrderConstants.ADD_TO_NAVETTE,
  payload: payload,
})
//Change de storedelivery vers clientdelivery
export const LivraisonClientAction = (payload) => ({
  type: OrderConstants.TO_CLIENT_DELIVERY,
  payload: payload,
})

//set Action Duplicated Course
export const setActionDuplicatedCourse = (payload) => ({
  type: OrderConstants.SET_DUPLICATED_COURSE,
  payload: payload,
})

//get Action Duplicated Course
export const getCourseDuplicated = (payload) => ({
  type: OrderConstants.GET_DUPLICATED_COURSE,
  payload: payload,
})

//set Action order done
export const setIsActionShipmentDone = (payload) => ({
  type: OrderConstants.SET_ACTION_ORDER,
  payload: payload,
})

export const getActitvity = (entityId, type, category) => ({
  type: OrderConstants.GET_ACTIVITY,
  payload: { entityId, type, category },
})

// Check All Courses Table (Commande en cours)
export const setAllCheckCourse = () => ({
  type: OrderConstants.SET_ALL_CHECK_COURSES,
})

// Check Courses Table (Commande en cours)
export const setCheckCourse = (codeCourse, courseCheck) => ({
  type: OrderConstants.SET_CHECK_COURSES,
  payload: { codeCourse, courseCheck },
})

// InitCheck (Commande en cours)
export const initCheckCourse = () => ({
  type: OrderConstants.INIT_CHECK_COURSES,
})

//Changement Status en masse
export const onChangeStatusEnMasse = (payload) => ({
  type: OrderConstants.CHANGE_STATUS_COURSE_EN_MASSE_DISPATCH,
  payload: payload,
})

//Fetch Question By LDV
export const getOrderQuestionsByLdv = (ldv) => ({
  type: OrderConstants.GET_ORDER_QUESTION_BY_LDV,
  payload: { ldv },
})
//refresh course with search corner
export const refreshFetchOrderSearchCorner = (search) => ({
  type: OrderConstants.REFRESH_COURSES_CORNER_SEARCH,
  payload: { search },
})
//Fetch config
export const getConfigCanal = (codeCanal, metaKey) => ({
  type: OrderConstants.GET_CONFIG_CANAL,
  payload: { codeCanal, metaKey },
})

export const resetConfigCanal = () => ({
  type: OrderConstants.RESET_CONFIG_CANAL,
})

//Importer Tournee
export const importerTournee = (payload) => ({
  type: OrderConstants.IMPORT_TOURNEE,
  payload: { payload },
})

//Reset action import tournee
export const resetActionImportTournees = () => ({
  type: OrderConstants.RESET_ACTION_IMPORT_TOURNEE,
})

//Remove error import tournee
export const removeErrorImportTournees = () => ({
  type: OrderConstants.REMOVE_ERROR_IMPORT_TOURNEE,
})
