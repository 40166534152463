import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Checkbox from '@mui/material/Checkbox'
import {
  ACCURATE_ADDRESS,
  COURSE_PLACEE_MANUELLEMENT,
} from '../../../../../utils/values'

import TableRowsContent from './TableContent'
import {
  checkActionsUservedOrders,
  columnsListUservedOrders,
  columnsOptimisationCoursesFileUservedOrders,
  settingsTable,
} from './data'
import TableCellCustomized from '../../../../../components/TableCellCustomized'
import { StyledCell } from './TableContent/style'
import { TableCell } from '@mui/material'
import MoveAction from '../MoveAction'
import ModificationCoursesDialog from '../../../../../containers/main/Simuler/modificationCoursesDialog'
import { TableVirtuoso } from 'react-virtuoso'

const VirtuosoTableComponents = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer {...props} ref={ref} />
  )),
  Table: (props) => (
    <Table
      {...props}
      stickyHeader
      aria-label="sticky table"
      style={{ backgroundColor: 'white' }}
    />
  ),
  TableHead: React.forwardRef((props, ref) => (
    <TableHead
      {...props}
      ref={ref}
      sx={{
        position: 'sticky',
        zIndex: 5,
      }}
    />
  )),
  TableRow,
  TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
}

VirtuosoTableComponents.Scroller.displayName = 'Scroller'
VirtuosoTableComponents.Table.displayName = 'Table'
VirtuosoTableComponents.TableHead.displayName = 'TableHead'
VirtuosoTableComponents.TableBody.displayName = 'TableBody'

function fixedHeaderContent(
  columnsList,
  isOptimisationCoursesFiles,
  unservedOrders,
  selectedCourses,
  handleCheckAllOrder,
  verifDisabledCheckAll,
  planningFiltered,
  moveTableUnserved,
  modifierCourse,
  filterCourseFile,
  setFilters,
  filtersValues,
  resetCheckedAll
) {
  return (
    <TableRow>
      {columnsList.map((column, index) =>
        column.id === 'check' ? (
          <StyledCell
            style={{
              position: 'sticky',
              left: 0,
              zIndex: 5,
              minWidth: '70px',
              background: '#ffff',
            }}
            key={`column_${index}`}
            align="left"
          >
            <Checkbox
              checked={
                unservedOrders.length > 0 &&
                selectedCourses.length == unservedOrders.length
              }
              onChange={handleCheckAllOrder}
              disabled={verifDisabledCheckAll()}
            />
          </StyledCell>
        ) : column.id === 'action' ? (
          <StyledCell
            style={{
              position: 'sticky',
              right: 0,
              minWidth: '70px',
              background: '#ffff',
            }}
            key={`Action`}
            align="left"
          ></StyledCell>
        ) : column.id === 'reason' ? (
          <TableCell key={`reason`} align="left">
            Raison
          </TableCell>
        ) : column.id === 'settings' ? (
          <MoveAction
            index={index}
            planning={planningFiltered}
            disabledActionMov={selectedCourses.length == 0}
            isTableTournee={false}
            moveTableUnserved={moveTableUnserved}
            modifierCourse={modifierCourse}
            disabledAction={
              isOptimisationCoursesFiles &&
              unservedOrders.some(
                (course) =>
                  selectedCourses.includes(course.id) &&
                  !ACCURATE_ADDRESS.includes(course.locationType)
              )
            }
            resetCheckedAll={resetCheckedAll}
          />
        ) : (
          <TableCellCustomized
            key={`column_${index}`}
            alignCell="center"
            column={column}
            filterCourseFile={filterCourseFile}
            setFilters={setFilters}
            filtersValues={filtersValues}
            isOptimisationCoursesFiles={isOptimisationCoursesFiles}
          />
        )
      )}
    </TableRow>
  )
}

function rowContent(
  row,
  rowNumber,
  isOptimisationCoursesFiles,
  selectedCourses,
  handleCheckOrder
) {
  return (
    <>
      <StyledCell
        style={{
          position: 'sticky',
          left: 0,
          minWidth: '70px',
          background: '#ffff',
        }}
      >
        <Checkbox
          checked={selectedCourses.includes(row.id)}
          onChange={() => handleCheckOrder(row.id)}
          component={'td'}
        />
      </StyledCell>

      <TableRowsContent
        row={row}
        rowNumber={rowNumber}
        isOptimisationCoursesFiles={isOptimisationCoursesFiles}
        columnsList={
          isOptimisationCoursesFiles
            ? columnsOptimisationCoursesFileUservedOrders
            : columnsListUservedOrders
        }
        disabledColumn={
          isOptimisationCoursesFiles && !ACCURATE_ADDRESS.includes(row.locationType)
        }
        isChecked={selectedCourses.includes(row.id)}
        withReason={
          row.reason && row.reason != COURSE_PLACEE_MANUELLEMENT && row.reason != '-'
        }
      />
    </>
  )
}

const UnservedOrders = ({
  unservedOrders,
  handleCheckAll,
  handleCheck,
  resetCheckedAll,
  isOptimisationCoursesFiles,
  filtersValues,
  setFilters,
  moveTableUnserved,
  planningFiltered,
  filterCourseFile,
  isModifAdresse,
  selectedCourses,
}) => {
  const [openDialogModifCourses, setOpenDialogModifCourses] = useState(false)
  const [unservedCoursesModif, setUnservedCoursesModif] = useState([])

  useEffect(() => {
    if (isModifAdresse) {
      setOpenDialogModifCourses(true)

      setUnservedCoursesModif(
        unservedOrders.filter(
          (course) => !ACCURATE_ADDRESS.includes(course.locationType)
        )
      )
    } else {
      setOpenDialogModifCourses(false)
    }
  }, [isModifAdresse, unservedOrders])

  const handleCheckAllOrder = (event) => {
    handleCheckAll(event.target.checked)
  }

  const verifDisabledCheckAll = () => {
    return (
      unservedOrders.filter(
        (e) =>
          isOptimisationCoursesFiles && !ACCURATE_ADDRESS.includes(e.locationType)
      ).length == unservedOrders.length
    )
  }

  const handleCheckOrder = (id) => {
    handleCheck(id)
  }

  const modifierCourse = () => {
    setOpenDialogModifCourses(true)
    setUnservedCoursesModif(
      unservedOrders.filter((course) => selectedCourses.includes(course.id))
    )
  }
  const columnsList = [
    ...checkActionsUservedOrders,
    ...(isOptimisationCoursesFiles
      ? columnsOptimisationCoursesFileUservedOrders
      : columnsListUservedOrders),
    // ...buttonActionUservedOrders,
    ...settingsTable,
  ]

  return (
    <Paper
      className="order-table-root"
      style={{
        margin: 0,
        width: '100%',
        backgroundColor: 'transparent',
        overflow: 'auto',
        height: 'calc(100% - 45px )',
      }}
    >
      <TableVirtuoso
        data={unservedOrders}
        components={VirtuosoTableComponents}
        fixedHeaderContent={() =>
          fixedHeaderContent(
            columnsList,
            isOptimisationCoursesFiles,
            unservedOrders,
            selectedCourses,
            handleCheckAllOrder,
            verifDisabledCheckAll,
            planningFiltered,
            moveTableUnserved,
            modifierCourse,
            filterCourseFile,
            setFilters,
            filtersValues,
            resetCheckedAll
          )
        }
        itemContent={(index, row) =>
          rowContent(
            row,
            index,
            isOptimisationCoursesFiles,
            selectedCourses,
            handleCheckOrder
          )
        }
      />

      {openDialogModifCourses && (
        <ModificationCoursesDialog
          open={openDialogModifCourses}
          handleClose={() => setOpenDialogModifCourses(false)}
          unservedOrders={unservedCoursesModif}
        />
      )}
    </Paper>
  )
}
UnservedOrders.propTypes = {
  unservedOrders: PropTypes.array,
  handleCheckAll: PropTypes.func,
  handleCheck: PropTypes.func,
  resetCheckedAll: PropTypes.func,
  isOptimisationCoursesFiles: PropTypes.bool,
  filtersValues: PropTypes.object,
  setFilters: PropTypes.object,
  moveTableUnserved: PropTypes.func,
  planningFiltered: PropTypes.array,
  filterCourseFile: PropTypes.object,
  isModifAdresse: PropTypes.bool,
  selectedCourses: PropTypes.array,
}

export default UnservedOrders
