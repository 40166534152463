import { connect } from 'react-redux'
import Programmer from '../../../pages/main/Programmer'
import {
  getOrderByFilter,
  removeError,
  addSelectedCourses,
  removeSelectedCourses,
  removeAllSelectedCourses,
  fetchSearchCornerSave,
  resetOrders,
  removeErrorAction,
  resetOrderAction,
} from '../../../redux/order/actions'
import { pageChanged, pageLimitChanged } from '../../../redux/filter/actions'

import {
  getOrders,
  getIsLoading,
  getError,
  getErrorActionCourse,
  getSelectedCourses,
  getisActionOrderDone,
} from '../../../redux/order/selectors'
import {
  getPagesCount,
  getLimit,
  getDefaultFilterPlanification,
  getSort,
  getOrderBy,
  getSearchCorner,
} from '../../../redux/filter/selectors'

import {
  getIsViewPlannification,
  isTourActionsSimulate,
} from '../../../redux/simulateTournee/selectors'

import {
  resetTourAction,
  updateOrdersArray,
  viewPlannification,
} from '../../../redux/simulateTournee/actions'
import {
  getfilterPlanification,
  getfilterError,
  getCount,
} from '../../../redux/filter/selectors'
import {
  setFilterPlanification,
  removeErrorFilter,
  resetFilterPlanification,
} from '../../../redux/filter/actions'

import { LimitPaginationList } from '../../../components/Pagination/LimitPaginationList'

const mapStateToProps = (state) => ({
  filterReducer: getfilterPlanification(state),
  shipments: getOrders(state),
  isLoading: getIsLoading(state),
  error: getError(state),
  count: getCount(state),
  pageCount: getPagesCount(state),
  limit: getLimit(state),
  selectedCourses: getSelectedCourses(state),
  errorFilter: getfilterError(state),
  searchReducer: getSearchCorner(state),
  isViewPlannification: getIsViewPlannification(state),
  errorActionCourse: getErrorActionCourse(state),
  isActionDone: getisActionOrderDone(state),
  defaultFilterPlanification: getDefaultFilterPlanification(state),
  sort: getSort(state),
  orderBy: getOrderBy(state),
  isTourActionSimulate: isTourActionsSimulate(state),
})
const mapDisptachToProps = (dispatch) => ({
  setFilter: (filter, fromAppliquer) =>
    dispatch(setFilterPlanification(filter, fromAppliquer)),
  getShipmentByFilter: () => {
    dispatch(pageLimitChanged(LimitPaginationList[0].value))
    dispatch(getOrderByFilter())
  },
  pageLimitChanged: (limit, search, searchCorner) => {
    dispatch(pageLimitChanged(limit))
    search
      ? dispatch(fetchSearchCornerSave(searchCorner))
      : dispatch(getOrderByFilter())
  },
  pageChanged: (offset, search, searchCorner) => {
    dispatch(pageChanged(offset))
    search
      ? dispatch(fetchSearchCornerSave(searchCorner))
      : dispatch(getOrderByFilter())
  },
  removeError: () => dispatch(removeError()),
  addSelectedCourses: (data) => dispatch(addSelectedCourses(data)),
  removeSelectedCourses: (data) => dispatch(removeSelectedCourses(data)),
  removeAllSelectedCourses: () => dispatch(removeAllSelectedCourses()),
  removeErrorFilter: () => dispatch(removeErrorFilter()),
  updateOrdersArray: (orders) => {
    dispatch(updateOrdersArray(orders))
  },
  resetFilterPlanification: () => dispatch(resetFilterPlanification()),
  viewPlannification: (isViewPlannif) => dispatch(viewPlannification(isViewPlannif)),
  resetShipments: () => dispatch(resetOrders()),
  removeErrorAction: () => dispatch(removeErrorAction()),
  resetOrderAction: () => dispatch(resetOrderAction()),
  fetchSearchCornerSave: (search) => dispatch(fetchSearchCornerSave(search)),
  resetTourAction: () => dispatch(resetTourAction()),
})

export default connect(mapStateToProps, mapDisptachToProps)(Programmer)
