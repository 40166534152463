import React, { useState } from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import ClearIcon from '@mui/icons-material/Clear'
import { StyledCell } from './style'

import InputMask from 'react-input-mask'
import moment from 'moment'

const ColumnTimeRangeComponentEditable = ({
  id,
  onChange,
  errorsCourseEditable,
  contentEditable,
  nameAttribut,
  disabledColumn,
  valuesmodif,
}) => {
  const [startTime, setStartTime] = useState(
    valuesmodif?.date != '-'
      ? valuesmodif.heureStart
      : contentEditable.heureStart
      ? contentEditable.heureStart
      : null
  )
  const [endTime, setEndTime] = useState(
    valuesmodif?.date != '-'
      ? valuesmodif.heureEnd
      : contentEditable.heureEnd
      ? contentEditable.heureEnd
      : null
  )

  const handleTimeChange = (event) => {
    const { name, value } = event.target
    if (name === 'dropOffStart') {
      setStartTime(value)
    } else if (name === 'dropOffEnd') {
      setEndTime(value)
    }
  }
  const formatTime = (value) => {
    const formattedValue = value.replace(/[^0-9:]/g, '').slice(0, 5)
    const [hours, minutes] = formattedValue.split(':')

    const validHours = Math.min(Math.max(parseInt(hours, 10) || 0, 0), 23)
    const validMinutes = Math.min(Math.max(parseInt(minutes, 10) || 0, 0), 59)

    return `${String(validHours).padStart(2, '0')}:${String(validMinutes).padStart(
      2,
      '0'
    )}`
  }
  const handleInputChange = (event) => {
    const { name, value } = event.target
    const formattedValue = formatTime(value)
    handleTimeChange({
      target: {
        name,
        value: formattedValue,
      },
    })

    const timeKey = name === 'dropOffStart' ? 'dropOffStart' : 'dropOffEnd'
    const prefix = nameAttribut === 'creneauArivee1' ? '' : 'second'

    const key = prefix
      ? `${prefix}${timeKey.charAt(0).toUpperCase()}${timeKey.slice(1)}`
      : timeKey

    onChange({
      [key]: moment(`${contentEditable.date} ${formattedValue}`).format(),
    })
  }

  const handleClear = (field) => {
    if (field === 'dropOffStart') {
      setStartTime('')
      onChange(
        nameAttribut === 'creneauArivee1'
          ? { dropOffStart: null }
          : { secondDropOffStart: null }
      )
    } else if (field === 'dropOffEnd') {
      setEndTime('')
      onChange(
        nameAttribut === 'creneauArivee1'
          ? { dropOffEndt: null }
          : { secondDropOffEnd: null }
      )
    }
  }

  return (
    <StyledCell id={id} sx={{ color: disabledColumn && '#8E8E8E' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <InputMask
          mask="99:99"
          maskChar="0"
          value={startTime}
          onChange={handleInputChange}
        >
          {(inputProps) => (
            <TextField
              {...inputProps}
              error={
                nameAttribut == 'creneauArivee1'
                  ? errorsCourseEditable?.includes('creneau1')
                  : errorsCourseEditable?.includes('creneau2')
              }
              type="text"
              name="dropOffStart"
              InputLabelProps={{
                shrink: true,
              }}
              InputAdornmentProps={{
                position: 'end',
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => handleClear('dropOffStart')}
                      edge="end"
                      size="small"
                      style={{ padding: 0 }}
                    >
                      <ClearIcon style={{ fontSize: 10 }} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        </InputMask>

        <span style={{ margin: '8px 8px' }}>~</span>
        <InputMask
          mask="99:99"
          maskChar="0"
          value={endTime}
          onChange={handleInputChange}
        >
          {(inputProps) => (
            <TextField
              {...inputProps}
              error={
                nameAttribut === 'creneauArivee1'
                  ? errorsCourseEditable?.includes('creneau1')
                  : errorsCourseEditable?.includes('creneau2')
              }
              type="text"
              name="dropOffEnd"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => handleClear('dropOffEnd')}
                      edge="end"
                      size="small"
                      style={{ padding: 0 }}
                    >
                      <ClearIcon style={{ fontSize: 10 }} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        </InputMask>
      </div>
    </StyledCell>
  )
}

ColumnTimeRangeComponentEditable.propTypes = {
  key: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  contentEditable: PropTypes.object,
  nameAttribut: PropTypes.string,
  errorsCourseEditable: PropTypes.array,
  disabledColumn: PropTypes.bool,
  valuesmodif: PropTypes.object,
}

export default ColumnTimeRangeComponentEditable
