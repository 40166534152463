import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import AutoComplete from '../../../../../components/AutoComplete'
import SearchIcon from '@mui/icons-material/Search'

export const sxInput = {
  backgroundColor: '#f8f8f8',
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#f8f8f8',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#f8f8f8',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#f8f8f8',
  },
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export default function DashTab({
  handleChange,
  value,
  canalVente,
  setCanalVente,

  fetchCanalVenteDash,
  fetchCanalVenteDashMore,
  canalVentes,
  isLoadingCanalVentes,
  canalVentesCount,
}) {
  const [canalVenteSearch, setSearchCanalVente] = useState('')
  const [offset, setOffset] = useState(25)

  const [select, setSelect] = useState(false)

  const onChangeSearchCanalVente = (value) => {
    setSelect(false)
    if (value) {
      let val = value.trim().toUpperCase()
      setSearchCanalVente(val)
    } else {
      setCanalVente(null)
      setSearchCanalVente('')
    }
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (canalVenteSearch.length > 0 && !select) {
        const filtre = {
          query: canalVenteSearch,
          limit: 25,
          offset: 0,
        }
        fetchCanalVenteDash(filtre)
        setOffset(25)
      } else {
        const filtre = {
          limit: 25,
          offset: 0,
        }
        fetchCanalVenteDash(filtre)
        setOffset(25)
      }
    }, 500)

    return () => clearTimeout(delayDebounceFn)
  }, [canalVenteSearch])

  const onSelectCanalVente = (value) => {
    if (value) {
      setCanalVente(value)
      setSelect(true)
    }
  }
  const loadMoreCanalVente = (event) => {
    if (
      Math.round(event.target.scrollHeight - event.target.scrollTop) ==
        event.target.clientHeight &&
      canalVentesCount >= offset
    ) {
      setOffset(offset + 25)
      const filtre = {
        query: canalVenteSearch.length > 0 ? canalVenteSearch : undefined,
        limit: 25,
        offset,
      }
      fetchCanalVenteDashMore(filtre)
    }
  }
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        aria-label="scrollable force tabs example"
        sx={{ height: '52px' }}
      >
        <Tab
          label="Entrepôt"
          sx={{
            borderRight: '1px solid rgba(237, 237, 237, 1)',
            backgroundColor: value == 0 && 'white',
          }}
          {...a11yProps(0)}
        />
        <Tab
          label="CHAUFFUER/SS-TRAITANT"
          sx={{
            borderRight: '1px solid rgba(237, 237, 2s37, 1)',
            backgroundColor: value == 1 && 'white',
          }}
          {...a11yProps(1)}
        />
        <Tab
          label="carrefour"
          sx={{
            borderRight: '1px solid rgba(237, 237, 237, 1)',
            backgroundColor: value == 2 && 'white',
          }}
          {...a11yProps(2)}
        />
        <Tab
          label="canal"
          sx={{
            borderRight: '1px solid rgba(237, 237, 237, 1)',
            backgroundColor: value == 3 && 'white',
          }}
          {...a11yProps(3)}
        />
      </Tabs>
      {value == 3 && (
        <Box sx={{ width: '240px' }}>
          <AutoComplete
            value={canalVente}
            onChange={(event, value) => {
              onSelectCanalVente(value)
            }}
            onInputChange={(event, value) => onChangeSearchCanalVente(value)}
            id="canals-vente"
            options={canalVentes}
            name="canalvente"
            getOptionLabel={(option) => option.name}
            variant="outlined"
            placeholder={'rechercher par canal de vente'}
            ListboxProps={{
              onScroll: loadMoreCanalVente,
            }}
            renderOption={(props, option) => (
              <li {...props} key={option.code} id={option.code}>
                <div className="AutoCompteFilterOption">{option.name}</div>
              </li>
            )}
            loading={isLoadingCanalVentes}
            startIcon={<SearchIcon sx={{ paddingRight: '4px', fontSize: '20px' }} />}
            forcePopupIcon={false}
            sxInputStyle={sxInput}
          />
        </Box>
      )}
    </Box>
  )
}

DashTab.propTypes = {
  handleChange: PropTypes.func,
  value: PropTypes.number,
  canalVente: PropTypes.string,
  setCanalVente: PropTypes.func,

  fetchCanalVenteDash: PropTypes.func,
  fetchCanalVenteDashMore: PropTypes.func,
  canalVentes: PropTypes.array,
  isLoadingCanalVentes: PropTypes.bool,
  canalVentesCount: PropTypes.number,
}
