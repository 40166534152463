import { OrderConstants } from './constants'
import { OrderInitialState as initialState } from './initialState'

import onFailure from '../OnFailure'
import {
  collectDeliveryData,
  getHistoriqueLivraison,
  getActivityHistory,
} from '../../utils/historique'

import { SELECTED_COURSE_ENUM } from '../../utils/values'

export default (state = initialState, action) => {
  switch (action.type) {
    //Fetch orders && sort
    case OrderConstants.FETCH_ORDER_BY_FILTER: {
      return {
        ...state,
        myCommandsIsOn: false,
        isLoading: true,
        isLoadingRefresh: false,
        error: null,
      }
    }

    case OrderConstants.FETCH_ORDER_BY_FILTER_SUCCESS: {
      const { secData, data } = action.payload.data
      if (!secData && state.checkCoursesAll) {
        const connectedUser = JSON.parse(localStorage.getItem('currentUser'))
        const parseOrder = data.map((order) => {
          return {
            collaborateurId: connectedUser.id,
            departureType: order?.departureType,
            courseType: order?.courseMetadata?.courseType,
            codeCourse: order.code,
            courseSource: order.courseSource,
            driver:
              order?.chauffeur != null && order?.chauffeur?.id
                ? { id: order.chauffeur.id }
                : undefined,
            method: 'corner/clotureEnMasse',
          }
        })
        const coursesChecked = [...state.coursesChecked, ...parseOrder]
        let coursesCheckedState = coursesChecked.reduce(
          (a, v) => ({ ...a, [v.codeCourse]: v }),
          {}
        )
        return {
          ...state,
          orders: data,
          isLoading: false,
          error: null,
          coursesChecked: [...Object.values(coursesCheckedState)],
        }
      }
      return {
        ...state,
        orders: data,
        isLoading: false,
        error: null,
        selectedCourses: {},
      }
    }

    case OrderConstants.FETCH_ORDER_BY_FILTER_FAILURE: {
      let error = onFailure(action.payload)
      return {
        ...state,
        isLoading: false,
        error: error,
      }
    }
    //Fetch orders by Agent
    case OrderConstants.FETCH_ORDER_BY_AGENTS: {
      return {
        ...state,
        myCommandsIsOn: true,
        isLoading: true,
        error: null,
      }
    }
    // offset
    case OrderConstants.FETCH_ORDER_BY_AGENTS_SUCCESS: {
      const { data } = action.payload.data

      return {
        ...state,
        orders: data,
        isLoading: false,
        error: null,
      }
    }

    case OrderConstants.FETCH_ORDER_BY_AGENTS_FAILURE: {
      let error = onFailure(action.payload)
      return {
        ...state,
        isLoading: false,
        error,
      }
    }

    //Search With Categrie in Corner
    case OrderConstants.FETCH_COURSES_ONGLET_CORNER_SEARCH:
    case OrderConstants.FETCH_COURSES_CORNER_SEARCH: {
      return {
        ...state,
        isLoadingSearchCorner: true,
        isLoadingRefresh: false,
      }
    }
    case OrderConstants.FETCH_COURSES_CORNER_SEARCH_SUCCESS: {
      const { response, total, limit, offset } = action.payload.data
      return {
        ...state,
        isLoadingSearchCorner: false,
        coursesSearch: response,
        coursesSearchLimit: parseInt(limit),
        coursesSearchOffset: parseInt(offset),
        coursesSearchCount: parseInt(total),
        isLoading: false,
      }
    }

    case OrderConstants.FETCH_COURSES_CORNER_SEARCH_FAILURE: {
      const error = onFailure(action.payload)
      return {
        ...state,
        isLoadingSearchCorner: false,
        isLoading: false,
        error,
      }
    }
    case OrderConstants.FETCH_COURSES_CORNER: {
      const { courses } = action.payload
      return {
        ...state,
        orders: courses,
        error: null,
      }
    }

    case OrderConstants.FETCH_COURSES_ONGLET_CORNER_SEARCH_SAVE:
    case OrderConstants.FETCH_COURSES_CORNER_SEARCH_SAVE: {
      return {
        ...state,
        isLoading: true,
        isLoadingRefresh: false,
        error: null,
      }
    }
    case OrderConstants.FETCH_COURSES_CORNER_SEARCH_SAVE_SUCCESS: {
      const { response, secData } = action.payload.data

      if (!secData && state.checkCoursesAll) {
        const connectedUser = JSON.parse(localStorage.getItem('currentUser'))
        const parseOrder = response
          .map((el) => el.course)
          .map((order) => {
            return {
              collaborateurId: connectedUser.id,
              departureType: order?.departureType,
              courseType: order?.courseMetadata?.courseType,
              codeCourse: order.code,
              courseSource: order.courseSource,
              driver:
                order?.chauffeur != null && order?.chauffeur?.id
                  ? { id: order.chauffeur.id }
                  : undefined,
              method: 'corner/clotureEnMasse',
            }
          })
        const coursesChecked = [...state.coursesChecked, ...parseOrder]
        let coursesCheckedState = coursesChecked.reduce(
          (a, v) => ({ ...a, [v.codeCourse]: v }),
          {}
        )

        return {
          ...state,
          orders: response.map((el) => el.course),
          isLoading: false,
          error: null,
          coursesChecked: [...Object.values(coursesCheckedState)],
        }
      }
      return {
        ...state,
        orders: response.map((el) => el.course),
        isLoading: false,
        error: null,
      }
    }

    case OrderConstants.FETCH_COURSES_CORNER_SEARCH_SAVE_FAILURE: {
      let error = onFailure(action.payload)
      return {
        ...state,
        isLoading: false,
        error,
      }
    }

    //Selected Course (Programmer)
    case OrderConstants.ADD_SELECTED_COURSES: {
      const courses = action.payload.data.data
      const source = action.payload.data.source
      const selectedCourses = { ...state.selectedCourses }
      if (source === SELECTED_COURSE_ENUM.MAP) {
        const affected = {}
        courses.forEach((ele) => {
          affected[ele] = {
            ...selectedCourses[ele],
            fromMap: true,
            code: ele,
          }
        })
        return {
          ...state,
          orders: [...state.orders],
          selectedCourses: {
            ...selectedCourses,
            ...affected,
          },
        }
      }
      if (source === SELECTED_COURSE_ENUM.TABLE) {
        const affected = {}
        courses.forEach((ele) => {
          affected[ele] = {
            ...selectedCourses[ele],
            fromTable: true,
            code: ele,
          }
        })
        return {
          ...state,
          selectedCourses: {
            ...selectedCourses,
            ...affected,
          },
        }
      }
      return {
        ...state,
      }
    }

    case OrderConstants.REMOVE_SELECTED_COURSES: {
      const courses = action.payload.data.data
      const source = action.payload.data.source
      const selectedCourses = Object.assign(state.selectedCourses)
      if (source === SELECTED_COURSE_ENUM.MAP) {
        courses.forEach((ele) => {
          if (selectedCourses[ele]?.fromTable !== true) {
            delete selectedCourses[ele]
          }
        })
      }
      if (source == SELECTED_COURSE_ENUM.TABLE) {
        courses.forEach((ele) => {
          delete selectedCourses[ele]
        })
      }
      return {
        ...state,
        selectedCourses: selectedCourses,
      }
    }

    case OrderConstants.REMOVE_ALL_SELECTED_COURSES: {
      return {
        ...state,
        selectedCourses: [],
      }
    }

    //Remove Error Fetch
    case OrderConstants.REMOVE_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: null,
      }
    }

    //Remove Error Action order
    case OrderConstants.REMOVE_ERROR_ACTION: {
      return {
        ...state,
        isLoading: false,
        errorActionCourse: null,
        errorActionChangeStatusEnMasse: null,
        isErrorActionWithSuccess: false,
      }
    }

    //Reset orders
    case OrderConstants.RESET_ORDER: {
      return {
        ...state,
        orders: [],
      }
    }

    //Fetch related course (commande en cours)
    case OrderConstants.FETCH_RELATED_COURSE_INFO: {
      return {
        ...state,
        isLoadingRelatedCourse: true,
        error: null,
        relatedCourse: null,
      }
    }

    case OrderConstants.FETCH_RELATED_COURSE_INFO_SUCCESS: {
      return {
        ...state,
        relatedCourse: action.payload.data.response,
        isLoadingRelatedCourse: false,
        error: null,
      }
    }

    case OrderConstants.FETCH_RELATED_COURSE_INFO_FAILURE: {
      let error = onFailure(action.payload)
      return {
        ...state,
        isLoadingRelatedCourse: false,
        error,
      }
    }

    //Fetch livraison details
    case OrderConstants.GET_ORDER_BY_LDV: {
      return {
        ...state,
        isLoading: true,
      }
    }

    case OrderConstants.GET_ORDER_BY_LDV_SUCCESS: {
      return {
        ...state,
        order: action.payload.data.response,
        isLoading: false,
        error: null,
      }
    }

    case OrderConstants.GET_ORDER_BY_LDV_FAILED: {
      let error = onFailure(action.payload)
      return {
        ...state,
        isLoading: false,
        error,
      }
    }

    // Reset order Details
    case OrderConstants.RESET_ORDER_DETAILS: {
      return {
        ...state,
        error: null,
        isLoadingHistoriqueLivraison: false,
        isLoadingHistoriqueRDV: false,
        orderHistoriqueRDV: [],
        orderHistoriqueLivraison: [],
        order: null,
        orderNotification: [],
        orderActivityHistory: [],
        isLoadingActivityHistory: false,
        orderAttachments: [],
        isLoadingOrderAttachments: false,
        isActionOrderAttachmentsDone: false,
        isLoadingOrderNotification: false,
        orderPhoto: [],
        isLoadingOrderPhoto: false,
        isActionOrderPhotoDone: false,
        orderSignature: [],
        isLoadingOrderSignature: false,
        isActionOrderSignatureDone: false,
        orderQuestions: [],
        isLoadingOrderQuestions: false,
        isLoadingActitvity: false,
        orderActivity: [],
        isLoadingGetConfig: false,
        configCanal: [],
      }
    }

    //Upload (Photo) Signature
    case OrderConstants.UPLOAD_SIGNATURE: {
      return {
        ...state,
        isLoadingUploadSignature: true,
        isActionOrderSignatureDone: false,
        errorUploadFile: false,
      }
    }

    case OrderConstants.UPLOAD_SIGNATURE_SUCCESS: {
      return {
        ...state,
        isLoadingUploadSignature: false,
        isActionOrderSignatureDone: true,
        errorUploadFile: false,
      }
    }

    case OrderConstants.UPLOAD_SIGNATURE_FAILED: {
      return {
        ...state,
        isLoadingUploadSignature: false,
        isActionOrderSignatureDone: false,
        errorUploadFile: true,
      }
    }

    //Fetch Signature Order
    case OrderConstants.GET_ORDER_SIGNATURE_BY_LDV: {
      return {
        ...state,
        isActionOrderSignatureDone: false,
        isLoadingOrderSignature: true,
      }
    }

    case OrderConstants.GET_ORDER_SIGNATURE_BY_LDV_SUCCESS: {
      return {
        ...state,
        orderSignature: action.payload.data.response.signaturesImages,
        isLoadingOrderSignature: false,
        error: null,
        isActionOrderSignatureDone: false,
      }
    }

    case OrderConstants.GET_ORDER_SIGNATURE_BY_LDV_FAILED: {
      let error = onFailure(action.payload)
      return {
        ...state,
        isLoadingOrderSignature: false,
        error,
        isActionOrderSignatureDone: false,
      }
    }

    //Upload  Photos
    case OrderConstants.UPLOAD_PHOTO: {
      return {
        ...state,
        isLoadingUploadPhoto: true,
        isActionOrderPhotoDone: false,
        errorUploadFile: false,
      }
    }

    case OrderConstants.UPLOAD_PHOTO_SUCCESS: {
      return {
        ...state,
        isLoadingUploadPhoto: false,
        isActionOrderPhotoDone: true,
        errorUploadFile: false,
      }
    }

    case OrderConstants.UPLOAD_PHOTO_FAILED: {
      return {
        ...state,
        isLoadingUploadPhoto: false,
        isActionOrderPhotoDone: false,
        errorUploadFile: true,
      }
    }

    //Fetch Photos Order
    case OrderConstants.GET_ORDER_PHOTO_BY_LDV: {
      return {
        ...state,
        isActionOrderPhotoDone: false,
        isLoadingOrderPhoto: true,
      }
    }

    case OrderConstants.GET_ORDER_PHOTO_BY_LDV_SUCCESS: {
      return {
        ...state,
        orderPhoto: action.payload.data.response.deliveryPictures,
        isLoadingOrderPhoto: false,
        error: null,
        isActionOrderPhotoDone: false,
      }
    }

    case OrderConstants.GET_ORDER_PHOTO_BY_LDV_FAILED: {
      let error = onFailure(action.payload)
      return {
        ...state,
        isLoadingOrderPhoto: false,
        error,
        isActionOrderPhotoDone: false,
      }
    }

    //Upload  Attachment
    case OrderConstants.UPLOAD_ATTACHMENT: {
      return {
        ...state,
        isLoadingUploadFile: true,
        isActionOrderAttachmentsDone: false,
        errorUploadFile: false,
      }
    }

    case OrderConstants.UPLOAD_ATTACHMENT_SUCCESS: {
      return {
        ...state,
        isLoadingUploadFile: false,
        isActionOrderAttachmentsDone: true,
        errorUploadFile: false,
      }
    }

    case OrderConstants.UPLOAD_ATTACHMENT_FAILED: {
      return {
        ...state,
        isLoadingUploadFile: false,
        isActionOrderAttachmentsDone: false,
        errorUploadFile: true,
      }
    }

    //Fetch Attachment Order
    case OrderConstants.GET_ORDER_ATTACHMENT_BY_LDV: {
      return {
        ...state,
        isActionOrderAttachmentsDone: false,
        isLoadingOrderAttachments: true,
      }
    }

    case OrderConstants.GET_ORDER_ATTACHMENT_BY_LDV_SUCCESS: {
      return {
        ...state,
        orderAttachments: action.payload.data.response.attachments,
        isLoadingOrderAttachments: false,
        error: null,
        isActionOrderAttachmentsDone: false,
      }
    }

    case OrderConstants.GET_ORDER_ATTACHMENT_BY_LDV_FAILED: {
      let error = onFailure(action.payload)
      return {
        ...state,
        isLoadingOrderAttachments: false,
        error,
        isActionOrderAttachmentsDone: false,
      }
    }

    //Set Error upload File (Photos && Signatures && Attachement)
    case OrderConstants.SET_ERROR_UPLOAD_FILE: {
      const { isError } = action.payload

      return {
        ...state,
        errorUploadFile: isError,
      }
    }

    // Notification de livraison
    case OrderConstants.GET_NOTIFICATION_ORDER: {
      return {
        ...state,
        isLoadingOrderNotification: true,
        error: null,
      }
    }

    case OrderConstants.GET_NOTIFICATION_ORDER_SUCCESS: {
      return {
        ...state,
        orderNotification: action.payload.data,
        isLoadingOrderNotification: false,
        error: null,
      }
    }

    case OrderConstants.GET_NOTIFICATION_ORDER_FAILED: {
      return {
        ...state,
        isLoadingOrderNotification: false,
        error: null,
      }
    }

    //Fetch historique delta cost
    case OrderConstants.GET_HISTORIQUE_DELTA_COST_ORDER: {
      return {
        ...state,
        isLoadingHistoriqueDeltaCost: true,
      }
    }
    case OrderConstants.GET_HISTORIQUE_DELTA_COST_ORDER_SUCCESS: {
      return {
        ...state,
        orderHistoriqueDeltaCost: action.payload.data.response,
        isLoadingHistoriqueDeltaCost: false,
      }
    }
    case OrderConstants.GET_HISTORIQUE_DELTA_COST_ORDER_FAILED: {
      return {
        ...state,
        isLoadingHistoriqueDeltaCost: false,
      }
    }

    //Fetch Historique de livraison
    case OrderConstants.GET_HISTORIQUE_LIVRAISON_ORDER: {
      return {
        ...state,
        isLoadingHistoriqueLivraison: true,
      }
    }
    case OrderConstants.GET_HISTORIQUE_LIVRAISON_ORDER_SUCCESS: {
      return {
        ...state,
        orderHistoriqueLivraison: getHistoriqueLivraison(
          action.payload.data.response
        ),

        isLoadingHistoriqueLivraison: false,
      }
    }
    case OrderConstants.GET_HISTORIQUE_LIVRAISON_ORDER_FAILED: {
      return {
        ...state,
        isLoadingHistoriqueLivraison: false,
      }
    }

    //Fetch Historique de RDV
    case OrderConstants.GET_HISTORIQUE_RDV_ORDER: {
      return {
        ...state,
        isLoadingHistoriqueRDV: true,
      }
    }
    case OrderConstants.GET_HISTORIQUE_RDV_ORDER_SUCCESS: {
      return {
        ...state,
        orderHistoriqueRDV: collectDeliveryData(action.payload.data.response),
        isLoadingHistoriqueRDV: false,
      }
    }
    case OrderConstants.GET_HISTORIQUE_RDV_ORDER_FAILED: {
      return {
        ...state,
        isLoadingHistoriqueRDV: false,
      }
    }

    // GET Historique activité
    case OrderConstants.GET_ACTIVITY_ORDER_HISTORY: {
      return {
        ...state,
        isLoadingActivityHistory: true,
      }
    }
    case OrderConstants.GET_ACTIVITY_ORDER_HISTORY_SUCCESS: {
      return {
        ...state,
        orderActivityHistory: getActivityHistory(action.payload.data.response),
        isLoadingActivityHistory: false,
      }
    }
    case OrderConstants.GET_ACTIVITY_ORDER_HISTORY_FAILED: {
      return {
        ...state,
        isLoadingActivityHistory: false,
      }
    }

    //Fetch Motifs (Annulation && RDI && Retrait impossible && Livraison impossible && terminee && enlevee)
    case OrderConstants.FETCH_ORDER_REASONS: {
      return {
        ...state,
        isLoadingReasonsOrder: true,
      }
    }
    case OrderConstants.FETCH_ORDER_REASONS_SUCCESS: {
      return {
        ...state,
        orderReasons: action.payload.data.response,
        isLoadingReasonsOrder: false,
      }
    }

    case OrderConstants.FETCH_ORDER_REASONS_FAILURE: {
      let error = onFailure(action.payload)
      return {
        ...state,
        isLoadingReasonsOrder: false,
        error,
      }
    }

    //Change Status (Deprogram Order && Desattribution Order && Anullation Order)
    case OrderConstants.CHANGE_STATUS_COURSE_DISPATCH: {
      return {
        ...state,
        isLoadingActionOrder: true,
      }
    }
    case OrderConstants.CHANGE_STATUS_COURSE_DISPATCH_SUCCESS: {
      return {
        ...state,
        isLoadingActionOrder: false,
        isActionOrderDone: true,
      }
    }
    case OrderConstants.CHANGE_STATUS_COURSE_DISPATCH_FAILURE: {
      return {
        ...state,
        isLoadingActionOrder: false,
        errorActionCourse:
          action.payload?.data?.response?.data?.message ||
          action.payload?.data?.data?.message ||
          'Request failed with status code 404 ',
      }
    }

    // Program Course
    case OrderConstants.PROGRAM_COURSE: {
      return {
        ...state,
        isLoadingActionOrder: true,
      }
    }
    case OrderConstants.PROGRAM_COURSE_SUCESS: {
      return {
        ...state,
        isLoadingActionOrder: false,
        isActionOrderDone: true,
      }
    }
    case OrderConstants.PROGRAM_COURSE_FAILURE: {
      return {
        ...state,
        isLoadingActionOrder: false,
        errorActionCourse:
          action.payload?.data?.response?.data?.message ||
          action.payload?.data?.data?.message ||
          'Request failed with status code 404 ',
      }
    }

    //Attribution Chauffeur warrning
    case OrderConstants.ASSIGN_DRIVER: {
      return {
        ...state,
        isLoadingActionOrder: true,
      }
    }

    case OrderConstants.ASSIGN_DRIVER_SUCCESS: {
      return {
        ...state,
        isLoadingActionOrder: false,
        isActionOrderDone: true,
      }
    }

    case OrderConstants.ASSIGN_DRIVER_FAILURE: {
      return {
        ...state,
        isLoadingActionOrder: false,
        errorActionCourse:
          action.payload?.data?.response?.data?.message ||
          action.payload?.data?.data?.message ||
          'Courses cannot be assigned ',
      }
    }

    //Affecter à une autre agence
    case OrderConstants.UPDATE_WAREHOUSE: {
      return {
        ...state,
        isLoadingActionOrder: true,
      }
    }
    case OrderConstants.UPDATE_WAREHOUSE_SUCCESS: {
      return {
        ...state,
        isLoadingActionOrder: false,
        isActionOrderDone: true,
      }
    }
    case OrderConstants.UPDATE_WAREHOUSE_FAILURE: {
      return {
        ...state,
        isLoadingActionOrder: false,
        errorActionCourse:
          action.payload?.data?.response?.data?.message ||
          action.payload?.data?.data?.message ||
          'Request failed with status code 404 ',
      }
    }

    //Rendez-Vous Impossible
    case OrderConstants.SET_RDV_IMPOSSIBLE: {
      return {
        ...state,
        isLoadingActionOrder: true,
      }
    }
    case OrderConstants.SET_RDV_IMPOSSIBLE_SUCCESS: {
      return {
        ...state,
        isLoadingActionOrder: false,
        isActionOrderDone: true,
      }
    }
    case OrderConstants.SET_RDV_IMPOSSIBLE_FAILURE: {
      return {
        ...state,
        isLoadingActionOrder: false,
        errorActionCourse:
          action.payload?.data?.response?.data?.message ||
          action.payload?.data?.data?.message ||
          'Request failed with status code 404 ',
      }
    }

    //Reset Action Course
    case OrderConstants.RESET_ORDER_ACTION: {
      return {
        ...state,
        isActionOrderDone: false,
      }
    }

    //Fetch Collaborateur (creation discussion)
    case OrderConstants.GET_COLLABORATEURS: {
      return {
        ...state,
        isLoadingGetCollaborateurs: true,
      }
    }

    case OrderConstants.GET_COLLABORATEURS_SUCCESS: {
      const { response } = action.payload.data
      return {
        ...state,
        collaborateurs: response || [],
        isLoadingGetCollaborateurs: false,
      }
    }

    case OrderConstants.GET_COLLABORATEURS_FAILED: {
      return {
        ...state,
        isLoadingGetCollaborateurs: false,
      }
    }

    //Fetch Clients Pro (creation discussion)
    case OrderConstants.GET_CLIENTSPRO: {
      return {
        ...state,
        isLoadingGetClientsPro: true,
      }
    }

    case OrderConstants.GET_CLIENTSPRO_SUCCESS: {
      const { response } = action.payload.data
      return {
        ...state,
        clientsPro: response,
        isLoadingGetClientsPro: false,
      }
    }

    case OrderConstants.GET_CLIENTSPRO_FAILED: {
      return {
        ...state,
        isLoadingGetClientsPro: false,
      }
    }

    //Set Loading
    case OrderConstants.SET_LOADING: {
      return {
        ...state,
        isLoading: true,
      }
    }

    // refresh courses
    case OrderConstants.REFRESH_FETCH_ORDER_BY_FILTER: {
      if (!state.isLoading) {
        return {
          ...state,
          isLoadingRefresh: true,
          error: null,
        }
      } else {
        return {
          ...state,
        }
      }
    }

    case OrderConstants.REFRESH_FETCH_ORDER_BY_FILTER_SUCCESS: {
      const { data } = action.payload.data
      return {
        ...state,
        orders: data,
        isLoadingRefresh: false,
        error: null,
        coursesChecked: [],
        checkCoursesAll: false,
      }
    }

    case OrderConstants.REFRESH_FETCH_ORDER_BY_FILTER_FAILED: {
      let error = onFailure(action.payload)
      return {
        ...state,
        isLoadingRefresh: false,
        error: error,
      }
    }

    //refresh courses with search onglet
    case OrderConstants.REFRESH_COURSES_ONGLET_CORNER_SEARCH:
    case OrderConstants.REFRESH_COURSES_CORNER_SEARCH: {
      if (!state.isLoading) {
        return {
          ...state,
          isLoadingRefresh: true,
          error: null,
        }
      } else {
        return {
          ...state,
        }
      }
    }
    case OrderConstants.REFRESH_COURSES_ONGLET_CORNER_SEARCH_SUCCESS: {
      const { response } = action.payload.data
      return {
        ...state,
        orders: response.map((el) => el.course),
        isLoadingRefresh: false,
        error: null,
        coursesChecked: [],
        checkCoursesAll: false,
      }
    }

    case OrderConstants.REFRESH_COURSES_ONGLET_CORNER_SEARCH_FAILED: {
      let error = onFailure(action.payload)
      return {
        ...state,
        isLoadingRefresh: false,
        error,
      }
    }
    //Fetch MacroCanal
    case OrderConstants.FETCH_MACRO_CANAL: {
      return {
        ...state,
        isLoadingMacroCanal: true,
        errorImportCourses: null,
      }
    }
    case OrderConstants.FETCH_MACRO_CANAL_SUCCESS: {
      const { macroCanals } = action.payload.data

      return {
        ...state,
        macroCanals,
        isLoadingMacroCanal: false,
      }
    }

    case OrderConstants.FETCH_MACRO_CANAL_FAILED: {
      let error = onFailure(action.payload)
      return {
        ...state,
        isLoadingMacroCanal: false,
        errorImportCourses: error?.statusText,
      }
    }

    //Importer Courses
    case OrderConstants.IMPORT_COURSES: {
      return {
        ...state,
        isLoadingActionImportCourses: true,
        errorImportCourses: null,
      }
    }
    case OrderConstants.IMPORT_COURSES_SUCCESS: {
      const { response } = action.payload.data
      return {
        ...state,
        isActionImportCourses: true,
        isLoadingActionImportCourses: false,
        errorImportCoursesMessage: response,
      }
    }

    case OrderConstants.IMPORT_COURSES_FAILED: {
      let error = onFailure(action.payload)
      return {
        ...state,
        isLoadingActionImportCourses: false,
        errorImportCourses: error?.statusText,
      }
    }

    //Remove Error Importer Courses
    case OrderConstants.REMOVE_ERROR_IMPORT_COURSES: {
      return {
        ...state,
        errorImportCourses: null,
        errorImportCoursesMessage: [],
      }
    }
    //Reset Action Importer Courses
    case OrderConstants.RESET_ACTION_IMPORT_COURSES: {
      return {
        ...state,
        isActionImportCourses: false,
      }
    }
    //Ajouter à une navette
    case OrderConstants.ADD_TO_NAVETTE: {
      return {
        ...state,
        isLoadingActionOrder: true,
      }
    }
    case OrderConstants.ADD_TO_NAVETTE_SUCCESS: {
      return {
        ...state,
        isLoadingActionOrder: false,
        isActionOrderDone: true,
      }
    }
    case OrderConstants.ADD_TO_NAVETTE_FAILURE: {
      return {
        ...state,
        isLoadingActionOrder: false,
        errorActionCourse:
          action.payload?.data?.response?.data?.message ||
          action.payload?.data?.data?.message ||
          'Request failed with status code 404 ',
      }
    }
    //chnager de storedelivery vers clientdelivery
    case OrderConstants.TO_CLIENT_DELIVERY: {
      return {
        ...state,
        isLoadingActionOrder: true,
      }
    }
    case OrderConstants.TO_CLIENT_DELIVERY_SUCCESS: {
      return {
        ...state,
        isLoadingActionOrder: false,
        isActionOrderDone: true,
      }
    }
    case OrderConstants.TO_CLIENT_DELIVERY_FAILURE: {
      return {
        ...state,
        isLoadingActionOrder: false,
        errorActionCourse:
          action.payload?.data?.response?.data?.message ||
          action.payload?.data?.data?.message ||
          'Request failed with status code 404 ',
      }
    }

    //SET DUPLICATED COURSE
    case OrderConstants.SET_DUPLICATED_COURSE: {
      return {
        ...state,
        duplicatedCourse: action.payload,
      }
    }
    case OrderConstants.GET_DUPLICATED_COURSE: {
      return {
        ...state,
        isLoading: true,
      }
    }

    //SET DUPLICATED COURSE
    case OrderConstants.SET_ACTION_ORDER: {
      return {
        ...state,
        isActionOrderDone: action.payload,
      }
    }

    case OrderConstants.GET_ACTIVITY: {
      return {
        ...state,
        isLoadingActitvity: true,
      }
    }
    case OrderConstants.GET_ACTIVITY_SUCCESS: {
      return {
        ...state,
        isLoadingActitvity: false,

        orderActivity: action?.payload.data,
      }
    }
    case OrderConstants.GET_ACTIVITY_FAILURE: {
      return {
        ...state,
        isLoadingActitvity: false,
        errorActivity:
          action.payload?.data?.response?.data?.message ||
          action.payload?.data?.data?.message ||
          'Request failed with status code 404 ',
      }
    }

    case OrderConstants.SET_CHECK_COURSES: {
      const { codeCourse, courseCheck } = action.payload

      let coursesCheckedState = state.coursesChecked.reduce(
        (a, v) => ({ ...a, [v.codeCourse]: v }),
        {}
      )
      let keysCodesCourse = Object.keys(coursesCheckedState)
      if (keysCodesCourse.includes(codeCourse)) {
        delete coursesCheckedState[codeCourse]
      } else {
        coursesCheckedState[codeCourse] = courseCheck
      }
      let coursesChecked = Object.values(coursesCheckedState)
      return {
        ...state,
        coursesChecked: [...coursesChecked],
        checkCoursesAll: coursesChecked.length >= state.orders.length,
      }
    }

    case OrderConstants.SET_ALL_CHECK_COURSES: {
      let coursesChecked = []
      let checkCoursesAll = false
      if (state.coursesChecked.length >= state.orders.length) {
        coursesChecked = []
      } else {
        const connectedUser = JSON.parse(localStorage.getItem('currentUser'))

        coursesChecked = state.orders.map((order) => {
          return {
            collaborateurId: connectedUser.id,
            departureType: order?.departureType,
            courseType: order?.courseMetadata?.courseType,
            codeCourse: order.code,
            courseSource: order.courseSource,
            driver:
              order?.chauffeur != null && order?.chauffeur?.id
                ? { id: order.chauffeur.id }
                : undefined,
            method: 'corner/clotureEnMasse',
          }
        })
        checkCoursesAll = true
      }

      return {
        ...state,
        coursesChecked: [...coursesChecked],
        checkCoursesAll,
      }
    }

    case OrderConstants.INIT_CHECK_COURSES: {
      return {
        ...state,
        coursesChecked: [],
        checkCoursesAll: false,
      }
    }

    //Changement Status en masse
    case OrderConstants.CHANGE_STATUS_COURSE_EN_MASSE_DISPATCH: {
      return {
        ...state,
        isLoadingActionOrder: true,
      }
    }
    case OrderConstants.CHANGE_STATUS_COURSE_EN_MASSE_DISPATCH_SUCCESS: {
      return {
        ...state,
        isLoadingActionOrder: false,
        isActionOrderDone: true,
        coursesChecked: [],
        checkCoursesAll: false,
      }
    }
    case OrderConstants.CHANGE_STATUS_COURSE_EN_MASSE_DISPATCH_FAILURE: {
      return {
        ...state,
        isLoadingActionOrder: false,
        isErrorActionWithSuccess:
          action?.payload?.data?.response?.data?.message?.successCourses?.length >=
          1,

        errorActionChangeStatusEnMasse:
          action.payload?.data?.response?.data?.message ||
          action.payload?.data?.data?.message ||
          'Request failed with status code 404 ',
        coursesChecked: [],
        checkCoursesAll: false,
      }
    }

    //Fetch livraison details
    case OrderConstants.GET_ORDER_QUESTION_BY_LDV: {
      return {
        ...state,
        isLoadingOrderQuestions: true,
      }
    }

    case OrderConstants.GET_ORDER_QUESTION_BY_LDV_SUCCESS: {
      return {
        ...state,
        orderQuestions: action.payload.data?.response || [],
        isLoadingOrderQuestions: false,
      }
    }

    case OrderConstants.GET_ORDER_QUESTION_BY_LDV_FAILURE: {
      return {
        ...state,
        isLoadingOrderQuestions: false,
      }
    }
    //Fetch config
    case OrderConstants.GET_CONFIG_CANAL: {
      return {
        ...state,
        isLoadingGetConfig: true,
      }
    }

    case OrderConstants.GET_CONFIG_CANAL_SUCCESS: {
      const { response } = action.payload.data

      return {
        ...state,
        configCanal: response.metaValue,
        isLoadingGetConfig: false,
      }
    }

    case OrderConstants.GET_CONFIG_CANAL_FAILED: {
      return {
        ...state,
        isLoadingGetConfig: false,
        configCanal: null,
      }
    }
    case OrderConstants.RESET_CONFIG_CANAL: {
      return {
        ...state,
        isLoadingGetConfig: false,
        configCanal: [],
      }
    }

    //Importer Tournee
    case OrderConstants.IMPORT_TOURNEE: {
      return {
        ...state,
        isLoadingActionImportTournee: true,
        errorImportTournee: null,
        errorImportTourneeMessage: [],
      }
    }
    case OrderConstants.IMPORT_TOURNEE_SUCCESS: {
      const { response } = action.payload.data
      return {
        ...state,
        isActionImportTournee: true,
        errorImportTourneeMessage: response,
        isLoadingActionImportTournee: false,
      }
    }

    case OrderConstants.IMPORT_TOURNEE_FAILED: {
      let error = onFailure(action.payload)
      return {
        ...state,
        isLoadingActionImportTournee: false,
        errorImportTournee: error?.statusText,
      }
    }

    //Remove Error Importer Tournee
    case OrderConstants.REMOVE_ERROR_IMPORT_TOURNEE: {
      return {
        ...state,
        errorImportTournee: null,
        errorImportTourneeMessage: [],
      }
    }
    //Reset Action Importer Tournee
    case OrderConstants.RESET_ACTION_IMPORT_TOURNEE: {
      return {
        ...state,
        isActionImportTournee: false,
      }
    }

    default:
      return state
  }
}
