import { connect } from 'react-redux'
import DashTabs from '../../../../pages/main/Entrepot/dashboard/Tabs'

import {
  getCanalVentes,
  getCanalVentesCount,
  getIsLoadingCanalVentes,
} from '../../../../redux/documents/selectors'
import {
  fetchCanalVenteDash,
  fetchCanalVenteDashMore,
} from '../../../../redux/documents/actions'

const mapStateToProps = (state) => ({
  canalVentes: getCanalVentes(state),
  isLoadingCanalVentes: getIsLoadingCanalVentes(state),
  canalVentesCount: getCanalVentesCount(state),
})

const mapDisptachToProps = (dispatch) => ({
  fetchCanalVenteDash: (filtre) => {
    dispatch(fetchCanalVenteDash(filtre))
  },
  fetchCanalVenteDashMore: (filtre) => {
    dispatch(fetchCanalVenteDashMore(filtre))
  },
})

export default connect(mapStateToProps, mapDisptachToProps)(DashTabs)
