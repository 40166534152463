import { COURSE_TYPE, EmailList } from './values'
import * as moment from 'moment'
import queryString from 'query-string'
import React from 'react'

const EMPTY = null
export const NOT_AVAILABLE = 'N/A'
export const NUMERIC_DATE_FORMAT = 'DD/MM/YYYY'
export const ALPHABETIC_DATE_FORMAT = 'LL'
export const hourFormat = 'HH:mm'
export const getCreneau = (
  dropOffStart,
  dropOffEnd,
  dateDemarrageMeta,
  dateDemarrage,
  dateFormat
) => {
  if (dropOffStart && dropOffEnd) {
    const start = moment(dropOffStart).format(dateFormat)
    const end = moment(dropOffEnd).format(dateFormat)
    const startHour = moment(dropOffStart).format(hourFormat)
    const endHour = moment(dropOffEnd).format(hourFormat)
    if (start === end) {
      if (startHour === endHour) {
        return `${start} - ${startHour}`
      }
      return `${start} ${startHour} - ${endHour}`
    }
    return `${start} ${startHour} - ${end} ${endHour}`
  }
  if (
    dateDemarrageMeta &&
    dateDemarrageMeta.deliveryWindow &&
    (dropOffStart || dateDemarrage)
  ) {
    const availableDate = dropOffStart ? dropOffStart : dateDemarrage
    const start = moment(availableDate).format(dateFormat)
    const startHour = moment(availableDate).format(hourFormat)
    const endHour = moment(availableDate)
      .add(dateDemarrageMeta.deliveryWindow, 'minutes')
      .format(hourFormat)
    return `${start} ${startHour} - ${endHour}`
  }
  return EMPTY
}
export const getCourseType = (courseType) => {
  const courseTypeFind = COURSE_TYPE[courseType]
  return courseTypeFind
}
export const getCreneauDetails = (
  dropOffStart,
  dropOffEnd,
  dateDemarrageMeta,
  dateDemarrage,
  dateFormat
) => {
  if (dropOffStart && dropOffEnd) {
    const start = moment(dropOffStart).format(dateFormat)
    const end = moment(dropOffEnd).format(dateFormat)
    const startHour = moment(dropOffStart).format(hourFormat)
    const endHour = moment(dropOffEnd).format(hourFormat)
    if (start === end) {
      if (startHour === endHour) {
        return { date: start, heure: endHour }
      }
      return { date: start, heure: `${startHour} - ${endHour}` }
    }
    return { date: start, heure: `${startHour} - ${endHour}` }
  }
  if (
    dateDemarrageMeta &&
    dateDemarrageMeta.deliveryWindow &&
    (dropOffStart || dateDemarrage)
  ) {
    const availableDate = dropOffStart ? dropOffStart : dateDemarrage
    const start = moment(availableDate).format(dateFormat)
    const startHour = moment(availableDate).format(hourFormat)
    const endHour = moment(availableDate)
      .add(dateDemarrageMeta.deliveryWindow, 'minutes')
      .format(hourFormat)
    return { date: start, heure: `${startHour} - ${endHour}` }
  }
  return EMPTY
}
export const getCreneauHours = (
  dropOffStart,
  dropOffEnd,
  dateDemarrageMeta,
  dateDemarrage,
  separator
) => {
  if (dropOffStart && dropOffEnd) {
    const startHour = moment(dropOffStart).format(hourFormat).substring(0, 2)
    const endHour = moment(dropOffEnd).format(hourFormat).substring(0, 2)
    return `${startHour}${separator}${endHour}`
  }
  if (
    dateDemarrageMeta &&
    dateDemarrageMeta.deliveryWindow &&
    (dropOffStart || dateDemarrage)
  ) {
    const availableDate = dropOffStart ? dropOffStart : dateDemarrage
    const startHour = moment(availableDate).format(hourFormat).substring(0, 2)
    const endHour = moment(availableDate)
      .add(dateDemarrageMeta.deliveryWindow, 'minutes')
      .format(hourFormat)
      .substring(0, 2)
    return `${startHour}${separator}${endHour}`
  }
  return EMPTY
}

export const getCourseLatLng = (tours) => {
  return tours.visits?.map((visit) => {
    return {
      lat: visit.cachedAddress.lat,
      lng: visit.cachedAddress.lng,
    }
  })
}
export const getDeliveryDate = (tours) => {
  return (
    [
      ...new Set(
        tours.map((tour) => moment(tour.deliveryDate).format(NUMERIC_DATE_FORMAT))
      ),
    ].length == 1
  )
}

export const insetObjectTable = (table, objectInserted, index) => {
  let newTable = []

  if (index === 0) {
    newTable = newTable.concat(objectInserted, table.slice(1))
  } else if (index === table.length - 1) {
    newTable = newTable.concat(table.slice(0, -1), objectInserted)
  } else if (index > 0) {
    newTable = newTable.concat(
      table.slice(0, index),
      objectInserted,
      table.slice(index + 1)
    )
  }
  return newTable
}
export const getStepLabel = (step) => {
  switch (step) {
    case 1:
      return 'Réception'

    case 2:
      return 'Préparation'

    case 3:
      return 'Chargement'

    case 4:
      return 'Livraison'

    case 5:
      return 'Mise en rayon'

    case 6:
      return 'Dégroupement réception'

    case 7:
      return 'Dégroupement livraisons'
    case 8:
      return 'Traction planifié'
    case 9:
      return 'Mise en traction'
    case 10:
      return 'Retour'
    case 11:
      return 'Enlèvement planifié'
    case 12:
      return 'Arrivée agence de retour'
    default:
      return 'étage non définie'
  }
}

export const getImage = (url) => {
  const api = process.env.REACT_APP_API_URL
  const token = localStorage.getItem('token')
  const dataSend = {
    url,
    token,
  }
  return `${api}downloadimage?${queryString.stringify(dataSend)}`
}

export const removeNull = (obj) => {
  for (let key in obj) {
    if (
      obj[key] === null ||
      obj[key] === '' ||
      (Array.isArray(obj[key]) && obj[key].length == 0)
    ) {
      delete obj[key]
    }
  }
  return obj
}

export const getCreneauHoursMinutes = (dropOffStart, dropOffEnd, separator) => {
  if (dropOffStart && dropOffEnd) {
    const startHour = moment(dropOffStart).format(hourFormat)
    const endHour = moment(dropOffEnd).format(hourFormat)
    return `${startHour}${separator}${endHour}`
  }
  return EMPTY
}

export function formatDatesWithBlueColor(inputString) {
  const dateRegex = /(\d{2}\/\d{2}\/\d{4} \d{2}:\d{2})/g
  const parts = inputString.split(dateRegex)

  return parts.map((part, index) => {
    if (dateRegex.test(part)) {
      return (
        <span key={index} style={{ color: '#1976D2' }}>
          {part}
        </span>
      )
    } else {
      return <span key={index}>{part}</span>
    }
  })
}

export const emailCheck = (email) => {
  return EmailList.includes(email)
}

export function convertirMinutesEnHeuresEtMinutes(minutes) {
  const heures = Math.floor(minutes / 60)
  const minutesRestantes = Math.floor(minutes % 60)

  return heures ? `${heures}h.${minutesRestantes}min` : `${minutesRestantes}min`
}

export function getFilterfromCourse(data) {
  const result = {}

  data.forEach((item) => {
    const keys = Object.keys(item)
    keys
      .filter((key) =>
        [
          'receptionNumber',
          'courseType',
          'client',
          'orderGiver',
          'weight',
          'endLocation',
          'codePostale',
          'keys',
          'city',
        ].includes(key)
      )
      .forEach((key) => {
        const keyValue = item[key]
        if (!result[key]) {
          result[key] = []
        }
        if (Array.isArray(keyValue)) {
          keyValue.forEach((subValue) => {
            if (!result[key].includes(subValue)) {
              result[key].push(subValue)
            }
          })
        } else if (typeof keyValue === 'object' && keyValue !== null) {
          if (
            !result[key].some(
              (existingValue) =>
                JSON.stringify(existingValue) === JSON.stringify(keyValue)
            )
          ) {
            if (key === 'endLocation' && keyValue.name) {
              // Ensure unique addresses
              if (!result[key].some((existing) => existing === keyValue.name)) {
                result[key].push(keyValue.name)
              }
            } else {
              typeof keyValue !== 'object' && result[key].push(keyValue)
            }
          }
        } else {
          if (!result[key].includes(keyValue)) {
            result[key].push(keyValue)
          }
        }
      })
  })

  return result
}

export function fetchCoursesWithFilter(dataTable, filter) {
  return dataTable.filter((course) => {
    let matchingNF = filter?.receptionNumber
      ? filter.receptionNumber.includes(course.id)
      : true

    let matchingCient = filter?.client ? filter.client.includes(course.client) : true
    let matchingWeight = filter?.weight
      ? filter.weight.includes(course.weight)
      : true
    let matchingOrderGiver = filter?.orderGiver
      ? filter.orderGiver.includes(course.orderGiver)
      : true
    let matchingAddress = filter?.endLocation
      ? filter.endLocation.includes(course.endLocation.name)
      : true
    let matchingType = filter?.courseType
      ? filter.courseType.includes(course.courseType)
      : true

    let matchingKeys = course.keys.filter((val) =>
      filter?.keys ? filter.keys.includes(val) : true
    )
    let matchingCodePostal = filter?.codePostale
      ? filter.codePostale.includes(course.codePostale)
      : true
    let matchingCity = filter?.city ? filter.city.includes(course.city) : true

    return (
      matchingKeys.length > 0 &&
      matchingCodePostal &&
      matchingNF &&
      matchingCient &&
      matchingAddress &&
      matchingType &&
      matchingOrderGiver &&
      matchingWeight &&
      matchingCity
    )
  })
}
