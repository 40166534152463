import React, { useEffect, useState } from 'react'
import ShipmentGrid from './Grid'
import PropTypes from 'prop-types'
import Loading from '../../../components/Loading'
import Error from '../../../components/Modal/HttpErrorPopUp'

import './index.scss'
import NoDataFound from '../../../components/NoDataFound'
import CustomPagination from '../../../components/Pagination'
import OrdersTable from './Table'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import { LimitPaginationList } from '../../../components/Pagination/LimitPaginationList'
import NavFilter from '../../../containers/main/NavFilter'
import { useHistory } from 'react-router-dom'
import Dialog from '../../../components/ModalDialog'
import IconImportationCourses from '../../../assets/icons/importationCourses'
import Divider from '@mui/material/Divider'

import ActionCourse from '../../../containers/main/Orders/actionOrder'
export default function Orders({
  getShipmentByFilter,
  getShipmentByAgents,
  shipments,
  isLoading,
  error,
  count,
  limit,
  pageCount,
  pageChanged,
  myCommandsIsOn,
  pageLimitChanged,
  displayGrid,
  removeError,
  isActionDone,
  filterReducer,
  setFilter,
  errorFilter,
  removeErrorFilter,
  resetFilterCommande,
  searchCornerOnglet,
  searchCorner,
  fetchSearchCornerSave,
  resetShipments,
  errorActionCourse,
  removeErrorAction,
  resetOrderAction,
  defaultFilterCommande,
  setDefaultFilterCommande,
  saveDefaultFilterCommander,
  resetDefaultFilterCommander,
  errorImportCourses,
  removeErrorImportCourses,
  errorImportCoursesMessage,
  sort,
  orderBy,
  setAllCheckCourse,
  setCheckCourse,
  coursesChecked,
  checkCoursesAll,
  sortShipment,
  fetchSearchOngletCornerSave,
  errorImportTournee,
  removeErrorImportTournees,
  errorImportTourneeMessage,
}) {
  const [page, setPage] = React.useState(1)
  const [limitIndex, setLimitIndex] = React.useState(0)
  const [limitLabel, setLimit] = useState(LimitPaginationList[0].label)
  const [open, setOpen] = useState(false)

  const history = useHistory()

  const shipmentTotal = count
  const handlePageChange = (value) => {
    setPage(value)
    const isSearchCornerOnglet =
      searchCornerOnglet &&
      count > 1 &&
      !displayGrid &&
      Number(localStorage.getItem('OngletOpened')) != 0
    const isSearchCorner =
      ((!displayGrid && Number(localStorage.getItem('OngletOpened')) == 0) ||
        displayGrid) &&
      searchCorner &&
      count > 1

    const payloadSearchCornerOnglet = {
      criteria: searchCornerOnglet?.label,
      type: searchCornerOnglet?.categorie.code,
    }
    const payloadSearchCorner = {
      criteria: searchCorner?.label,
      type: searchCorner?.categorie.code,
    }
    pageChanged(
      (value - 1) * limit,
      myCommandsIsOn,
      isSearchCornerOnglet,
      isSearchCorner,
      payloadSearchCornerOnglet,
      payloadSearchCorner
    )
  }
  useEffect(() => {
    if (isActionDone) {
      resetOrderAction()
      resetShipments()
      if (myCommandsIsOn) {
        getShipmentByAgents()
      } else {
        if ((searchCornerOnglet || searchCorner) && count > 0) {
          const payloadSearchCornerOnglet = {
            criteria: searchCornerOnglet?.label,
            type: searchCornerOnglet?.categorie.code,
          }
          const payloadSearchCorner = {
            criteria: searchCorner?.label,
            type: searchCorner?.categorie.code,
          }
          if (
            searchCornerOnglet &&
            count > 1 &&
            !displayGrid &&
            Number(localStorage.getItem('OngletOpened')) != 0
          ) {
            fetchSearchOngletCornerSave(payloadSearchCornerOnglet)
          } else if (
            ((!displayGrid && Number(localStorage.getItem('OngletOpened')) == 0) ||
              displayGrid) &&
            searchCorner &&
            count > 1
          ) {
            fetchSearchCornerSave(payloadSearchCorner)
          } else {
            getShipmentByFilter()
          }
        } else {
          getShipmentByFilter()
        }
      }
    }
  }, [isActionDone])

  useEffect(() => {
    return () => {
      resetShipments()
      resetOrderAction()
    }
  }, [])

  const handlePageLimitChange = (limit) => {
    setPage(1)
    const isSearchCornerOnglet =
      searchCornerOnglet &&
      count > 1 &&
      !displayGrid &&
      Number(localStorage.getItem('OngletOpened')) != 0
    const isSearchCorner =
      ((!displayGrid && Number(localStorage.getItem('OngletOpened')) == 0) ||
        displayGrid) &&
      searchCorner &&
      count > 1

    const payloadSearchCornerOnglet = {
      criteria: searchCornerOnglet?.label,
      type: searchCornerOnglet?.categorie.code,
    }
    const payloadSearchCorner = {
      criteria: searchCorner?.label,
      type: searchCorner?.categorie.code,
    }
    pageLimitChanged(
      limit,
      myCommandsIsOn,
      isSearchCornerOnglet,
      isSearchCorner,
      payloadSearchCornerOnglet,
      payloadSearchCorner
    )
  }
  const onLimitChange = (index, value) => {
    setLimitIndex(index)
    setLimit(value)
  }

  useEffect(() => {
    setPage(1)
  }, [pageCount])
  const onErrorClose = () => {
    setOpen(false)
    removeError()
  }
  useEffect(() => {
    setOpen(error !== null)
  }, [error])

  const [sortDirection, setOrder] = useState('')
  const [orderByColumn, setOrderBy] = useState('')
  const [openSnackbar, setOpenSnackbar] = useState(false)

  useEffect(() => {
    setOrderBy(sort || '')
  }, [sort])
  useEffect(() => {
    setOrder(orderBy?.toLowerCase() || '')
  }, [orderBy])
  const createSortHandler = (sortName) => {
    let direction
    if (sortName === orderByColumn) {
      if (sortDirection === 'desc') {
        direction = 'asc'
        setOrderBy(sortName)
        setOrder(direction)
        sortShipment(
          {
            ...filterReducer,
            sort: [sortName],
            orderBy: [direction.toUpperCase()],
          },
          false
        )
      } else if (sortDirection === 'asc') {
        setOrderBy('')
        setOrder('')
        sortShipment(
          {
            ...filterReducer,
            sort: null,
            orderBy: null,
          },
          false
        )
      } else {
        direction = 'desc'
        setOrderBy(sortName)
        setOrder(direction)
        sortShipment(
          {
            ...filterReducer,
            sort: [sortName],
            orderBy: [direction.toUpperCase()],
          },
          false
        )
      }
    } else {
      direction = 'desc'
      setOrderBy(sortName)
      setOrder(direction)
      sortShipment(
        {
          ...filterReducer,
          sort: [sortName],
          orderBy: [direction.toUpperCase()],
        },
        false
      )
    }
  }

  const [openErrorFilter, setOpenErrorFilter] = useState(false)
  const onErrorFilterClose = () => {
    setOpenErrorFilter(false)
    removeErrorFilter()
  }
  useEffect(() => {
    if (errorFilter) setOpenErrorFilter(true)
  }, [errorFilter])

  const handleCloseErrorAction = () => {
    removeErrorAction()
  }

  const [openErrorImport, setOpenErrorImport] = useState(false)

  useEffect(() => {
    if (errorImportCourses || errorImportTournee) setOpenErrorImport(true)
  }, [errorImportCourses, errorImportTournee])

  const [openErrorImportMessage, setOpenErrorImportMessage] = useState(false)
  const [errorImportMessage, setErrorImportMessage] = useState([])
  const onErrorImportClose = () => {
    setOpenErrorImport(false)
    setOpenErrorImportMessage(false)
    removeErrorImportCourses()
    removeErrorImportTournees()
    setErrorImportMessage([])
  }

  useEffect(() => {
    if (errorImportCoursesMessage.length > 0) {
      setOpenErrorImportMessage(true)
      setErrorImportMessage(errorImportCoursesMessage)
    }
  }, [errorImportCoursesMessage])
  useEffect(() => {
    if (errorImportTourneeMessage.length > 0) {
      setOpenErrorImportMessage(true)
      setErrorImportMessage(errorImportTourneeMessage)
    }
  }, [errorImportTourneeMessage])

  return (
    <>
      <div className="container">
        {openErrorImportMessage && (
          <Dialog
            maxWidthDialog={'lg'}
            open={openErrorImportMessage}
            iconTitle={
              <IconImportationCourses
                style={{
                  marginRight: '10px',
                  marginTop: '3px ',
                  fontSize: '1.5rem',
                }}
              />
            }
            title={'Alertes'}
            content={
              <div
                style={{
                  paddingBottom: '16px',
                  paddingRight: '16px',
                }}
              >
                {errorImportMessage.map((messageImport, i) => (
                  <>
                    <div
                      style={{
                        padding: '16px',
                      }}
                    >
                      <span
                        style={{
                          fontSize: '16px',
                          color: '#434343',
                          opacity: '1',
                        }}
                      >
                        {messageImport?.message
                          ? messageImport?.message
                          : messageImport}
                      </span>
                    </div>
                    {!(errorImportMessage.length - 1 == i) && <Divider />}
                  </>
                ))}
              </div>
            }
            handleClose={onErrorImportClose}
            isClosedIcon={true}
            isModalActionFermer={true}
          />
        )}
        {error && (
          <Error
            status={error.status}
            statusText={error.statusText}
            open={open}
            setOpen={onErrorClose}
          />
        )}
        {(errorImportCourses || errorImportTournee) && (
          <Error
            statusText={errorImportCourses || errorImportTournee}
            open={openErrorImport}
            setOpen={onErrorImportClose}
          />
        )}

        <NavFilter
          dateFilterToday={true}
          statusFilter={true}
          driversFilter={true}
          salesChannelfilter={true}
          prestationFilter={true}
          agentsCommands={true}
          displaySelection={true}
          filterReducer={filterReducer}
          setFilter={setFilter}
          courseTypeFilter={true}
          savFilter={true}
          dateFilterCreation={true}
          BusinessOwnerFilter={true}
          resetFilter={resetFilterCommande}
          wareHouseFilter={true}
          pathnameFilter={history.location.pathname}
          defaultFilter={defaultFilterCommande}
          setDefaultFilter={setDefaultFilterCommande}
          saveDefaultFilter={saveDefaultFilterCommander}
          resetDefaultFilter={resetDefaultFilterCommander}
          pageFilter="COMMANDE"
          courseLieeFilter={true}
          tourListFilter={true}
          rdiFilter={true}
        />
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {shipments.length > 0 ? (
              <div
                style={{
                  width: '100%',
                  marginTop: '15px',
                }}
              >
                {displayGrid ? (
                  <ShipmentGrid shipments={shipments} />
                ) : (
                  <OrdersTable
                    shipments={shipments}
                    isProgrammerPage={false}
                    createSortHandler={createSortHandler}
                    openSnackbar={openSnackbar}
                    handleSnackbar={setOpenSnackbar}
                    orderBy={orderByColumn}
                    sortDirection={sortDirection}
                    setAllCheckCourse={setAllCheckCourse}
                    setCheckCourse={setCheckCourse}
                    coursesChecked={coursesChecked}
                    checkCoursesAll={checkCoursesAll}
                  />
                )}
                <CustomPagination
                  selectedPage={page}
                  onPageChange={handlePageChange}
                  pageTotal={pageCount}
                  itemsTotal={shipmentTotal}
                  handlePageLimitChange={handlePageLimitChange}
                  limitIndex={limitIndex}
                  onLimitChange={onLimitChange}
                  limit={limitLabel}
                />
                <ActionCourse />
              </div>
            ) : (
              !isLoading && (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    marginTop: '40px',
                  }}
                >
                  <NoDataFound />
                </div>
              )
            )}
            {errorFilter && (
              <Error
                statusText={errorFilter}
                open={openErrorFilter}
                setOpen={onErrorFilterClose}
              />
            )}
          </>
        )}
      </div>
      <Snackbar
        open={errorActionCourse != null}
        onClose={handleCloseErrorAction}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity="error" onClose={handleCloseErrorAction} elevation={6}>
          {errorActionCourse}
        </Alert>
      </Snackbar>
    </>
  )
}
Orders.propTypes = {
  shipments: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  getShipmentByFilter: PropTypes.func.isRequired,
  error: PropTypes.shape({
    status: PropTypes.number,
    statusText: PropTypes.string,
  }),
  count: PropTypes.number,
  limit: PropTypes.number,
  offset: PropTypes.number,
  pageCount: PropTypes.number,
  pageChanged: PropTypes.func,
  pageLimitChanged: PropTypes.func,
  myCommandsIsOn: PropTypes.bool,
  displayGrid: PropTypes.bool,
  removeError: PropTypes.func,
  isActionDone: PropTypes.bool,
  filterReducer: PropTypes.object,
  setFilter: PropTypes.func,
  removeErrorFilter: PropTypes.func,
  errorFilter: PropTypes.string,
  getShipmentByAgents: PropTypes.func,
  resetFilterCommande: PropTypes.func,
  searchCornerOnglet: PropTypes.object,
  searchCorner: PropTypes.object,
  fetchSearchCornerSave: PropTypes.func,
  resetShipments: PropTypes.func,
  errorActionCourse: PropTypes.string,
  removeErrorAction: PropTypes.func,
  resetOrderAction: PropTypes.func,
  defaultFilterCommande: PropTypes.object,
  setDefaultFilterCommande: PropTypes.func,
  saveDefaultFilterCommander: PropTypes.func,
  resetDefaultFilterCommander: PropTypes.func,
  errorImportCourses: PropTypes.string,
  removeErrorImportCourses: PropTypes.func,
  errorImportCoursesMessage: PropTypes.array,
  sort: PropTypes.string,
  orderBy: PropTypes.string,
  sortShipment: PropTypes.func,
  setAllCheckCourse: PropTypes.func,
  setCheckCourse: PropTypes.func,
  coursesChecked: PropTypes.array,
  checkCoursesAll: PropTypes.bool,
  fetchSearchOngletCornerSave: PropTypes.func,
  errorImportTournee: PropTypes.string,
  removeErrorImportTournees: PropTypes.func,
  errorImportTourneeMessage: PropTypes.array,
}
