import React, { useState } from 'react'
import PropTypes from 'prop-types'

import jwt from 'jsonwebtoken'

import Box from '@mui/material/Box'
import WarningIcon from '@mui/icons-material/Warning'

const BoxWarning = ({ message }) => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '20px',
        fontWeight: 'bold',
        color: '#fbb561',
      }}
    >
      <WarningIcon sx={{ marginRight: '13px', fontSize: '25px' }} />
      {message}
    </Box>
  )
}
BoxWarning.propTypes = {
  message: PropTypes.string,
}
export default function DashParCanal({ canalVente }) {
  const [error, setError] = useState(false)

  const getDashboarURL = () => {
    const payloadParse = canalVente.configMetabase
      .replace(/(\w+):/g, '"$1":') // Ajouter des guillemets autour des clés
      .replace(/\/\/.*$/gm, '') // Supprimer les commentaires
      .replace(
        /Math\.round\(Date\.now\(\) \/ 1000\) \+ \(10 \* 60\)/,
        () => Math.round(Date.now() / 1000) + 10 * 60
      ) // Évaluer l'expression
      .trim()
      .replace(/,\s*$/, '') // Supprimer une virgule superflue à la fin
      .concat('}') // Ajouter la fermeture manquante
    // Transformez en objet
    try {
      const payload = JSON.parse(payloadParse)
      const urlDash = Object.keys(payload.resource)[0] + '/'

      delete payload.exp

      var token = jwt.sign(payload, process.env.REACT_APP_METABASE_SECRET_KEY)

      var iframeUrl =
        process.env.REACT_APP_METABASE_SITE_URL +
        '/embed/' +
        urlDash +
        token +
        '#bordered=true&titled=true'
      return iframeUrl
    } catch (error) {
      setError(true)
    }
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: 'calc(100vh - 129px)',
        overflow: 'hidden',
      }}
    >
      {canalVente == null ? (
        <BoxWarning message={'Sélectionner canal de vente'} />
      ) : canalVente.configMetabase == null ? (
        <BoxWarning message={'Pas tableau de bord pour cette canal'} />
      ) : error ? (
        <BoxWarning message={'Configuration erronée'} />
      ) : (
        <Box
          component="iframe"
          src={getDashboarURL()}
          frameBorder="0"
          sx={{
            width: '100%',
            height: '100%',
            backgroundColor: 'transparent',
          }}
        />
      )}
    </Box>
  )
}
DashParCanal.propTypes = {
  canalVente: PropTypes.object,
}
