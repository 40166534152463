import React from 'react'
import PropTypes from 'prop-types'

import jwt from 'jsonwebtoken'

import { Box } from '@mui/material'

const payloadDash = (collaborateur) => {
  return {
    0: {
      payload: {
        resource: {
          dashboard: 599,
        },
        params: {
          id: collaborateur.id,
        },
      },
    },
    1: {
      payload: {
        resource: { dashboard: 867 },
        params: {},
      },
    },
    2: {
      payload: {
        resource: { dashboard: 868 },
        params: {},
      },
    },
  }
}

export default function DashStatic({ value, collaborateur }) {
  const getDashboarURL = () => {
    var token = jwt.sign(
      payloadDash(collaborateur)[value].payload,
      process.env.REACT_APP_METABASE_SECRET_KEY
    )

    var iframeUrl =
      process.env.REACT_APP_METABASE_SITE_URL +
      '/embed/dashboard/' +
      token +
      '#bordered=true&titled=true'
    return iframeUrl
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: 'calc(100vh - 129px)',
        overflow: 'hidden',
      }}
    >
      <Box
        component="iframe"
        src={getDashboarURL()}
        frameBorder="0"
        sx={{
          width: '100%',
          height: '100%',
          backgroundColor: 'transparent',
        }}
      />
    </Box>
  )
}
DashStatic.propTypes = {
  value: PropTypes.string,
  collaborateur: PropTypes.object,
}
