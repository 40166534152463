export const GET_ETIQUETTE_LIST = 'GET_ETIQUETTE_LIST'
export const GET_ETIQUETTE_LIST_SUCCESS = 'GET_ETIQUETTE_LIST_SUCCESS'
export const GET_ETIQUETTE_LIST_FAILURE = 'GET_ETIQUETTE_LIST_FAILURE'

export const GET_ETIQUETTE_LIST_MORE = 'GET_ETIQUETTE_LIST_MORE'
export const GET_ETIQUETTE_LIST_MORE_SUCCESS = 'GET_ETIQUETTE_LIST_MORE_SUCCESS'
export const GET_ETIQUETTE_LIST_MORE_FAILURE = 'GET_ETIQUETTE_LIST_MORE_FAILURE'

export const REMOVE_ETIQUETTE_LIST = 'REMOVE_ETIQUETTE_LIST'
export const GENERATION_ETIQUETTE = 'GENERATION_ETIQUETTE'
export const GENERATION_PREPARATION = 'GENERATION_PREPARATION'
export const GENERATION_TRANSFERT = 'GENERATION_TRANSFERT'
export const GENERATION_BONLIVRAISON = 'GENERATION_BONLIVRAISON'
export const GENERATION_BONLIVRAISON_COURSE = 'GENERATION_BONLIVRAISON_COURSE'
export const GET_LIVRAISON_LIST = 'GET_LIVRAISON_LIST'
export const GET_LIVRAISON_LIST_SUCCESS = 'GET_LIVRAISON_LIST_SUCCESS'
export const GET_LIVRAISON_LIST_FAILURE = 'GET_LIVRAISON_LIST_FAILURE'
export const GET_FREIGHTS_LIST = 'GET_FREIGHTS_LIST'
export const GET_FREIGHTS_LIST_SUCCESS = 'GET_FREIGHTS_LIST_SUCCESS'
export const GET_FREIGHTS_LIST_FAILURE = 'GET_FREIGHTS_LIST_FAILURE'

export const CLOTURE_RECEPTION_FREIGHTS = 'CLOTURE_RECEPTION_FREIGHTS'
export const CLOTURE_RECEPTION_FREIGHTS_SUCCESS =
  'CLOTURE_RECEPTION_FREIGHTS_SUCCESS'
export const CLOTURE_RECEPTION_FREIGHTS_FAILURE =
  'CLOTURE_RECEPTION_FREIGHTS_FAILURE'

export const GET_HISTORIQUE_FREIGHTS = 'GET_HISTORIQUE_FREIGHTS'
export const GET_HISTORIQUE_FREIGHTS_SUCCESS = 'GET_HISTORIQUE_FREIGHTS_SUCCESS'
export const GET_HISTORIQUE_FREIGHTS_FAILURE = 'GET_HISTORIQUE_FREIGHTS_FAILURE'

export const GENERATION_QRCODE = 'GENERATION_QRCODE'
export const GENERATION_TOURNEE = 'GENERATION_TOURNEE'

export const DOWNLOAD_KILOMETRAGE_TOURS = 'DOWNLOAD_KILOMETRAGE_TOURS'

export const GET_CANAL_VENTE_DASH = 'GET_CANAL_VENTE_DASH'
export const GET_CANAL_VENTE_DASH_SUCCESS = 'GET_CANAL_VENTE_DASH_SUCCESS'
export const GET_CANAL_VENTE_DASH_MORE = 'GET_CANAL_VENTE_DASH_MORE'
export const GET_CANAL_VENTE_DASH_MORE_SUCCESS = 'GET_CANAL_VENTE_DASH_MORE_SUCCESS'
export const GET_CANAL_VENTE_DASH_FAILURE = 'GET_CANAL_VENTE_DASH_FAILURE'
