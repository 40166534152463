import { parseUpdatedTours } from '../../utils/tours'
import { tourType } from '../../utils/values'
import { httpPatch, httpPost, httpPostCallbackData } from '../http/actions'
import {
  SIMULATE_TOURS,
  SIMULATE_TOURS_FAILURE,
  SIMULATE_TOURS_SUCCESS,
  CREATE_TOURS,
  CREATE_TOURS_FAILURE,
  CREATE_TOURS_SUCCESS,
  RECALCULATE_TOURS_SUCCESS,
  RECALCULATE_TOURS_FAILURE,
  RECALCULATE_TOURS,
  GET_DRIVERS_PLANNINGS,
  GET_DRIVERS_PLANNINGS_SUCCESS,
  GET_DRIVERS_PLANNINGS_FAILURE,
  AFFECT_TOUR,
  AFFECT_TOUR_SUCCESS,
  AFFECT_TOUR_FAILURE,
  UPDATE_COURSE_POLARIS,
  UPDATE_COURSE_POLARIS_SUCCESS,
  UPDATE_COURSE_POLARIS_FAILURE,
  GET_TOURS_FROM_POLARIS,
  GET_TOURS_FROM_POLARIS_SUCCESS,
  GET_TOURS_FROM_POLARIS_FAILURE,
  DELETE_TOUR_FROM_POLARIS,
  DELETE_TOUR_FROM_POLARIS_SUCCESS,
  DELETE_TOUR_FROM_POLARIS_FAILURE,
  OPTIMISATION_COURSES,
  OPTIMISATION_COURSES_SUCCESS,
  OPTIMISATION_COURSES_FAILURE,
  GET_WAREHOUSES,
  GET_WAREHOUSES_SUCCESS,
  GET_WAREHOUSES_FAILURE,
  GET_WAREHOUSES_MORE,
  GET_WAREHOUSES_MORE_SUCCESS,
  GET_WAREHOUSES_MORE_FAILURE,
  UPDATE_COURSE_OPTIMISATION_FILE,
  UPDATE_COURSE_OPTIMISATION_FILE_SUCCESS,
  UPDATE_COURSE_OPTIMISATION_FILE_FAILURE,
  EXPORT_TOURS,
  EXPORT_TOURS_SUCCESS,
  EXPORT_TOURS_FAILURE,
} from './actionsConsts'
import { axiosHTTP } from '../../utils'
import { removeNull } from '../../utils/utils'

const tourApi = process.env.REACT_APP_TOURS_MS_URL
const polarisApi = process.env.REACT_APP_POLARIS_MS_URL
const api = process.env.REACT_APP_API_URL

let CancelToken = axiosHTTP.CancelToken
let cancelWareHouse
let cancelWareHouseMore
export default (store) => (next) => (action) => {
  if (action.type === GET_DRIVERS_PLANNINGS) {
    store.dispatch(
      httpPostCallbackData({
        endPoint: `${polarisApi}tour/getToursByDriver`,
        data: action.payload.data,
        success: GET_DRIVERS_PLANNINGS_SUCCESS,
        failure: GET_DRIVERS_PLANNINGS_FAILURE,
        secData: action.payload.fleet,
      })
    )
  }

  if (action.type === AFFECT_TOUR) {
    store.dispatch(
      httpPost({
        endPoint: `${polarisApi}tour/affectTour`,
        data: action.payload.data,
        success: AFFECT_TOUR_SUCCESS,
        failure: AFFECT_TOUR_FAILURE,
      })
    )
  }

  if (action.type === RECALCULATE_TOURS) {
    const { data, isOnePickUp, deliveryDate, isFile } = action.payload
    const type = isOnePickUp === true ? tourType.ONE_PICK_UP : tourType.MULTI_PICK_UP
    const newData = parseUpdatedTours(data, type)
    // update input recalculate to adapt validator payloads
    store.dispatch(
      httpPost({
        endPoint: `${polarisApi}tour/recalculateTours/${type}`,
        data: Object.assign({
          tours: newData,
          deliveryDate: deliveryDate,
          isFile: isFile,
        }),
        success: RECALCULATE_TOURS_SUCCESS,
        failure: RECALCULATE_TOURS_FAILURE,
      })
    )
  }

  if (action.type === SIMULATE_TOURS) {
    const type =
      action.payload.isOnePickUp === true
        ? tourType.ONE_PICK_UP
        : tourType.MULTI_PICK_UP
    store.dispatch(
      httpPost({
        endPoint: `${tourApi}tour/optimize/${type}`,
        data: action.payload.data,
        success: SIMULATE_TOURS_SUCCESS,
        failure: SIMULATE_TOURS_FAILURE,
      })
    )
  }

  if (action.type === CREATE_TOURS) {
    store.dispatch(
      httpPost({
        endPoint: `${polarisApi}tour/save/CORNER`,
        data: action.payload.data,
        success: CREATE_TOURS_SUCCESS,
        failure: CREATE_TOURS_FAILURE,
      })
    )
  }

  if (action.type === UPDATE_COURSE_POLARIS) {
    store.dispatch(
      httpPatch({
        endPoint: `${api}courses/update-bulk`,
        data: action.payload.course,
        success: UPDATE_COURSE_POLARIS_SUCCESS,
        failure: UPDATE_COURSE_POLARIS_FAILURE,
      })
    )
  }

  if (action.type === GET_TOURS_FROM_POLARIS) {
    const {
      filters: { filter },
    } = store.getState()
    const data = {
      startDeliveryDate: filter.dropOffStart
        ? filter.dropOffStart
        : filter.startTime,
      endDeliveryDate: filter.dropOffEnd,
      // ldt: filter.tourList,
      // salesChanel: filter.codeCanal,
      // driverId: filter.codeChauffeur,
      max: filter.limit,
      offset: filter.offset,
    }
    store.dispatch(
      httpPost({
        endPoint: `${polarisApi}tour/getToursDetails`,
        data: data,
        success: GET_TOURS_FROM_POLARIS_SUCCESS,
        failure: GET_TOURS_FROM_POLARIS_FAILURE,
      })
    )
    // store.dispatch(resetSimulateAction())
  }
  if (action.type === DELETE_TOUR_FROM_POLARIS) {
    const payload = action.payload.data
    store.dispatch(
      httpPost({
        endPoint: `${polarisApi}tour/deleteTour`,
        data: payload,
        success: DELETE_TOUR_FROM_POLARIS_SUCCESS,
        failure: DELETE_TOUR_FROM_POLARIS_FAILURE,
      })
    )
  }

  //Optimisation course Euromatic
  if (action.type === OPTIMISATION_COURSES) {
    const payload = action.payload
    store.dispatch(
      httpPost({
        endPoint: `${polarisApi}course/upload`,
        data: payload,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        success: OPTIMISATION_COURSES_SUCCESS,
        failure: OPTIMISATION_COURSES_FAILURE,
      })
    )
  }

  // Get Wahrehouse ALL
  if (action.type === GET_WAREHOUSES) {
    // cancel the request
    if (cancelWareHouse != undefined) {
      cancelWareHouse()
    }
    const {
      simulateTournee: { filter },
    } = store.getState()
    store.dispatch(
      httpPost({
        endPoint: `${polarisApi}warehouse/getWarehousesByfilter`,
        data: removeNull(filter),
        cancelToken: new CancelToken(function executor(b) {
          // An executor function receives a cancel function as a parameter
          cancelWareHouse = b
        }),
        success: GET_WAREHOUSES_SUCCESS,
        failure: GET_WAREHOUSES_FAILURE,
      })
    )
  }

  if (action.type === GET_WAREHOUSES_MORE) {
    // cancel the request
    if (cancelWareHouseMore != undefined) {
      cancelWareHouseMore()
    }
    const {
      simulateTournee: { filter },
    } = store.getState()
    store.dispatch(
      httpPost({
        endPoint: `${polarisApi}warehouse/getWarehousesByfilter`,
        data: removeNull(filter),
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancelWareHouseMore = c
        }),
        success: GET_WAREHOUSES_MORE_SUCCESS,
        failure: GET_WAREHOUSES_MORE_FAILURE,
      })
    )
  }

  if (action.type === UPDATE_COURSE_OPTIMISATION_FILE) {
    store.dispatch(
      httpPost({
        endPoint: `${polarisApi}course/updateOrder`,
        data: action.payload.course,
        success: UPDATE_COURSE_OPTIMISATION_FILE_SUCCESS,
        failure: UPDATE_COURSE_OPTIMISATION_FILE_FAILURE,
      })
    )
  }

  if (action.type === EXPORT_TOURS) {
    const {
      simulateTournee: { fileNameCoursesOptimisation },
    } = store.getState()
    store.dispatch(
      httpPost({
        endPoint: `${polarisApi}course/exportFiles`,
        data: { ...action.payload.data, fileName: fileNameCoursesOptimisation },
        success: EXPORT_TOURS_SUCCESS,
        failure: EXPORT_TOURS_FAILURE,
      })
    )
  }

  next(action)
}
