export const getDocumentsState = ({ documentsEntrepot }) => documentsEntrepot

export const getEtiquettes = (state) => getDocumentsState(state).etiquetteList
export const getCountetiquettes = (state) => getDocumentsState(state).etiquettesCount
export const getIsLoadingEtiquette = (state) =>
  getDocumentsState(state).isLoadingEtiquette

export const getLivraisonList = (state) => getDocumentsState(state).livraisonList
export const getIsLoadingLivraison = (state) =>
  getDocumentsState(state).isLoadingLivraison
export const getErrorLivraison = (state) => getDocumentsState(state).errorLivraison
export const getCountLivraison = (state) => getDocumentsState(state).countLivraison

export const getFreightsList = (state) => getDocumentsState(state).freightsList
export const getIsLoadingFreights = (state) =>
  getDocumentsState(state).isLoadingFreights
export const getErrorFreights = (state) => getDocumentsState(state).errorFreights
export const getCountFreights = (state) => getDocumentsState(state).countFreights
export const getIsActionsFreights = (state) =>
  getDocumentsState(state).isActionsFreights
export const getIsLoadingActionsFreights = (state) =>
  getDocumentsState(state).isLoadingActionsFreights

export const getHistoriquesFreights = (state) =>
  getDocumentsState(state).historiquesFreights
export const getIsLoadingHistoriquesFreights = (state) =>
  getDocumentsState(state).isLoadingHistoriquesFreights

export const getCanalVentes = (state) => getDocumentsState(state).canalVentes
export const getIsLoadingCanalVentes = (state) =>
  getDocumentsState(state).isLoadingCanalVentes
export const getCanalVentesCount = (state) =>
  getDocumentsState(state).canalVentesCount
