import React from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'

const StyledHelperText = styled('p')({
  color: '#d32f2f',
  fontWeight: '400',
  fontSize: '0.75rem',
  lineHeight: '1.66',
  letterSpacing: '0.03333em',
  textAlign: 'left',
  marginTop: '3px',
  marginRight: '14px',
  marginBottom: '0',
  marginLeft: '14px',
})
const StyledAutoComplete = styled(Autocomplete)(({ classes }) => classes)

export default function AutoComplete({
  placeholder,
  onChange,
  options,
  disabled,
  onInputChange,
  forcePopupIcon,
  loading,
  value,
  startIcon,
  id,
  groupBy,
  isOptionEqualToValue,
  getOptionLabel,
  error,
  helperText,
  renderOption,
  label,
  required,
  PaperComponent,
  ChipProps,
  multiple,
  variant,
  sxInputStyle,
  ListboxProps,
  classes,
  name,
  fullWidth,
  textValue,
  freeSolo, // to persist the search value when we loses focus of the input
  inputValue,
  onBlur,
  onFocus,
  onClose,
  open,
  onClick,
}) {
  return (
    <StyledAutoComplete
      open={open}
      name={name}
      freeSolo={freeSolo}
      id={id}
      isOptionEqualToValue={isOptionEqualToValue}
      onChange={onChange}
      onClose={onClose}
      options={options}
      onInputChange={onInputChange}
      forcePopupIcon={forcePopupIcon}
      loading={loading}
      getOptionLabel={getOptionLabel}
      groupBy={groupBy}
      renderOption={renderOption}
      value={!multiple ? value || null : value}
      ChipProps={ChipProps}
      multiple={multiple}
      PaperComponent={PaperComponent}
      ListboxProps={ListboxProps}
      fullWidth={fullWidth}
      inputValue={inputValue}
      renderInput={(params) => (
        <>
          <TextField
            required={required}
            variant={variant}
            value={textValue}
            {...params}
            error={error}
            label={label}
            placeholder={placeholder}
            onBlur={onBlur}
            onFocus={onFocus}
            onClick={onClick}
            InputProps={{
              sx: sxInputStyle,
              ...params.InputProps,
              ...(startIcon
                ? {
                    startAdornment: (
                      <>
                        {startIcon}
                        {params.InputProps.startAdornment}
                      </>
                    ),
                  }
                : null),
            }}
          />
          {helperText && <StyledHelperText>{helperText}</StyledHelperText>}
        </>
      )}
      classes={classes}
      disabled={disabled}
    />
  )
}
AutoComplete.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  loading: PropTypes.bool,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.array,
  startIcon: PropTypes.object,
  id: PropTypes.string,
  onInputChange: PropTypes.func,
  forcePopupIcon: PropTypes.bool,
  renderOption: PropTypes.func,
  groupBy: PropTypes.func,
  PaperComponent: PropTypes.func,
  isOptionEqualToValue: PropTypes.func,
  error: PropTypes.string,
  helperText: PropTypes.string,
  getOptionLabel: PropTypes.func,
  label: PropTypes.string,
  variant: PropTypes.string,
  ChipProps: PropTypes.object,
  multiple: PropTypes.bool,
  required: PropTypes.bool,
  sxInputStyle: PropTypes.object,
  ListboxProps: PropTypes.object,
  classes: PropTypes.any,
  name: PropTypes.string,
  participant: PropTypes.bool,
  fullWidth: PropTypes.bool,
  textValue: PropTypes.string,
  handleTextFiledChange: PropTypes.func,
  freeSolo: PropTypes.bool,
  inputValue: PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  onClick: PropTypes.bool,
}
