export default () => {
  return {
    driversOptimiser: [],
    isLoadingDriversOptimiser: false,

    drivers: [],

    driversOptimiserCount: 0,
  }
}
