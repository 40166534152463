import { httpPost } from '../http/actions'
import {
  OPTIMISER_DRIVER_GET,
  OPTIMISER_DRIVER_GET_SUCCESS,
  OPTIMISER_DRIVER_GET_FAILURE,
  GET_DRIVER_WARRNING,
  GET_DRIVER_WARRNING_SUCCESS,
  GET_DRIVER_WARRNING_FAILURE,
  GET_DRIVER_WARRNING_MORE,
  GET_DRIVER_WARRNING_MORE_SUCCESS,
  GET_DRIVER_WARRNING_MORE_FAILURE,
} from './actionsConsts'

import { axiosHTTP } from '../../utils'

const api = process.env.REACT_APP_API_URL

let CancelToken = axiosHTTP.CancelToken
let cancel

export default (store) => (next) => (action) => {
  //Get Drivers for plannification course && Warrning
  if (action.type === OPTIMISER_DRIVER_GET) {
    // cancel the request
    if (cancel != undefined) {
      cancel()
    }
    const filter = action.payload
    store.dispatch(
      httpPost({
        endPoint: `${api}getDriversByDate`,
        data: filter,
        cancelToken: new CancelToken(function executor(b) {
          // An executor function receives a cancel function as a parameter
          cancel = b
        }),
        success: OPTIMISER_DRIVER_GET_SUCCESS,
        failure: OPTIMISER_DRIVER_GET_FAILURE,
      })
    )
  }

  //Get Drivers Warrning-Popup
  if (action.type === GET_DRIVER_WARRNING) {
    // cancel the request
    if (cancel != undefined) {
      cancel()
    }
    const filter = action.payload
    store.dispatch(
      httpPost({
        endPoint: `${api}getDriversInfo`,
        data: filter,
        cancelToken: new CancelToken(function executor(b) {
          // An executor function receives a cancel function as a parameter
          cancel = b
        }),
        success: GET_DRIVER_WARRNING_SUCCESS,
        failure: GET_DRIVER_WARRNING_FAILURE,
      })
    )
  }

  //Get Drivers More Warrning-Popup (scroll)
  if (action.type === GET_DRIVER_WARRNING_MORE) {
    // cancel the request
    if (cancel != undefined) {
      cancel()
    }
    const filter = action.payload
    store.dispatch(
      httpPost({
        endPoint: `${api}getDriversInfo`,
        data: filter,
        cancelToken: new CancelToken(function executor(b) {
          // An executor function receives a cancel function as a parameter
          cancel = b
        }),
        success: GET_DRIVER_WARRNING_MORE_SUCCESS,
        failure: GET_DRIVER_WARRNING_MORE_FAILURE,
      })
    )
  }

  next(action)
}
