import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import AddressAutoComplete from '../../../../../../containers/main/AddressAutoComplete'
import { Place } from '../../../../../../models/place'
import { LatLng } from '../../../../../../models/latlng'
import { StyledCell } from './style'

const ColumnAddressComponentEditable = ({
  content,
  id,
  onChange,
  nameAttribut,
  adressAut,
  getGoogleMapDirection,
  errorsCourseEditable,
  isOptimisationCoursesFiles,
  disabledColumn,
  directionRoute,
  valuesmodif,
}) => {
  const address =
    valuesmodif?.address != '-'
      ? new Place().getFromOrderAddress(valuesmodif)
      : new Place().getFromOrderAddress(content)
  const [valueContent, setValueContent] = useState(address)

  useEffect(() => {
    if (directionRoute) {
      if (
        directionRoute.destination.lat == valueContent.latlng.lat ||
        directionRoute.destination.lng == valueContent.latlng.lng
      )
        onChange({
          [`estimatedKM`]: directionRoute.selectedDirection.distance,
        })
    }
  }, [directionRoute])

  const onChangeAddress = (value) => {
    if (
      address?.latlng?.lat === value?.latlng?.lat &&
      address?.latlng?.lng === value?.latlng?.lng &&
      address?.formattedAddress === value?.formattedAddress
    ) {
      return
    }

    setValueContent(value)
    onChange({
      [`${nameAttribut}`]: isOptimisationCoursesFiles
        ? getDestinationAdressOptimisationFile(value)
        : getDestinationAdress(value),
    })

    !isOptimisationCoursesFiles && calculateDirection(value)
  }

  const getDestinationAdress = (addressValues) => {
    return {
      address: addressValues?.formattedAddress,
      latitude: addressValues?.latlng?.lat,
      longitude: addressValues?.latlng?.lng,
      postalCode: addressValues?.postalCode,
      countryCode: addressValues?.country?.code,
      codePays: addressValues?.country?.code,
      city: addressValues?.city,
    }
  }
  const getDestinationAdressOptimisationFile = (addressValues) => {
    return {
      address: addressValues?.formattedAddress,
      latitude: addressValues?.latlng?.lat,
      longitude: addressValues?.latlng?.lng,
      postalCode: addressValues?.postalCode,
      city: addressValues?.city,
      locationType: 'ROOFTOP',
    }
  }

  const calculateDirection = (addressValues) => {
    if (addressValues?.latlng?.lat && addressValues?.latlng?.lng) {
      getGoogleMapDirection(
        '',
        new LatLng(adressAut.latitude, adressAut.longitude),
        new LatLng(addressValues.latlng.lat, addressValues.latlng.lng)
      )
    }
  }
  return (
    <StyledCell id={id} sx={{ color: disabledColumn && '#D50000' }}>
      <AddressAutoComplete
        id="selectedDestination-shipment"
        selectedDefaultAddress={valueContent}
        handleQueryChange={(value) => console.log('destinationQuery', value)}
        handleOnAddressSelect={(value) => {
          value && onChangeAddress(value)
        }}
        hasError={errorsCourseEditable.includes(nameAttribut)}
      />
    </StyledCell>
  )
}
ColumnAddressComponentEditable.propTypes = {
  content: PropTypes.object,
  key: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  nameAttribut: PropTypes.string,
  adressAut: PropTypes.object,
  getGoogleMapDirection: PropTypes.func,
  errorsCourseEditable: PropTypes.array,
  isOptimisationCoursesFiles: PropTypes.bool,
  disabledColumn: PropTypes.bool,
  directionRoute: PropTypes.object,
  valuesmodif: PropTypes.object,
}

export default ColumnAddressComponentEditable
