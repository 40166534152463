import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import Dialog from '../../../ModalDialog'
import IconButtonImporter from '../../../../assets/icons/navBar/UiwDownload'

import Button from '@mui/material/Button'
import IconImportationFile from '../../../../assets/icons/navBar/MingcuteFileImportLine'
import { validateUploadFileXLS } from '../../../../utils/validator/UploadFile'

export default function CoursesImportation({
  open,
  handleClose,

  importerTournee,

  errorImportTournee,
  isActionImportTournee,
  resetActionImportTournees,
  isLoadingActionImporterFile,
}) {
  const [fileCourse, setFileCourse] = useState(null)
  const [errorUploadFile, setErrorUploadFile] = useState(null)

  useEffect(() => {
    if (isActionImportTournee) {
      handleClose()
      resetActionImportTournees()
    }
  }, [isActionImportTournee])

  const onUpload = (event) => {
    const error = validateUploadFileXLS(Array.from(event.target.files))
    if (error.isError) {
      setErrorUploadFile(error)
      setFileCourse(null)
    } else {
      setFileCourse(event.target.files[0])
      setErrorUploadFile(null)
    }
    event.target.value = ''
  }

  const onImportCourses = () => {
    const formData = new FormData()
    formData.append('file', fileCourse)
    importerTournee(formData)
  }

  return (
    <Dialog
      maxWidthDialog={'md'}
      open={open}
      title={'Importer les tournées'}
      iconTitle={
        <IconButtonImporter
          style={{ width: '20px', height: '20px', marginRight: '10px' }}
        />
      }
      style={errorImportTournee ? { display: 'none' } : {}}
      content={
        <div
          style={{
            overflow: 'auto',
            width: '600px',
            paddingRight: '16px',
            paddingBottom: '6px',
            '@media (max-width: 680px)': {
              width: 'unset',
            },
          }}
        >
          <div style={{ padding: '15px 11px' }}>
            <div style={{ padding: '10px 0px' }}>
              <div style={{ paddingBottom: '10px', fontWeight: 'bold' }}>
                Télécharger un fichier
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Button
                  id="download-file"
                  name="download-file"
                  variant="outlined"
                  endIcon={<IconImportationFile />}
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '16.5px 14px',
                    border: '1px solid rgb(196 196 196)',
                    color: 'rgba(0, 0, 0, 0.87)',
                    '&:hover': {
                      border: '1px solid rgb(0 0 0)',
                      backgroundColor: 'white',
                    },
                    '.MuiButton-endIcon': {
                      marginRight: '-7px',
                      color: 'rgba(0, 0, 0, 0.54)',
                      width: '1em',
                      height: '1em',
                      marginTop: '-11px',
                    },
                  }}
                >
                  <span style={{ textTransform: 'none' }}>
                    {fileCourse ? fileCourse.name : 'Sélectionner un fichier'}
                  </span>
                </Button>
                <p
                  style={{
                    color: '#d32f2f',
                    fontSize: '0.75rem',
                    letterSpacing: '0.03333em',
                    textAlign: 'left',
                    margin: '3px 14px 0px',
                    height: '10px',
                  }}
                >
                  {errorUploadFile?.message}
                </p>

                <input
                  id="input-download-file"
                  type="file"
                  style={{
                    width: '91%',
                    cursor: 'pointer',
                    opacity: '0',
                    zIndex: '2',
                    position: 'absolute',
                    height: '55px',
                  }}
                  onChange={onUpload}
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                />
              </div>
            </div>
          </div>
        </div>
      }
      handleClose={handleClose}
      handleClickAction={onImportCourses}
      disabled={fileCourse == null}
      isLoadingButtonAction={isLoadingActionImporterFile}
    />
  )
}
CoursesImportation.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  importerTournee: PropTypes.func,
  errorImportTournee: PropTypes.string,
  isActionImportTournee: PropTypes.bool,
  resetActionImportTournees: PropTypes.func,
  isLoadingActionImporterFile: PropTypes.bool,
}
