import { GOOGLE_MAP_CONSTANTS } from '../constants/googleMapConstants'

export const calculateCenter = (
  markers,
  requestedZoom,
  additionalZoom,
  zoomOnly = false
) => {
  if (markers?.length > 0) {
    const points = {
      lats: markers.map((m) => (m.position ? m.position.lat : m.lat)),
      lngs: markers.map((m) => (m.position ? m.position.lng : m.lng)),
    }
    const centerLat =
      points.lats.reduce((sum, lat) => sum + lat, 0) / points.lats.length
    const centerLng =
      points.lngs.reduce((sum, lng) => sum + lng, 0) / points.lngs.length
    const maxDistance = Math.max(
      distance(
        Math.max(...points.lats),
        centerLng,
        Math.min(...points.lats),
        centerLng
      ),
      distance(
        centerLat,
        Math.max(...points.lngs),
        centerLat,
        Math.min(...points.lngs)
      )
    )
    const zoom = radiusToZoom(maxDistance)

    console.log({
      center: { lat: centerLat, lng: centerLng },
      zoom: zoom + additionalZoom,
    })
    return {
      center: { lat: centerLat, lng: centerLng },
      zoom: zoomOnly ? requestedZoom : zoom + additionalZoom,
    }
  } else {
    return {
      center: {
        lat: GOOGLE_MAP_CONSTANTS.PROGRAMMER_LATLNG.lat,
        lng: GOOGLE_MAP_CONSTANTS.PROGRAMMER_LATLNG.lng,
      },
      zoom: requestedZoom,
    }
  }
}
const radiusToZoom = (radius) => {
  return Math.round(16 - Math.log(radius / 2) / Math.LN2)
}
function distance(lat1, lon1, lat2, lon2) {
  var p = 0.017453292519943295 // Math.PI / 180
  var c = Math.cos
  var a =
    0.5 -
    c((lat2 - lat1) * p) / 2 +
    (c(lat1 * p) * c(lat2 * p) * (1 - c((lon2 - lon1) * p))) / 2

  return 12742 * Math.asin(Math.sqrt(a)) // 2 * R; R = 6371 km
}
