import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Dialog from '../../../../components/ModalDialog'
import { TextField, Avatar, Box } from '@mui/material'
import CreateIcon from '../../../../assets/icons/settingsCorner/iconsCreate'
import EditIcon from '../../../../assets/icons/settingsCorner/iconEdit'
import {
  ParticipantRoleType,
  getPhoneInputCountries,
  defaultImageParticipant,
} from '../../../../utils/values'
import {
  StyledDivContent,
  StyledDivFullWidth,
  StyledDivTopInputs,
  StyledInputModal,
  StyledInputTitle,
  sxInput,
} from '../../styled'
import { MenuItem } from '@mui/material'
import { useFormik } from 'formik'
import AutoComplete from '../../../../components/AutoComplete'
import PhoneInput from 'react-phone-input-2'
import './styleModal.scss'
import AddressAutoComplete from '../../../../containers/main/AddressAutoComplete'
import { validateUploadImage } from '../../../../utils/validator/UploadFileDiscussion'
import Error from '../../../../components/Modal/HttpErrorPopUp'
import fileDecoded from '../../../../utils/fileDecoded'
import { Place } from '../../../../models/place'

// which keys are symmetrical to our values/initialValues
const validate = ({ firstName, lastName, email, phone }) => {
  const errors = {}
  if (!firstName) {
    errors.firstName = 'Prénom est obligatoire'
  }

  if (!lastName) {
    errors.lastName = 'Nom est obligatoire'
  }

  if (!email) {
    errors.email = 'Email est obligatoire'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
    errors.email = 'Email est invalide'
  }
  if (!phone) {
    errors.phone = 'Téléphone est obligatoire'
  }

  return errors
}

function DriverModal({
  open,
  isEdit,
  handleClose,
  modalTitle,
  createParticipant,
  updateParticipant,
  isLoadingParticipantAction,
  success,
  errorMessage,
  row,
  keys,
  fetchKeys,
  searchKeys,
  isLoadingKeysList,
  fetchMoreKeys,
  keysCount,
  resetKey,
  resetParticipantConfig,
  errorMessageConfig,
  successConfig,
  keyCreatedObject,
}) {
  const [select, setSelect] = useState(false)
  const [init, setInit] = useState(false)
  const [searchKey, setSearchKey] = useState('')
  const [offset, setOffset] = useState(25)

  const [errorUploadFile, setErrorUploadFile] = useState(null)
  const [openDialogError, setOpenDialogError] = useState(false)

  const [openListOptions, setOpenListOptions] = useState(false)

  const getadresse = (adresse) => {
    return {
      address: adresse.formattedAddress,
      latitude: adresse.latlng.lat,
      longitude: adresse.latlng.lng,
      postalCode: adresse.postalCode,
      city: adresse.city,
    }
  }
  const parsePayload = (changedValues) => {
    if (changedValues?.company != '' || changedValues?.adresse != null) {
      changedValues.driver = {
        id: isEdit ? row.driverId : undefined,
        company: changedValues.company || undefined,
        adresse: changedValues?.adresse
          ? getadresse(changedValues.adresse)
          : undefined,
      }
    }
    delete changedValues.company
    delete changedValues.adresse
    let formData = new FormData()
    formData.append('participantDto', JSON.stringify(changedValues))
    changedValues.photo != null
      ? Array.from(changedValues.photo).forEach((file) => {
          formData.append('photo', file)
        })
      : formData.append('photo', {})
    return formData
  }
  const formik = useFormik({
    initialValues: {
      id: row.id,
      method: 'Corner',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      type: 'DRIVER',
      role: 'DRIVER',
      competences: [],
      company: '',
      photo: null,
    },
    validate,
    onSubmit: (values) => {
      if (isEdit) {
        const formData = parsePayload(values)
        updateParticipant(formData)
      } else {
        delete values.id
        const formData = parsePayload(values)
        createParticipant(formData)
      }
    },
  })
  const phoneInputStyles = {
    height: '3.12em',
    width: '100%',
    borderRadius: '4px',
    border:
      formik.touched.phone && formik.errors.phone
        ? '1px solid red'
        : '1px solid #E2F0FE',
  }
  useEffect(() => {
    if (isEdit) {
      const filesToDecode = fileDecoded([row.photo])
      formik.setValues({
        ...formik.values,
        method: 'Corner',
        firstName: row.firstName,
        lastName: row.lastName,
        email: row.email,
        phone: row.phone,
        type: row.type,
        role: row.role,
        competences: row.competences,
        id: row.id,
        adresse: row?.adresse ? new Place().getFromOrderAddress(row.adresse) : null,
        company: row.company || '',
        photo: row?.photo ? filesToDecode[0] : null,
      })
    }
  }, [isEdit])

  useEffect(() => {
    if (success) {
      handleClose()
      resetParticipantConfig()
    }
  }, [success])

  useEffect(() => {
    if (successConfig && keyCreatedObject) {
      formik.setFieldValue('competences', [
        ...formik.values.competences,
        keyCreatedObject,
      ])
      setSearchKey('')
    }
  }, [successConfig])

  const verifEdit = () => {
    return (
      isEdit &&
      formik.values.firstName == row.firstName &&
      formik.values.lastName == row.lastName &&
      formik.values.email == row.email &&
      formik.values.phone == row.phone &&
      formik.values.role == row.role &&
      formik.values.competences == row.competences &&
      formik.values.adresse == row.adresse &&
      formik.values.company == row.company &&
      (formik?.values?.photo == undefined ||
        formik?.values?.photo == null ||
        (formik?.values?.photo != undefined &&
          formik?.values?.photo != null &&
          Array.from(formik?.values?.photo)
            ?.map((el) => el.name)
            ?.every((el) => row?.photo?.fileName == el)))
    )
  }
  useEffect(() => {
    fetchKeys()
    setInit(true)
  }, [])

  useEffect(() => {
    window.addEventListener('beforeunload', resetKey)

    return () => {
      resetKey()
      window.removeEventListener('beforeunload', resetKey)
    }
  }, [])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchKey.length > 0 && !select) {
        searchKeys(searchKey)
        setOffset(25)
      } else if (searchKey === '') {
        if (init) {
          searchKeys('')
        }
      }
    }, 500)

    return () => clearTimeout(delayDebounceFn)
  }, [searchKey])

  const onSelectKey = (value) => {
    if (value) {
      formik.setFieldValue('competences', value)
      setSelect(true)
    }
  }

  const onChangeSearchKey = (value) => {
    setSelect(false)
    if (value) {
      let val = value.trim().toUpperCase()
      setSearchKey(val)
    } else {
      setSearchKey('')
      setSelect(false)
    }
  }

  const loadMoreKeys = (event) => {
    if (
      Math.round(event.target.scrollHeight - event.target.scrollTop) ==
        event.target.clientHeight &&
      keysCount >= offset
    ) {
      setOffset(offset + 25)
      fetchMoreKeys(offset, searchKey)
    }
  }

  const onChangeImage = (event) => {
    const error = validateUploadImage(Array.from(event.target.files))
    if (error.isError) {
      setErrorUploadFile(error)
      setOpenDialogError(true)
      event.target.value = null
    } else {
      formik.setFieldValue('photo', event.target.files)
    }
  }
  const onErrorClose = () => {
    setOpenDialogError(false)
    setErrorUploadFile(null)
  }
  const getURLImage = () => {
    return formik?.values?.photo != null && isEdit && formik.values?.photo?.url
      ? formik.values?.photo?.url
      : formik.values?.photo != null
      ? URL.createObjectURL(
          formik?.values?.photo.length > 0
            ? formik?.values?.photo[0]
            : formik?.values?.photo
        )
      : defaultImageParticipant
  }

  return (
    <>
      {errorUploadFile?.isError && (
        <Error
          statusText={
            errorUploadFile?.message ||
            "une erreur s'est produite merci d'actualiser la page"
          }
          open={openDialogError}
          setOpen={onErrorClose}
        />
      )}
      <form noValidate onSubmit={formik.handleSubmit}>
        <Dialog
          maxWidthDialog={'md'}
          open={open}
          title={modalTitle}
          iconTitle={isEdit ? <EditIcon /> : <CreateIcon />}
          style={{ display: (errorMessage || errorMessageConfig) && 'none' }}
          content={
            <StyledDivContent>
              <Box
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                  paddingBottom: '20px',
                }}
              >
                <input
                  type="file"
                  style={{
                    width: '120px',
                    cursor: 'pointer',
                    zIndex: '2',
                    position: 'absolute',
                    height: '120px',
                    opacity: '0',
                  }}
                  accept="image/*"
                  onChange={onChangeImage}
                />

                <Avatar
                  src={getURLImage()}
                  alt="profile"
                  sx={{
                    width: '120px',
                    height: '120px',
                  }}
                />
              </Box>

              <StyledDivTopInputs>
                <StyledInputModal>
                  <StyledInputTitle>Nom *</StyledInputTitle>
                  <TextField
                    placeholder="Nom"
                    id="Driver-Nom"
                    name="lastName"
                    variant="outlined"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    InputProps={{
                      sx: sxInput,
                    }}
                    value={formik.values.lastName}
                    error={formik.errors.lastName && formik.touched.lastName}
                    helperText={
                      formik.errors.lastName && formik.touched.lastName
                        ? formik.errors.lastName
                        : null
                    }
                  />
                </StyledInputModal>
                <StyledInputModal>
                  <StyledInputTitle>Prénom *</StyledInputTitle>
                  <TextField
                    id="Driver-Prenom"
                    name="firstName"
                    variant="outlined"
                    placeholder="Prénom"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    InputProps={{
                      sx: sxInput,
                    }}
                    value={formik.values.firstName}
                    error={formik.errors.firstName && formik.touched.firstName}
                    helperText={
                      formik.errors.firstName && formik.touched.firstName
                        ? formik.errors.firstName
                        : null
                    }
                  />
                </StyledInputModal>
              </StyledDivTopInputs>
              <StyledDivTopInputs>
                <StyledInputModal>
                  <StyledInputTitle>Email *</StyledInputTitle>
                  <TextField
                    placeholder="Email"
                    id="Driver-Email"
                    name="email"
                    variant="outlined"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    InputProps={{
                      sx: sxInput,
                    }}
                    value={formik.values.email}
                    error={formik.errors.email && formik.touched.email}
                    helperText={
                      formik.errors.email && formik.touched.email
                        ? formik.errors.email
                        : null
                    }
                  />
                </StyledInputModal>
                <StyledInputModal>
                  <StyledInputTitle>Téléphone *</StyledInputTitle>
                  <PhoneInput
                    inputClass="phoneInput"
                    containerClass="phoneInputContainer"
                    onBlur={formik.handleBlur}
                    id="Driver-phone"
                    onChange={(newValue) => {
                      formik.setFieldValue('phone', newValue)
                    }}
                    value={formik.values.phone}
                    onlyCountries={getPhoneInputCountries()}
                    country={'fr'}
                    specialLabel={''}
                    inputProps={{
                      name: 'phone',
                      required: true,
                      country: 'fr',
                    }}
                    inputStyle={phoneInputStyles}
                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                  />
                  {formik.touched.phone && formik.errors.phone && (
                    <div
                      style={{
                        color: 'red',
                        fontWeight: '400',
                        fontSize: '0.75rem',
                        lineHeight: '1.66',
                        letterSpacing: '0.03333em',
                        textAlign: 'left',
                        marginTop: '3px',
                        marginRight: '14px',
                        marginBottom: '0',
                        marginLeft: '14px',
                      }}
                    >
                      {formik.errors.phone}
                    </div>
                  )}
                </StyledInputModal>
              </StyledDivTopInputs>
              <StyledDivFullWidth>
                <StyledInputTitle>Adresse</StyledInputTitle>
                <AddressAutoComplete
                  id="Driver-adresse"
                  placeholder="Adresse du chauffeur"
                  selectedDefaultAddress={formik.values.adresse}
                  handleQueryChange={(event) => console.log(event)}
                  handleOnAddressSelect={(event) => {
                    init && formik.setFieldValue('adresse', event)
                  }}
                  sxInput={sxInput}
                />
              </StyledDivFullWidth>

              <StyledDivTopInputs>
                <StyledInputModal>
                  <StyledInputTitle>Rôle</StyledInputTitle>
                  <TextField
                    id="Participant-Role"
                    name="role"
                    variant="outlined"
                    onChange={formik.handleChange}
                    InputProps={{
                      sx: sxInput,
                    }}
                    value={formik.values.role}
                    select
                    error={formik.errors.role && formik.touched.role}
                    helperText={
                      formik.errors.role && formik.touched.role
                        ? formik.errors.role
                        : null
                    }
                  >
                    {ParticipantRoleType.map((key, index) => (
                      <MenuItem value={key.code} key={index}>
                        {key.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </StyledInputModal>

                <StyledInputModal>
                  <StyledInputTitle>Société</StyledInputTitle>
                  <TextField
                    placeholder="Société"
                    id="Driver-societe"
                    name="company"
                    variant="outlined"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    InputProps={{
                      sx: sxInput,
                    }}
                    value={formik.values.company}
                    error={formik.errors.company && formik.touched.company}
                    helperText={
                      formik.errors.company && formik.touched.company
                        ? formik.errors.company
                        : null
                    }
                  />
                </StyledInputModal>
              </StyledDivTopInputs>
              <StyledDivFullWidth>
                <StyledInputTitle>Clés associés</StyledInputTitle>
                <div style={{ display: 'flex' }}>
                  <AutoComplete
                    freeSolo
                    value={formik.values.competences}
                    name={'competences'}
                    onChange={(event, value) => {
                      onSelectKey(value)
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    onInputChange={(event, value) => onChangeSearchKey(value)}
                    id="Driver-Competances"
                    options={keys}
                    getOptionLabel={(option) => option.value}
                    variant="outlined"
                    placeholder={'Choisir une compétence'}
                    sxInputStyle={sxInput}
                    multiple={true}
                    error={formik.errors.competences && formik.touched.competences}
                    helperText={
                      formik.errors.competences && formik.touched.competences
                        ? formik.errors.competences
                        : null
                    }
                    fullWidth={true}
                    ListboxProps={{
                      onScroll: loadMoreKeys,
                    }}
                    renderOption={(props, option) => (
                      <li {...props} id={`Compétences-${option.id}`} key={option.id}>
                        <div className="AutoCompteFilterOption">{option.value}</div>
                      </li>
                    )}
                    loading={isLoadingKeysList}
                    inputValue={searchKey}
                    open={openListOptions}
                    onBlur={() => setOpenListOptions(false)}
                    onFocus={() => setOpenListOptions(true)}
                    onClose={(event, reason) => {
                      if (reason === 'escape' || reason === 'blur') {
                        setOpenListOptions(false)
                      }
                    }}
                    onClick={() => setOpenListOptions(true)}
                  />
                </div>
              </StyledDivFullWidth>
            </StyledDivContent>
          }
          handleClose={handleClose}
          handleClickAction={formik.handleSubmit}
          disabled={verifEdit()}
          isLoadingButtonAction={isLoadingParticipantAction}
        />
      </form>
    </>
  )
}
DriverModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  keys: PropTypes.array,
  createParticipant: PropTypes.func,
  errorMessage: PropTypes.string,
  isEdit: PropTypes.bool,
  errorActionCourse: PropTypes.string,
  modalTitle: PropTypes.string,
  isLoadingParticipantAction: PropTypes.bool,
  success: PropTypes.bool,
  updateParticipant: PropTypes.func,
  row: PropTypes.object,
  fetchKeys: PropTypes.func,
  isLoadingKeysList: PropTypes.bool,
  searchKeys: PropTypes.func,
  fetchMoreKeys: PropTypes.func,
  keysCount: PropTypes.number,
  resetKey: PropTypes.func,
  resetParticipantConfig: PropTypes.func,
  errorMessageConfig: PropTypes.string,
  successConfig: PropTypes.bool,
  keyCreatedObject: PropTypes.object,
}

export default DriverModal
