import React from 'react'
import PropTypes from 'prop-types'

import TextField from '@mui/material/TextField'

import { StyledCell } from './style'
const ColumnTextComponentEditable = ({
  content,
  id,
  onChange,
  nameAttribut,
  errorsCourseEditable,
  disabledColumn,
  valuesmodif,
}) => {
  const onChangeValue = (valueEvent) => {
    const value = valueEvent.trim()
    if (value >= 0) {
      onChange({ [`${nameAttribut}`]: Number(value) })
    }
  }
  return (
    <StyledCell id={id} sx={{ color: disabledColumn && '#8E8E8E' }}>
      <TextField
        id="editableText"
        value={valuesmodif != '-' ? valuesmodif : content === '-' ? '0' : content}
        name="editableText"
        variant="outlined"
        onChange={(event) => {
          onChangeValue(event.target.value)
        }}
        error={errorsCourseEditable.includes(nameAttribut)}
        type="number"
        InputProps={{
          inputProps: {
            min: 1,
            type: 'text',
            pattern: '[0-9]*',
          },
        }}
      />
    </StyledCell>
  )
}
ColumnTextComponentEditable.propTypes = {
  content: PropTypes.string,
  key: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  nameAttribut: PropTypes.string,
  errorsCourseEditable: PropTypes.array,
  disabledColumn: PropTypes.bool,
  valuesmodif: PropTypes.string,
}

export default ColumnTextComponentEditable
