export const SET_FILTER_COMMANDE = 'SET_FILTER_COMMANDE'
export const SET_FILTER_PLANIFICATION = 'SET_FILTER_PLANIFICATION'
export const SET_FILTER_ERROR = 'SET_FILTER_ERROR'
export const REMOVE_FILTER_ERROR = 'REMOVE_FILTER_ERROR'
export const SET_FILTER_ENTREPOT = 'SET_FILTER_ENTREPOT'
export const GET_BUSINESS_OWNER = 'GET_BUSINESS_OWNER'
export const GET_BUSINESS_OWNER_SUCCESS = 'GET_BUSINESS_OWNER_SUCCESS'
export const GET_BUSINESS_OWNER_FAILURE = 'GET_BUSINESS_OWNER_FAILURE'
export const SAVE_SEARCH_CORNER_ONGLET = 'SAVE_SEARCH_CORNER_ONGLET'
export const RESET_FILTER_COMMANDE = 'RESET_FILTER_COMMANDE'
export const RESET_FILTER_PLANIFICATION = 'RESET_FILTER_PLANIFICATION'
export const RESET_FILTER_ENTREPOT = 'RESET_FILTER_ENTREPOT'

export const SET_FILTER_FREIGHTS = 'SET_FILTER_FREIGHTS'
export const RESET_FILTER_FREIGHTS = 'RESET_FILTER_FREIGHTS'

export const SET_FILTER_TOUR = 'SET_FILTER_TOUR'
export const RESET_FILTER_TOUR = 'RESET_FILTER_TOUR'

export const RESET_ALL_FILTER = 'RESET_ALL_FILTER'

export const SET_FILTER_DISCUSSION = 'SET_FILTER_DISCUSSION'
export const RESET_FILTER_DISCUSSION = 'RESET_FILTER_DISCUSSION'

export const GET_ALL_SUBJECT = 'GET_ALL_SUBJECT'
export const GET_ALL_SUBJECT_SUCCESS = 'GET_ALL_SUBJECT_SUCCESS'
export const GET_ALL_SUBJECT_FAILURE = 'GET_ALL_SUBJECT_FAILURE'
export const FILTER_CHANGED = 'FILTER_CHANGED'
export const PAGE_CHANGED = 'PAGE_CHANGED'
export const PAGE_LIMIT_CHANGED = 'PAGE_LIMIT_CHANGED'
export const IS_OPEN_CHANGED = 'IS_OPEN_CHANGED'
export const SEARCH_DISCUSSION = 'SEARCH_DISCUSSION'
export const SET_SELECTED_DATE = 'SET_SELECTED_DATE'

export const FETCH_DRIVERS_FILTER = 'FETCH_DRIVERS_FILTER'
export const FETCH_DRIVERS_FILTER_SUCCESS = 'FETCH_DRIVERS_FILTER_SUCCESS'
export const FETCH_DRIVERS_FILTER_FAILURE = 'FETCH_DRIVERS_FILTER_FAILURE'
export const DRIVERS_FILTER = 'drivers_filter'

export const GET_MACRO_CANAL = 'GET_MACRO_CANAL'
export const GET_MACRO_CANAL_SUCCESS = 'GET_MACRO_CANAL_SUCCESS'
export const GET_MACRO_CANAL_FAILURE = 'GET_MACRO_CANAL_FAILURE'

export const GET_PRESTATIONS = 'GET_PRESTATIONS'
export const GET_PRESTATIONS_SUCCESS = 'GET_PRESTATIONS_SUCCESS'
export const GET_PRESTATIONS_FAILURE = 'GET_PRESTATIONS_FAILURE'

export const GET_DEPARTEMENTS = 'GET_DEPARTEMENTS'
export const GET_DEPARTEMENTS_SUCCESS = 'GET_DEPARTEMENTS_SUCCESS'
export const GET_DEPARTEMENTS_FAILURE = 'GET_DEPARTEMENTS_FAILURE'

export const GET_SALES_CHANNEL = 'SALES_CHANNEL_GET_REQUEST' // GET_REQUEST
export const GET_SALES_CHANNEL_SUCCESS = 'SALES_CHANNEL_GET_SUCCESS' // GET_SUCCESS
export const GET_SALES_CHANNEL_FAILURE = 'SALES_CHANNEL_GET_FAILURE' //GET_ERROR

export const GET_TOUR_LIST = 'GET_TOUR_LIST'
export const GET_TOUR_LIST_SUCCESS = 'GET_TOUR_LIST_SUCCESS'
export const GET_TOUR_LIST_FAILURE = 'GET_TOUR_LIST_FAILURE'

export const GET_TOUR_LIST_MORE = 'GET_TOUR_LIST_MORE'
export const GET_TOUR_LIST_MORE_SUCCESS = 'GET_TOUR_LIST_MORE_SUCCESS'
export const GET_TOUR_LIST_MORE_FAILURE = 'GET_TOUR_LIST_MORE_FAILURE'

export const GET_WAREHOUSE = 'GET_WAREHOUSE'
export const GET_WAREHOUSE_SUCCESS = 'GET_WAREHOUSE_SUCCESS'
export const GET_WAREHOUSE_FAILURE = 'GET_WAREHOUSE_FAILURE'

export const GET_FILTER_PARAMS = 'GET_FILTER_PARAMS'

export const RESET_FROMAPPLIQUER = 'RESET_FROMAPPLIQUER'

export const SET_DEFAULT_FILTER_COMMANDE = 'SET_DEFAULT_FILTER_COMMANDE'
export const SET_DEFAULT_FILTER_PLANIFICATION = 'SET_DEFAULT_FILTER_PLANIFICATION'
export const SET_DEFAULT_FILTER_ENTREPOT = 'SET_DEFAULT_FILTER_ENTREPOT'

export const GET_FILTER_COLLABORATEUR = 'GET_FILTER_COLLABORATEUR'
export const GET_FILTER_COLLABORATEUR_SUCCESS = 'GET_FILTER_COLLABORATEUR_SUCCESS'
export const GET_FILTER_COLLABORATEUR_FAILURE = 'GET_FILTER_COLLABORATEUR_FAILURE'

export const SAVE_DEFAULT_FILTER_COMMANDE = 'SAVE_DEFAULT_FILTER_COMMANDE'
export const RESET_DEFAULT_FILTER_COMMANDE = 'RESET_DEFAULT_FILTER_COMMANDE'

export const DISPLAY_MODE_CHANGED = 'DISPLAY_MODE_CHANGED'

export const CREATE_FILTER_COLLABORATEUR = 'CREATE_FILTER_COLLABORATEUR'
export const CREATE_FILTER_COLLABORATEUR_SUCCESS =
  'CREATE_FILTER_COLLABORATEUR_SUCCESS'
export const CREATE_FILTER_COLLABORATEUR_FAILURE =
  'CREATE_FILTER_COLLABORATEUR_FAILURE'

export const RESET_ACTIONS_CREATION_FILTER = 'RESET_ACTIONS_CREATION_FILTER'

export const DELETE_FILTER_COLLABORATEUR = 'DELETE_FILTER_COLLABORATEUR'
export const DELETE_FILTER_COLLABORATEUR_SUCCESS =
  'DELETE_FILTER_COLLABORATEUR_SUCCESS'
export const DELETE_FILTER_COLLABORATEUR_FAILURE =
  'DELETE_FILTER_COLLABORATEUR_FAILURE'

export const RESET_ACTION_DELETE_FILTER = 'RESET_ACTION_DELETE_FILTER'

export const RENOMMER_FILTER_COLLABORATEUR = 'RENOMMER_FILTER_COLLABORATEUR'
export const RENOMMER_FILTER_COLLABORATEUR_SUCCESS =
  'RENOMMER_FILTER_COLLABORATEUR_SUCCESS'
export const RENOMMER_FILTER_COLLABORATEUR_FAILURE =
  'RENOMMER_FILTER_COLLABORATEUR_FAILURE'

export const RESET_ACTION_FILTER = 'RESET_ACTION_FILTER'

export const SET_ONGLET_FILTER_OPEN = 'SET_ONGLET_FILTER_OPEN'

export const MODIFICATION_FILTER_COLLABORATEUR = 'MODIFICATION_FILTER_COLLABORATEUR'
export const MODIFICATION_FILTER_COLLABORATEUR_SUCCESS =
  'MODIFICATION_FILTER_COLLABORATEUR_SUCCESS'
export const MODIFICATION_FILTER_COLLABORATEUR_FAILURE =
  'MODIFICATION_FILTER_COLLABORATEUR_FAILURE'

export const RESET_ACTIONS_MODIFICATION_FILTER = 'RESET_ACTIONS_MODIFICATION_FILTER'

export const SAVE_SEARCH_ONGLET = 'SAVE_SEARCH_ONGLET'
export const SAVE_SEARCH_CORNER = 'SAVE_SEARCH_CORNER'

export const RESET_FILTER_COLLABORATEUR = 'RESET_FILTER_COLLABORATEUR'
