import { connect } from 'react-redux'
import Orders from '../../../pages/main/Orders'
import {
  getOrderByAgents,
  getOrderByFilter,
  removeError,
  fetchSearchCornerSave,
  resetOrders,
  removeErrorAction,
  resetOrderAction,
  removeErrorImportCourses,
  fetchSearchOngletCornerSave,
  setCheckCourse,
  setAllCheckCourse,
  initCheckCourse,
  removeErrorImportTournees,
} from '../../../redux/order/actions'
import {
  getOrders,
  getIsLoading,
  getError,
  getMyCommandsIsOn,
  getisActionOrderDone,
  getErrorActionCourse,
  getErrorImportCourses,
  getErrorImportCoursesMessage,
  getCoursesChecked,
  getCheckCoursesAll,
  getErrorImportTournee,
  getErrorImportTourneeMessage,
} from '../../../redux/order/selectors'
import {
  getfilterCommande,
  getfilterError,
  getSearchCorner,
  getSearchCornerOnglet,
  getCount,
  getDefaultFilterCommande,
  getLimit,
  getPagesCount,
  getDisplayGrid,
  getSort,
  getOrderBy,
} from '../../../redux/filter/selectors'
import {
  setFilterCommande,
  removeErrorFilter,
  resetFilterCommande,
  pageChanged,
  pageLimitChanged,
  setDefaultFilterCommande,
  saveDefaultFilterCommander,
  resetDefaultFilterCommander,
} from '../../../redux/filter/actions'

import { LimitPaginationList } from '../../../components/Pagination/LimitPaginationList'

const mapStateToProps = (state) => ({
  filterReducer: getfilterCommande(state),
  shipments: getOrders(state),
  isLoading: getIsLoading(state),
  error: getError(state),
  count: getCount(state),
  pageCount: getPagesCount(state),
  limit: getLimit(state),
  myCommandsIsOn: getMyCommandsIsOn(state),
  displayGrid: getDisplayGrid(state),
  isActionDone: getisActionOrderDone(state),
  errorFilter: getfilterError(state),
  searchCornerOnglet: getSearchCornerOnglet(state),
  searchCorner: getSearchCorner(state),
  errorActionCourse: getErrorActionCourse(state),
  defaultFilterCommande: getDefaultFilterCommande(state),
  errorImportCourses: getErrorImportCourses(state),
  errorImportCoursesMessage: getErrorImportCoursesMessage(state),
  sort: getSort(state),
  orderBy: getOrderBy(state),
  coursesChecked: getCoursesChecked(state),
  checkCoursesAll: getCheckCoursesAll(state),
  errorImportTournee: getErrorImportTournee(state),
  errorImportTourneeMessage: getErrorImportTourneeMessage(state),
})
const mapDisptachToProps = (dispatch) => ({
  setFilter: (filter, fromAppliquer) =>
    dispatch(setFilterCommande(filter, fromAppliquer)),
  getShipmentByFilter: () => {
    dispatch(pageLimitChanged(LimitPaginationList[0].value))
    dispatch(getOrderByFilter())
  },
  getShipmentByAgents: () => {
    dispatch(pageLimitChanged(LimitPaginationList[0].value))
    dispatch(getOrderByAgents())
  },
  pageLimitChanged: (
    limit,
    isOn,
    isSearchCornerOnglet,
    isSearchCorner,
    payloadSearchCornerOnglet,
    payloadSearchCorner
  ) => {
    dispatch(pageLimitChanged(limit))
    isOn
      ? dispatch(getOrderByAgents())
      : isSearchCornerOnglet
      ? dispatch(fetchSearchOngletCornerSave(payloadSearchCornerOnglet))
      : isSearchCorner
      ? dispatch(fetchSearchCornerSave(payloadSearchCorner))
      : dispatch(getOrderByFilter())
  },
  pageChanged: (
    offset,
    isOn,
    isSearchCornerOnglet,
    isSearchCorner,
    payloadSearchCornerOnglet,
    payloadSearchCorner
  ) => {
    dispatch(pageChanged(offset))
    isOn
      ? dispatch(getOrderByAgents())
      : isSearchCornerOnglet
      ? dispatch(fetchSearchOngletCornerSave(payloadSearchCornerOnglet))
      : isSearchCorner
      ? dispatch(fetchSearchCornerSave(payloadSearchCorner))
      : dispatch(getOrderByFilter())
  },
  sortShipment: (filter, fromAppliquer) => {
    dispatch(initCheckCourse())
    dispatch(setFilterCommande(filter, fromAppliquer))
  },
  removeError: () => dispatch(removeError()),
  removeErrorFilter: () => dispatch(removeErrorFilter()),
  resetFilterCommande: () => dispatch(resetFilterCommande()),
  fetchSearchCornerSave: (search) => dispatch(fetchSearchCornerSave(search)),
  resetShipments: () => dispatch(resetOrders()),
  removeErrorAction: () => dispatch(removeErrorAction()),
  resetOrderAction: () => dispatch(resetOrderAction()),
  setDefaultFilterCommande: (filter) => dispatch(setDefaultFilterCommande(filter)),
  saveDefaultFilterCommander: () => dispatch(saveDefaultFilterCommander()),
  resetDefaultFilterCommander: () => dispatch(resetDefaultFilterCommander()),
  removeErrorImportCourses: () => dispatch(removeErrorImportCourses()),
  setAllCheckCourse: () => dispatch(setAllCheckCourse()),
  setCheckCourse: (codeCourse, courseCheck) =>
    dispatch(setCheckCourse(codeCourse, courseCheck)),
  fetchSearchOngletCornerSave: (search) => {
    dispatch(initCheckCourse())
    dispatch(fetchSearchOngletCornerSave(search))
  },
  removeErrorImportTournees: () => dispatch(removeErrorImportTournees()),
})

export default connect(mapStateToProps, mapDisptachToProps)(Orders)
