import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import Dialog from '@mui/material/Dialog'

import DialogTitleComponent from './components/DialogTitle'
import ShimpmentsInfos from './components/ShimpmentsInfos'
import Filter from './components/Filters'
import DialogActionsComponent from './components/DialogActions'
import DriversTab from './components/DriversTab'
import DialogAvertissement from '../../../../components/DialogError/dialogAvertissementClose'
import moment from 'moment'
import {
  DONE_STATUS,
  LIVRAISON_IMPOSSIBLE,
  RETRAIT_IMPOSSIBLE,
  SUPPLY_WAIT_STATUS,
  SUPPLY_IN_PROGRESS_STATUS,
  SUPPLY_COMPLETE_STATUS,
} from '../../../../utils/values'
import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { fleetParseObject } from '../../../../utils/tours'
import CustomizedDialogs from '../../../../components/ModalDialog'
import { CircularProgress, IconButton, InputAdornment } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'

function Equipage({
  open,
  hideDialog,
  selectedCourses,
  shipments,
  fetchDrivers,
  isLoadingDriversOptimiser,
  driversOptimiser,
  dropoffStartCourses,
  equalDropoffStartCourses,
  getDriversPlannings,
  isLoadingAttribuer,
  ldt,
  affectTour,
  filterPlanifier,
  driversOptimiserCount,
  isErrorAffectPlanning,
  errorMessageSimulations,
  removeError,
  tours,
  viewPlannification,
  isSimLoading,
}) {
  const [filtersValues, setFilters] = useState({})
  const [isDisabledButton, setIsDisabledButton] = useState(false)
  const [driversOriginal, setDrivers] = useState([])

  const [errorDialogOpen, setErrorDialogOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [tourLdt, setTourLdt] = useState('')
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)
  const [search, setSearch] = useState('')
  const [init, setInit] = useState(false)
  const [driversOriginalSelected, setDriversOriginalSelected] = useState([])
  const [newTourOpen, setNewTourOpen] = useState(false)

  useEffect(() => {
    setFilters(getFilterInit(filterPlanifier))
  }, [filterPlanifier])

  useEffect(() => {
    const checkErrorCloture = shipments
      .filter((el) => selectedCourses.includes(el.code))
      .some((ship) =>
        [
          RETRAIT_IMPOSSIBLE.code,
          LIVRAISON_IMPOSSIBLE.code,
          DONE_STATUS.code,
        ].includes(ship.status.code)
      )
    const checkErrorSupply = shipments
      .filter((el) => selectedCourses.includes(el.code))
      .some((ship) =>
        [
          SUPPLY_WAIT_STATUS.code,
          SUPPLY_IN_PROGRESS_STATUS.code,
          SUPPLY_COMPLETE_STATUS.code,
        ].includes(ship.status.code)
      )
    if (checkErrorCloture) {
      setErrorMessage('Une des courses est déja cloturé. Veuillez la désélectionner')
      setErrorDialogOpen(true)
    } else if (checkErrorSupply) {
      setErrorMessage(
        "Pas possible d'attribuer des courses en statut d'approvisionnement"
      )
      setErrorDialogOpen(true)
    }
  }, [])

  const handleChange = (event) => {
    setTourLdt(event.target.value)
  }
  const getFilterPayload = (limit, offset, search) => {
    return {
      prestations:
        filtersValues.prestation.length > 0 ? filtersValues.prestation : undefined,
      actif: filtersValues.statusDrivers,
      dayOfWeek: moment(dropoffStartCourses)
        .locale('en')
        .format('dddd')
        .toUpperCase(),
      limit: limit,
      offset: offset,
      query: search.length > 0 ? search : undefined,
    }
  }
  useEffect(() => {
    if (equalDropoffStartCourses && Object.keys(filtersValues).length > 0) {
      const filter = getFilterPayload(rowsPerPage, 0, search)
      fetchDrivers(filter)
      setInit(true)
    }
  }, [filtersValues])
  useEffect(() => {
    setDrivers(driversOptimiser)
  }, [driversOptimiser])

  useEffect(() => {
    if (driversOriginalSelected.length > 0) {
      const isDisabledButton = driversOriginalSelected.some((val) => val.checked)
      setIsDisabledButton(isDisabledButton)
    }
  }, [driversOriginalSelected])

  const attribuer = () => {
    const driversAttribuer = driversOriginalSelected.map((driver) => driver.id)

    const payload = {
      deliveryDate: dropoffStartCourses,
      driverId: driversAttribuer,

      ...(driversAttribuer.length === 1 ? { isNewTour: true } : {}),
      ...(tourLdt ? { ldt: tourLdt } : { newTour: true }),
    }

    getDriversPlannings({
      data: payload,
      fleet: fleetParseObject(driversOriginalSelected, dropoffStartCourses),
    })
    hideDialog()
    setNewTourOpen(false)
    viewPlannification(true)
  }

  const handleClose = () => {
    setNewTourOpen(false)
    viewPlannification(false)
    hideDialog()
  }

  const handleTourSwap = () => {
    let selectedDriver = {}
    driversOriginalSelected.map(({ id, firstname, lastname, operationalHours }) => {
      selectedDriver = {
        firstName: firstname,
        name: lastname,
        id: String(id),
        shiftStart: operationalHours.openTime,
        shiftEnd: operationalHours.closeTime,
      }
    })
    affectTour({
      data: {
        driver: selectedDriver,
        ldt: ldt,
        deliveryDate: dropoffStartCourses,
        userId: String(JSON.parse(localStorage.getItem('currentUser'))?.id),
      },
    })

    hideDialog()
  }

  const getFilterInit = (filterPlanifier) => {
    return {
      statusDrivers: true,
      prestation:
        filterPlanifier?.prestation && filterPlanifier?.prestation != 'TOUS'
          ? filterPlanifier.prestation
          : [],
    }
  }
  const onSearch = (searchValue) => {
    const filter = getFilterPayload(rowsPerPage, 0, searchValue)

    fetchDrivers(filter)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    const filter = getFilterPayload(rowsPerPage, newPage * rowsPerPage, search)

    fetchDrivers(filter)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
    const filter = getFilterPayload(parseInt(event.target.value, 10), 0, search)

    fetchDrivers(filter)
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search.length > 2) {
        onSearch(search)
      } else {
        if (init) {
          const filter = getFilterPayload(rowsPerPage, 0, '')

          fetchDrivers(filter)
        }
      }
    }, 500)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  const onChangeSearch = (value) => {
    let val = value.trim()
    setSearch(val)
  }
  const handleOpenCreateNewTour = () => {
    const driversAttribuer = driversOriginalSelected.map((driver) => driver.id)
    const payload = {
      deliveryDate: dropoffStartCourses,
      driverId: driversAttribuer,
      ...(driversAttribuer.length === 1 ? { isNewTour: true } : {}),
      ...(driversAttribuer.length > 1 ? { newTour: true } : {}),
    }

    getDriversPlannings({
      data: payload,
      fleet: fleetParseObject(driversOriginalSelected, dropoffStartCourses),
    })
    if (driversAttribuer.length > 1) {
      hideDialog()
      viewPlannification(true)
    }
  }

  useEffect(() => {
    if (tours[0]?.markers.length > 2 && driversOriginalSelected.length == 1) {
      setNewTourOpen(true)
    }

    if (tours[0]?.markers.length == 2 && driversOriginalSelected.length == 1) {
      hideDialog()
      viewPlannification(true)
    }
  }, [tours])

  return (
    <>
      {errorDialogOpen || isErrorAffectPlanning ? (
        <DialogAvertissement
          messageErreur={errorMessage || errorMessageSimulations.message}
          open={errorDialogOpen}
          onClose={() => {
            setErrorDialogOpen(false)
            setErrorMessage(null)
            removeError()
            hideDialog()
          }}
        />
      ) : !equalDropoffStartCourses ? (
        <DialogAvertissement
          messageErreur={
            !equalDropoffStartCourses && (selectedCourses.length === 1 || ldt)
              ? 'Date de livraison manquant                           '
              : 'Choisissez des courses avec la même date de livraison'
          }
          open={open}
          onClose={hideDialog}
        />
      ) : (
        <>
          <Dialog
            open={open}
            onClose={hideDialog}
            fullWidth
            maxWidth="md"
            sx={{
              paddingTop: '70px',

              '& .MuiDialog-paperWidthMd': {
                maxWidth: '960px !important',
              },
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitleComponent onChangeSearch={onChangeSearch} />
            <ShimpmentsInfos
              ldt={ldt}
              selectedCourses={selectedCourses}
              shipments={shipments}
              dropOffStart={dropoffStartCourses}
            />

            <Filter setFilters={setFilters} filters={filtersValues} />
            <DriversTab
              ldt={ldt}
              drivers={driversOriginal}
              isLoading={isLoadingDriversOptimiser}
              setDrivers={setDrivers}
              setErrorDialogOpen={setErrorDialogOpen}
              setErrorMessage={setErrorMessage}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              rowsPerPage={rowsPerPage}
              page={page}
              driversOptimiserCount={driversOptimiserCount}
              setDriversOriginalSelected={setDriversOriginalSelected}
              driversOriginalSelected={driversOriginalSelected}
            />
            {isSimLoading ? (
              <div
                style={{
                  margin: '1px 0 0',
                  padding: '12px',
                  paddingRight: '30px',
                  boxShadow: '3px 0 3px 0 rgb(0 0 0 / 16%)',
                  textAlign: 'right',
                }}
              >
                <CircularProgress
                  sx={{
                    color: '#1976D2',
                    width: '35px !important',
                    height: '35px !important',
                    marginLeft: '10px',
                  }}
                />
              </div>
            ) : (
              <DialogActionsComponent
                isLoadingTour={isSimLoading}
                isLoading={
                  isLoadingDriversOptimiser ||
                  !isDisabledButton ||
                  isLoadingAttribuer
                }
                onAttribuer={ldt ? handleTourSwap : handleOpenCreateNewTour}
              />
            )}
          </Dialog>
          <CustomizedDialogs
            isDoubleTourner={true}
            open={newTourOpen}
            tourDisable={!tourLdt}
            title={'Optimiser Tournée'}
            content={
              <div style={{ margin: '15px', marginRight: '30px' }}>
                <div style={{ marginBottom: '15px' }}>
                  <div style={{ marginBottom: '15px' }}>
                    <div style={{ marginBottom: '15px' }}>
                      {`Sélectionnez une tournée si vous souhaitez faire des modifications.`}{' '}
                      <br />
                      {`Sinon, cliquez sur "Continuer" pour créer une nouvelle.`}
                    </div>
                  </div>
                </div>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Tournée</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={tourLdt}
                      label="tourLdt"
                      onChange={handleChange}
                      endAdornment={
                        tourLdt && (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="clear selection"
                              onClick={() => handleChange({ target: { value: '' } })}
                              edge="end"
                              size="small"
                            >
                              <ClearIcon />
                            </IconButton>
                          </InputAdornment>
                        )
                      }
                    >
                      {tours.map((tour, i) => {
                        return (
                          <MenuItem key={i} value={tour.ldt}>
                            {tour.ldt}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                </Box>
              </div>
            }
            handleClose={handleClose}
            handleClickAction={attribuer}
          />
        </>
      )}
    </>
  )
}
Equipage.propTypes = {
  open: PropTypes.bool,
  hideDialog: PropTypes.func,
  selectedCourses: PropTypes.array,
  shipments: PropTypes.array,
  fetchDrivers: PropTypes.func,
  isLoadingDriversOptimiser: PropTypes.bool,
  driversOptimiser: PropTypes.array,
  dropoffStartCourses: PropTypes.any,
  equalDropoffStartCourses: PropTypes.bool,
  getDriversPlannings: PropTypes.func,
  isLoadingAttribuer: PropTypes.bool,
  setEquipageDialogOpen: PropTypes.func,
  affectTour: PropTypes.func,
  ldt: PropTypes.any,
  filterPlanifier: PropTypes.object,
  driversOptimiserCount: PropTypes.number,
  isErrorAffectPlanning: PropTypes.bool,
  errorMessageSimulations: PropTypes.object,
  removeError: PropTypes.func,
  tours: PropTypes.array,
  viewPlannification: PropTypes.func,
  isSimLoading: PropTypes.bool,
}

export default Equipage
