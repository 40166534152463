import { connect } from 'react-redux'
import RelatedCourse from '../../pages/main/Orders/RelatedCourse'

import { getRelatedCourseInfo } from '../../redux/order/actions'
import {
  getError,
  getIsLoadingRelatedCourse,
  getRelatedCourse,
} from '../../redux/order/selectors'

const mapStateToProps = (state) => ({
  courseInfos: getRelatedCourse(state),
  isLoadingRelatedCourse: getIsLoadingRelatedCourse(state),
  error: getError(state),
})

const mapDisptachToProps = (dispatch) => ({
  getRelatedCourseInfo: (code) => dispatch(getRelatedCourseInfo(code)),
})

export default connect(mapStateToProps, mapDisptachToProps)(RelatedCourse)
