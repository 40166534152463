import React from 'react'
import AccordionItem from '../../containers/main/AccordionItem'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'

export const Bloc = styled('div')({
  width: '100%',
  height: 'calc(100% - 45px)',
  overflowX: 'auto',
  '&::-webkit-scrollbar': {
    width: '7px !important', // hauteur de la scrollbar horizontale
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#1976d2 !important',
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent',
  },
  '@media (max-width: 600px)': {
    width: '100% !important',
  },
})

export default function AccordionList({ list }) {
  return (
    <Bloc>
      {list.map((e) => {
        return (
          <AccordionItem
            key={e.index}
            index={e.index}
            id={e.index}
            isFocused={e.isFocused}
            expanded={e.expanded}
            heading={e.heading}
            lock={e.lock}
            summary={e.summary}
            details={e.details}
            path={e.path}
            color={e.color}
            handleChange={e.handleChange}
          />
        )
      })}
    </Bloc>
  )
}

AccordionList.propTypes = {
  list: PropTypes.array,
}
