import { styled } from '@mui/material/styles'

export const MapContainer = styled('div')(({ displayNone }) => ({
  display: displayNone && 'none',
  width: '100%',
  position: 'relative',
  '@media (max-width: 600px)': {
    width: '100%',
    height: '100%',
  },
}))

export const TopContainer = styled('div')({
  display: 'flex',
  overflow: 'hidden',
  height: '100%',
  flexWrap: 'nowrap', // Default no wrap

  '@media (max-width: 600px)': {
    flexWrap: 'wrap',
    overflow: 'auto',
  },
})

export const DetailTounerContainer = styled('div')(
  ({ expandVertical, expandHorizental }) => ({
    display: expandHorizental ? 'none' : 'inline-block',
    transition: 'all 0.1s',
    minWidth: expandVertical ? '100%' : '50%',
    '&::-webkit-scrollbar': {
      width: '7px !important', // hauteur de la scrollbar horizontale
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#1976d2 !important',
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent',
    },
    '@media (max-width: 600px)': {
      width: '100% !important',
    },
  })
)
