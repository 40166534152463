import { ROLES, ROLE_PLANIFICATEUR } from './roleValues'

export const getPathRedirectRole = (userRole) => {
  return ROLES[userRole]?.pathRedirect || '/commandes'
}

export const getActionRole = (userRole, action) => {
  return (
    ROLES[userRole]?.actions.includes(action) || userRole != ROLE_PLANIFICATEUR.code
  )
}

export const routeCanAccess = (routes, userRole) => {
  return routes.filter(
    (route) =>
      route.canAccess.includes(userRole) || userRole != ROLE_PLANIFICATEUR.code
  )
}

export const isPathInRoutes = (routes, pathname, userRole) => {
  return routes
    .filter(
      (route) =>
        route.canAccess.includes(userRole) || userRole != ROLE_PLANIFICATEUR.code
    )
    .flatMap((item) =>
      item.children?.length > 0
        ? item.children.map((child) => child.path)
        : item.path
    )
    .includes(
      pathname.includes('/deliveries')
        ? '/deliveries'
        : pathname.includes('/edit')
        ? '/edit'
        : pathname
    )
}
