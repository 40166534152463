import { createSelector } from 'reselect'
import { getfullName } from '../../utils/values'

export const driversListFilter = (drivers) =>
  drivers.map((driver) => {
    return { name: getfullName(driver, 'N/A'), code: driver.code, id: driver.id } // Added ID for AttributionDialog
  })

export const getfilterState = ({ filters }) => filters
export const getfilterCommande = (state) => getfilterState(state).commande
export const getfilterPlanification = (state) => getfilterState(state).planification
export const getfilterEntreprot = (state) => getfilterState(state).entrepot
export const getfilterError = (state) => getfilterState(state).error
export const getfilterDiscussion = (state) => getfilterState(state).discussion

export const getIsLoadingBusinessOwner = (state) =>
  getfilterState(state).isLoadingBusinessOwner
export const getBusinessOwnerList = (state) =>
  getfilterState(state).businessOwnerList
export const getSearchCornerOnglet = (state) =>
  getfilterState(state).searchCornerOnglet
export const getSearchCorner = (state) => getfilterState(state).searchCorner

export const getfilterFreights = (state) => getfilterState(state).freights
export const getProgrammerPickedDate = (state) =>
  getfilterState(state).programmerPickedDate

export const getFilterTours = (state) => getfilterState(state).tours

export const getSubjects = (state) => getfilterState(state).subjects
export const getIsLoadingSubjects = (state) =>
  getfilterState(state).isLoadingSubjects
export const getDriversFilter = (state) => getfilterState(state).driverfilter
export const getDriversIsLoadingFilter = (state) =>
  getfilterState(state).isLoadingDriverFilter

export const getIsServicesListLoading = (state) =>
  getfilterState(state).isGetServicesListLoading
export const getServices = (state) => getfilterState(state).services

export const getIsDepartemenetsListLoading = (state) =>
  getfilterState(state).isGetDepartementsLoading
export const getDepartemenets = (state) => getfilterState(state).departments

export const getMacroCanal = (state) => getfilterState(state).macroCanal
export const getIsGetMacroCanalLoading = (state) =>
  getfilterState(state).isGetMacroCanalLoading

export const getIsGetSalesChannelListLoading = (state) =>
  getfilterState(state).isGetSalesChannelListLoading

export const getSalesChannelList = (state) =>
  getfilterState(state).salesChannelList?.filter((elem) => elem.name !== '')

export const getShipmentSalesChannelList = (state) =>
  getfilterState(state).salesChannelList
export const getAssemblySalesChannelList = (state) =>
  getfilterState(state).salesChannelList

export const getSalesChannelListGroup = (state) =>
  getfilterState(state).salesChannelListGroup

export const getTourFilterList = (state) => getfilterState(state).tourList
export const getIsTourListLoading = (state) =>
  getfilterState(state).isGetTourListLoading
export const getIsTourListMoreLoading = (state) =>
  getfilterState(state).isGetTourListMoreLoading
export const getCountListTour = (state) => getfilterState(state).countListTour

export const getWareHouses = (state) => getfilterState(state).wareHouseList
export const getIsWareHouseLoading = (state) =>
  getfilterState(state).isGetWareHouseListLoading

export const getLimit = (state) => getfilterState(state).filter.limit
export const getPagesCount = createSelector(
  getfilterState,
  ({ filter: { limit }, count }) => {
    if (limit > count || count === 0) {
      return 1
    }
    if (limit !== 0) {
      return Math.trunc(count / limit) === count / limit
        ? count / limit
        : Math.trunc(count / limit) + 1
    } else {
      return 1
    }
  }
)

export const getCount = (state) => getfilterState(state).count

export const getIsOpened = (state) => getfilterState(state).isOpen

export const getFromAppliquer = (state) => getfilterState(state).fromAppliquer

export const getDefaultFilterCommande = (state) =>
  getfilterState(state).defaultFilterCommande
export const getDefaultFilterPlanification = (state) =>
  getfilterState(state).defaultFilterPlanification
export const getDefaultFilterTours = (state) =>
  getfilterState(state).defaultFilterTours
export const getDefaultFilterDiscussion = (state) =>
  getfilterState(state).defaultFilterDiscussion
export const getDefaultFilterEntrepot = (state) =>
  getfilterState(state).defaultFilterEntrepot
export const getDefaultFilterFreights = (state) =>
  getfilterState(state).defaultFilterFreights

export const getIsLoadingGetFilters = (state) =>
  getfilterState(state).isLoadingGetFilters
export const getFiltersCollaborateur = (state) =>
  getfilterState(state).filtersCollaborateur

export const getDisplayGrid = (state) => getfilterState(state).displayGrid

export const getIsLoadingActionFilter = (state) =>
  getfilterState(state).isLoadingActionFilter
export const getErrorActionFilter = (state) => getfilterState(state).error

export const getIsActionCreationFilter = (state) =>
  getfilterState(state).isActionCreationFilter

export const getIsActionDeleteFilter = (state) =>
  getfilterState(state).isActionDeleteFilter

export const getIsActionFilter = (state) => getfilterState(state).isActionFilter

export const getIdOngletFilterOpen = (state) =>
  getfilterState(state).idOngletFilterOpen

export const getIsActionModifierFilter = (state) =>
  getfilterState(state).isActionModifierFilter

export const getSort = (state) => getfilterState(state).filter.sort
export const getOrderBy = (state) => getfilterState(state).filter.orderBy
