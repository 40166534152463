import {
  OPTIMISER_DRIVER_GET,
  OPTIMISER_DRIVER_GET_SUCCESS,
  OPTIMISER_DRIVER_GET_FAILURE,
  GET_DRIVER_WARRNING,
  GET_DRIVER_WARRNING_SUCCESS,
  GET_DRIVER_WARRNING_FAILURE,
  GET_DRIVER_WARRNING_MORE_SUCCESS,
  GET_DRIVER_WARRNING_MORE_FAILURE,
} from './actionsConsts'
import initialState from './initialState'

export default (state = initialState(), action) => {
  if (action.type === OPTIMISER_DRIVER_GET) {
    return {
      ...state,
      driversOptimiser: [],
      isLoadingDriversOptimiser: true,
    }
  }

  if (action.type === OPTIMISER_DRIVER_GET_SUCCESS) {
    const { drivers, count } = action.payload.data
    return {
      ...state,
      driversOptimiser: drivers,
      driversOptimiserCount: count,
      isLoadingDriversOptimiser: false,
    }
  }

  if (action.type === OPTIMISER_DRIVER_GET_FAILURE) {
    return {
      ...state,
      isLoadingDriversOptimiser: false,
    }
  }

  if (action.type === GET_DRIVER_WARRNING) {
    return {
      ...state,
      drivers: [],
      driversOptimiserCount: 0,
      isLoadingDriversOptimiser: true,
    }
  }

  if (action.type === GET_DRIVER_WARRNING_SUCCESS) {
    const { drivers, count } = action.payload.data
    return {
      ...state,
      drivers: drivers,
      driversOptimiserCount: count,
      isLoadingDriversOptimiser: false,
    }
  }

  if (action.type === GET_DRIVER_WARRNING_MORE_SUCCESS) {
    const { drivers, count } = action.payload.data
    return {
      ...state,
      drivers: [...state.drivers, ...drivers],
      driversOptimiserCount: count,
      isLoadingDriversOptimiser: false,
    }
  }

  if (
    action.type === GET_DRIVER_WARRNING_FAILURE ||
    action.type === GET_DRIVER_WARRNING_MORE_FAILURE
  ) {
    return {
      ...state,
      isLoadingDriversOptimiser: false,
    }
  }

  return state
}
