import React from 'react'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import './index.scss'
import PropTypes from 'prop-types'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import LongTextComponent from '../../../../main/Orders/Table/LongTextComponent'
import VolumeIcon from '../../../../../assets/icons/cube.svg'
import IdleTimeIcon from '../../../../../assets/icons/polaris/idleTime'
import Checkbox from '@mui/material/Checkbox'
import Badge from '@mui/material/Badge'
import {
  convertirMinutesEnHeuresEtMinutes,
  getCourseType,
} from '../../../../../utils/utils'
import {
  checkActionsToursTable,
  labelsToursTable,
  labelsToursTableOptimisationFile,
  settingsTable,
} from './data'
import { Tooltip } from '@mui/material'
import { parseReceptionNumber } from '../../../../../utils/tours'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import MoveAction from '../MoveAction'
export default function ToursTable({
  tours /* This should be the prop for the new planning coming from driver */,
  index,
  isRecalculateLoading,
  isOpen,
  handleCheck,
  handleCheckAll,
  indexPlanning,
  checkedAll,
  nbVisitChecked,
  isOptimisationCoursesFiles,
  lock,
  planning,
  tour,
  disabledActionMov,
  moveTableTournee,
  deattributeCourse,
  handleDragEnd,
}) {
  const initialSimulationTabHeader = (e) => {
    return {
      receptionNumber: {
        name: 'receptionNumber',
        content: e?.receptionNumber ? parseReceptionNumber(e.receptionNumber) : '-',
      },
      type: {
        name: 'Type',
        content: e.type ? e.type : '-',
      },
      order: {
        name: 'Order',
        content: e.order ? e.order : '0',
      },
      courseRank: {
        name: 'Drop Off Order',
        content: e.courseRank || e.courseRank === 0 ? e.courseRank : '-',
      },
      courseType: {
        name: 'Type de course',
        content:
          isOptimisationCoursesFiles && e.courseType
            ? e.courseType
            : e.courseType
            ? getCourseType(e.courseType)
            : '-',
      },
      courseId: {
        name: 'Lettre de voiture',
        content: e.ldv ? e.ldv : '-',
      },
      arrival: {
        name: 'Heure d’arrivée',
        content: e.arrival ? e.arrival : '-',
      },
      finish: {
        name: 'Heure de départ',
        content: e.finish ? e.finish : '-',
      },

      day: {
        name: 'Jour',
        content: e.day ? e.day : '-',
      },

      dropoff1: {
        name: 'Créneau de livraison 1',
        content: e.dropoff1 ? e.dropoff1 : '-',
      },
      dropoff2: {
        name: 'Créneau de livraison 2',
        content: e.dropoff2 ? e.dropoff2 : '-',
      },
      locationId: {
        name: "Addresse d'arrivée",
        content: e.location_name ? e.location_name : '-',
      },
      volume: {
        name: 'Volume',
        content: e?.volume >= 0 && e?.volume != null ? e.volume : '-',
      },
      weight: {
        name: 'Poids',
        content: e?.weight >= 0 && e?.weight != null ? e.weight : '-',
      },

      montantPrestataireHT: {
        name: 'Prix',
        content:
          e?.montantPrestataireHT >= 0 && e?.montantPrestataireHT != null
            ? e.montantPrestataireHT
            : '-',
      },
      salesChannel: {
        name: 'Canal Vente',
        content: e?.salesChanel?.name ? e?.salesChanel?.name : '-',
      },
      client: {
        name: 'Client',
        content: e?.client ? e?.client : '-',
      },
      orderGiver: {
        name: "Donneur d'ordre",
        content: e?.orderGiver ? e?.orderGiver : '-',
      },
      prestaTime: {
        name: 'Temps prestation',
        content: e?.prestaTime ? e?.prestaTime : '-',
      },
      city: {
        name: 'Ville',
        content: e?.city ? e?.city : '-',
      },
      distance: {
        name: 'distance',
        content: e?.distance == 0 ? '0' : e?.distance ? e?.distance : '-',
      },
      codePostale: {
        name: 'Code postale',
        content: e?.codePostale ? e?.codePostale : '-',
      },
      keys: {
        name: 'Compétences',
        content: e?.keys ? e?.keys.join(', ') : '-',
      },
    }
  }
  const getDraggingItemStyle = (draggableStyle, isDragging, type, selected) => {
    return {
      userSelect: 'none',
      padding: 2,
      backgroundColor: isDragging
        ? 'rgba(245,245,245, 0.75)'
        : selected
        ? 'lightgrey'
        : type === 'pickup'
        ? '#E3EFFA'
        : 'none',
      ...draggableStyle,
    }
  }
  const handleCheckAllVisit = (indexPlanning) => {
    handleCheckAll(indexPlanning)
  }

  const handleCheckVisit = (indexPlanning, indexVisit, RecList) => {
    handleCheck(indexPlanning, indexVisit, RecList)
  }
  return (
    <div style={{ width: '100%' }}>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Paper
          className="order-table-root"
          style={{ backgroundColor: 'transparent' }}
          key={index}
        >
          <TableContainer
            key={index}
            sx={{
              maxHeight: '300px',
              minHeight: '150px',
            }}
          >
            <Table
              key={index}
              stickyHeader
              aria-label="sticky table"
              sx={{
                boxShadow:
                  ' rgb(51 51 51 / 10%) 0px 5px 5px -5px, rgb(51 51 51) 5px 0px 5px -5px',
              }}
            >
              <TableHead>
                {[
                  ...(tours?.length > 2 ? checkActionsToursTable : []),
                  ...(isOptimisationCoursesFiles
                    ? labelsToursTableOptimisationFile
                    : labelsToursTable),
                  ...settingsTable,
                ].map((column, index) =>
                  column.id === 'check' ? (
                    tours?.length > 2 ? (
                      <TableCell key={`column_${index}`} align="center">
                        <Checkbox
                          disabled={lock}
                          checked={checkedAll}
                          onChange={() => {
                            handleCheckAllVisit(indexPlanning)
                          }}
                        />
                      </TableCell>
                    ) : (
                      <TableCell key={`column_${index}`} align="center"></TableCell>
                    )
                  ) : column.id === 'settings' ? (
                    <MoveAction
                      index={index}
                      planning={planning}
                      tourAction={tour}
                      disabledActionMov={disabledActionMov}
                      isTableTournee={true}
                      moveTableTournee={moveTableTournee}
                      deattributeCourse={deattributeCourse}
                      disabledAction={false}
                    />
                  ) : (
                    <TableCell key={`column_${index}`} align="center">
                      {column.label}
                    </TableCell>
                  )
                )}
              </TableHead>

              <Droppable
                droppableId={String(index)}
                direction="vertical"
                isDropDisabled={isRecalculateLoading || !isOpen || lock}
              >
                {/**we can add type prop  for further nested drag and drop usage */}
                {(provided) => (
                  <TableBody
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    id={`Tableau-Chauffeur-${index}`}
                  >
                    {tours.map((e, i) => {
                      return (
                        <Draggable
                          disabled
                          key={e.location_id.concat(e.type ? e.type : '')}
                          draggableId={e.location_id.concat(e.type ? e.type : '')}
                          index={
                            isOptimisationCoursesFiles ? i + tours.length - 2 : i
                          }
                          isDragDisabled={
                            e.type === undefined ||
                            e.type == 'depotEnd' ||
                            e.type == 'depotStart' ||
                            isRecalculateLoading ||
                            !isOpen ||
                            lock
                          }
                        >
                          {(provided, snapshot) => (
                            <TableRow
                              key={i}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              sx={getDraggingItemStyle(
                                provided.draggableProps.style,
                                snapshot.isDragging,
                                e.type,
                                e.checked
                              )}
                              id={e.ldv ?? e.location_id}

                              // draggable="true"
                            >
                              {e.type !== 'depotEnd' && e.type !== 'depotStart' ? (
                                <TableCell>
                                  {snapshot.isDragging ? (
                                    <Badge
                                      badgeContent={e.checked ? nbVisitChecked : 1}
                                      color="primary"
                                      anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                      }}
                                      style={{ top: '-10px', paddingRight: '5px' }}
                                    ></Badge>
                                  ) : (
                                    <Checkbox
                                      disabled={lock}
                                      checked={e.checked}
                                      onChange={() => {
                                        handleCheckVisit(
                                          indexPlanning,
                                          e.indexVisit,
                                          e.receptionNumber
                                        )
                                      }}
                                      component={'td'}
                                      style={{ padding: 0 }}
                                    />
                                  )}
                                </TableCell>
                              ) : (
                                (e.type == 'depotEnd' || e.type == 'depotStart') &&
                                tours?.length > 2 && <TableCell></TableCell>
                              )}
                              {[
                                ...(isOptimisationCoursesFiles
                                  ? labelsToursTableOptimisationFile
                                  : labelsToursTable),
                              ].map((el, index) => {
                                if (
                                  initialSimulationTabHeader(e)[el.id].name ==
                                  'Action'
                                ) {
                                  return (
                                    <TableCell key={index}>
                                      <img src={VolumeIcon} onClick={() => {}} />
                                    </TableCell>
                                  )
                                } else if (
                                  initialSimulationTabHeader(e)[el.id].name ==
                                    'Heure d’arrivée' &&
                                  (e.idleTime > 0 || e.lateBy > 0)
                                ) {
                                  return (
                                    <Tooltip
                                      key={index}
                                      title={
                                        <span className="tootltip-content">
                                          {e.idleTime > 0
                                            ? `${convertirMinutesEnHeuresEtMinutes(
                                                e.idleTime
                                              )} avance`
                                            : `${convertirMinutesEnHeuresEtMinutes(
                                                e.lateBy
                                              )} retard`}
                                        </span>
                                      }
                                      arrow
                                    >
                                      <TableCell
                                        sx={{
                                          color: '#932121',
                                        }}
                                      >
                                        <IdleTimeIcon />
                                        <span
                                          style={{ fontSize: '12px !important' }}
                                        >
                                          {e.arrival}
                                        </span>
                                      </TableCell>
                                    </Tooltip>
                                  )
                                } else if (
                                  initialSimulationTabHeader(e)[el.id].name ==
                                    'Heure de départ' &&
                                  e.slotOverRun > 0
                                ) {
                                  return (
                                    <Tooltip
                                      key={index}
                                      title={
                                        <span className="tootltip-content">
                                          {`${convertirMinutesEnHeuresEtMinutes(
                                            e.slotOverRun
                                          )} minutes de Dépassement `}
                                        </span>
                                      }
                                      arrow
                                    >
                                      <TableCell
                                        sx={{
                                          color: '#932121',
                                        }}
                                      >
                                        <IdleTimeIcon />
                                        <span
                                          style={{ fontSize: '12px !important' }}
                                        >
                                          {e.finish}
                                        </span>
                                      </TableCell>
                                    </Tooltip>
                                  )
                                } else if (
                                  initialSimulationTabHeader(e)[el.id].name ==
                                    'Order' &&
                                  e?.rejectedKeys?.length > 0 &&
                                  e.order != '0' &&
                                  e.order != `${tours.length - 1} ` &&
                                  isOptimisationCoursesFiles
                                ) {
                                  return (
                                    <Tooltip
                                      key={index}
                                      title={
                                        <span className="tootltip-content">{`${e?.rejectedKeys}`}</span>
                                      }
                                      arrow
                                      alignItems="left"
                                    >
                                      <TableCell
                                        align="left"
                                        sx={{
                                          color: '#f7740f',
                                        }}
                                      >
                                        <WarningAmberOutlinedIcon
                                          sx={{
                                            verticalAlign: 'sub',
                                            marginRight: '3px',
                                          }}
                                        />
                                        <span
                                          style={{ fontSize: '12px !important' }}
                                        >
                                          {e.order}
                                        </span>
                                      </TableCell>
                                    </Tooltip>
                                  )
                                } else
                                  return (
                                    <LongTextComponent
                                      display={true}
                                      text={
                                        initialSimulationTabHeader(e)[el.id].content
                                      }
                                      key={index}
                                      style={e.too_late ? { color: 'red' } : {}}
                                      id={`item-${i}-${index}`}
                                    />
                                  )
                              })}
                            </TableRow>
                          )}
                        </Draggable>
                      )
                    })}
                  </TableBody>
                )}
              </Droppable>
            </Table>
          </TableContainer>
        </Paper>
      </DragDropContext>
    </div>
  )
}

ToursTable.propTypes = {
  tours: PropTypes.array,
  index: PropTypes.number,
  isRecalculateLoading: PropTypes.bool,
  isOpen: PropTypes.bool,
  handleCheck: PropTypes.func,
  handleCheckAll: PropTypes.func,
  indexPlanning: PropTypes.number,
  checkedAll: PropTypes.bool,
  nbVisitChecked: PropTypes.number,
  getCourseType: PropTypes.func,
  isOptimisationCoursesFiles: PropTypes.bool,
  lock: PropTypes.bool,
  planning: PropTypes.any,
  tour: PropTypes.object,
  disabledActionMov: PropTypes.bool,
  moveTableTournee: PropTypes.func,
  deattributeCourse: PropTypes.func,
  handleDragEnd: PropTypes.func,
}
