import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Box from '@mui/material/Box'
import DashTab from '../../../../containers/main/Entrepot/dashboard/DashTabs'
import DashStatic from '../../../../containers/main/Entrepot/dashboard/DashStatic'
import DashParCanal from './TabPanels/DashParCanal'

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ padding: '10px' }}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

export default function DashboardEntrepot() {
  const [value, setValue] = useState(0)
  const [canalVente, setCanalVente] = useState(null)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box
        sx={{
          boxShadow:
            '0px 5px 2px -5px rgb(0 0 0 / 20%), 0px 0px 7px 2px rgb(0 0 0 / 14%), 0px 6px 30px 5px rgb(0 0 0 / 12%)',
          backgroundColor: 'rgb(248 248 248)',
          width: '100%',
          top: '55px',
          zIndex: '3',
          position: 'sticky',
        }}
      >
        <DashTab
          handleChange={handleChange}
          value={value}
          canalVente={canalVente}
          setCanalVente={setCanalVente}
        />
      </Box>
      {(value == 0 || value == 1 || value == 2) && (
        <CustomTabPanel value={value} index={value}>
          <DashStatic value={value} />
        </CustomTabPanel>
      )}
      {value == 3 && (
        <CustomTabPanel value={value} index={3}>
          <DashParCanal canalVente={canalVente} />
        </CustomTabPanel>
      )}
    </Box>
  )
}
