// check list

import React, { useEffect, useState } from 'react'
import Dialog from '../../../../../components/ModalDialog'
import PropTypes from 'prop-types'
import CreateIcon from '../../../../../assets/icons/settingsCorner/iconsCreate'
import { getName } from '../../../../../utils/values'
import { TextField } from '@mui/material'
import { styled } from '@mui/material/styles'
import AutoCompleteSearch from '../../../../../components/ListWithSearch/selectListWithSearch/AutoCompleteSearch'

export const StyledTextField = styled(TextField)(() => ({
  backgroundColor: 'white',

  '& .MuiOutlinedInput-notchedOutline': {
    borderWidth: '1px',
    borderColor: '#E2F0FE ',
    '&:hover': {
      borderColor: '#E2F0FE',
    },
  },

  '&.Mui-focused & .MuiOutlinedInput-notchedOutline': {
    borderColor: '#E2F0FE',
  },
}))

const DriverModal = ({
  openDriverModal,
  setOpenDriverModal,
  drivers,
  generateOptimizedTours,
  notEmptyTours,
}) => {
  const [index, setIndex] = useState(25)
  const [selected, setSelected] = useState([])
  const [checkedAll, setCheckedAll] = useState(true)
  const [search, setSearch] = useState('')
  const [isMoreLoading, setIsMoreLoading] = useState(false)
  const [driversList, setDriversList] = useState([])

  useEffect(() => {
    initDrivers()
  }, [drivers])

  const initDrivers = () => {
    const list = drivers?.map(({ id, firstName, name }) => {
      return {
        name: getName(firstName, name),
        code: id,
      }
    })
    setDriversList(list)
  }

  //FETCH more data with search
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search.length > 0) {
        onSearch(search.trim())
      } else {
        initDrivers()
        setIndex(25)
      }
    }, 500)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  const onChangeSearch = (value) => {
    let val = value
    setSearch(val)
    setIndex(25)
  }

  const onSearch = (search) => {
    const filteredSuggestions = driversList
      .filter((suggestion) =>
        suggestion.name.toLowerCase().includes(search.toLowerCase())
      )
      .filter((item) => item)
    setDriversList(filteredSuggestions)
  }

  //FETCH more data with scroll
  const fetchMoreData = (event) => {
    if (
      Math.round(event.target.scrollHeight - event.target.scrollTop) <=
        event.target.clientHeight &&
      index < drivers.length &&
      !isMoreLoading
    ) {
      setIsMoreLoading(true)
      setTimeout(() => {
        setIndex(index + 25)
        setDriversList(driversList)
        setIsMoreLoading(false)
      }, 500)
    }
  }

  // Click single data
  const handleToggle = (value) => () => {
    const selectedIndex = selected.indexOf(value)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, value)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }
    setSelected(newSelected)
  }
  const handleDoubleClick = (value) => () => {
    setSelected([value])
  }
  // Click All list DATA
  const handleToggleAll = (event) => {
    setCheckedAll(event.target.checked)
    if (event.target.checked) {
      const newSelecteds = drivers.map((d) => d.id)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }
  const handleDoubleClickAll = () => {
    setCheckedAll(!checkedAll)
    if (!checkedAll) {
      const newSelecteds = drivers.map((n) => n.id)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }
  //Verif checked element
  const isSelected = (id) => selected.indexOf(id) !== -1

  const onConfirm = () => {
    let selectedDrivers = driversList.filter((driver) =>
      selected.includes(driver.code)
    )

    generateOptimizedTours(selectedDrivers)
    setOpenDriverModal(false)
  }
  return (
    <>
      <Dialog
        isClosedIcon
        handleClose={() => setOpenDriverModal(false)}
        maxWidthDialog={'md'}
        open={openDriverModal}
        iconTitle={<CreateIcon />}
        title={'Optimiser'}
        style={{
          '& .MuiDialog-paper': {
            width: '417px',
            height: '490px',
            '& .MuiDialogContent-root': {
              overflow: 'hidden',
            },
          },
        }}
        content={
          <AutoCompleteSearch
            customStyle={{
              listItem: {
                height: '44px',
                borderBottom: '1px solid #E0E0E0',
              },
              listItemText: {
                '& .MuiListItemText-primary': {
                  fontSize: '14px',
                },
              },
              list: {
                paddingRight: '16px',
              },
              listDrivers: {
                height: '215px',
                paddingRight: '16px',
              },
              searchBar: {
                position: 'sticky',
              },
            }}
            notEmptyTours={notEmptyTours}
            dataList={driversList}
            handleToggle={handleToggle}
            handleDoubleClick={handleDoubleClick}
            fetchMoreData={fetchMoreData}
            onChangeSearch={onChangeSearch}
            search={search}
            handleDoubleClickAll={handleDoubleClickAll}
            handleToggleAll={handleToggleAll}
            numSelected={selected.length === drivers.length}
            isSelected={isSelected}
            autoFocus={true}
          />
        }
        handleClickAction={onConfirm}
        disabled={selected.length === 0}
      />
    </>
  )
}
DriverModal.propTypes = {
  openDriverModal: PropTypes.bool,
  setOpenDriverModal: PropTypes.func,
  drivers: PropTypes.array,
  generateOptimizedTours: PropTypes.func,
  notEmptyTours: PropTypes.array,
  selectedCourses: PropTypes.array,
}
export default DriverModal
