import {
  GET_ETIQUETTE_LIST,
  GET_ETIQUETTE_LIST_SUCCESS,
  GET_ETIQUETTE_LIST_FAILURE,
  GET_ETIQUETTE_LIST_MORE_SUCCESS,
  GET_ETIQUETTE_LIST_MORE_FAILURE,
  GET_LIVRAISON_LIST,
  GET_LIVRAISON_LIST_SUCCESS,
  GET_LIVRAISON_LIST_FAILURE,
  GET_FREIGHTS_LIST,
  GET_FREIGHTS_LIST_SUCCESS,
  GET_FREIGHTS_LIST_FAILURE,
  CLOTURE_RECEPTION_FREIGHTS,
  CLOTURE_RECEPTION_FREIGHTS_SUCCESS,
  CLOTURE_RECEPTION_FREIGHTS_FAILURE,
  GET_HISTORIQUE_FREIGHTS,
  GET_HISTORIQUE_FREIGHTS_SUCCESS,
  GET_HISTORIQUE_FREIGHTS_FAILURE,
  GET_CANAL_VENTE_DASH,
  GET_CANAL_VENTE_DASH_SUCCESS,
  GET_CANAL_VENTE_DASH_MORE_SUCCESS,
  GET_CANAL_VENTE_DASH_FAILURE,
} from './constants'
import onFailure from '../OnFailure'

import initialState from './initialState'

export default (state = initialState(), action) => {
  if (action.type === GET_ETIQUETTE_LIST) {
    return {
      ...state,
      etiquetteList: [],
      etiquettesCount: 0,
      isLoadingEtiquette: true,
    }
  }
  if (action.type === GET_ETIQUETTE_LIST_SUCCESS) {
    const { data, count } = action.payload.data
    return {
      ...state,
      etiquetteList: data,
      etiquettesCount: count,
      isLoadingEtiquette: false,
    }
  }

  if (action.type === GET_ETIQUETTE_LIST_MORE_SUCCESS) {
    const { data, count } = action.payload.data
    return {
      ...state,
      etiquetteList: [...state.etiquetteList, ...data],
      etiquettesCount: count,
      isLoadingEtiquette: false,
    }
  }

  if (
    action.type === GET_ETIQUETTE_LIST_FAILURE ||
    action.type === GET_ETIQUETTE_LIST_MORE_FAILURE
  ) {
    return {
      ...state,
      isLoadingEtiquette: false,
      etiquetteList: [],
    }
  }

  if (action.type === GET_LIVRAISON_LIST) {
    return {
      ...state,
      errorLivraison: null,
      isLoadingLivraison: true,
    }
  }

  if (action.type === GET_LIVRAISON_LIST_SUCCESS) {
    const { data, count } = action.payload.data
    return {
      ...state,
      livraisonList: data,
      countLivraison: count,
      errorLivraison: null,
      isLoadingLivraison: false,
    }
  }

  if (action.type === GET_LIVRAISON_LIST_FAILURE) {
    const errorLivraison = action.payload.data.data?.message
    return {
      ...state,
      errorLivraison,
      isLoadingLivraison: false,
    }
  }

  if (action.type === GET_FREIGHTS_LIST) {
    return {
      ...state,
      errorFreights: null,
      isLoadingFreights: true,
      isActionsFreights: false,
    }
  }

  if (action.type === GET_FREIGHTS_LIST_SUCCESS) {
    const { data, count } = action.payload.data
    return {
      ...state,
      freightsList: data || [],
      countFreights: count,
      errorFreights: null,
      isLoadingFreights: false,
    }
  }

  if (action.type === GET_FREIGHTS_LIST_FAILURE) {
    const errorFreights = onFailure(action.payload)

    return {
      ...state,
      errorFreights,
      isLoadingFreights: false,
    }
  }

  if (action.type === CLOTURE_RECEPTION_FREIGHTS) {
    return {
      ...state,
      errorFreights: null,
      isActionsFreights: false,
      isLoadingActionsFreights: true,
    }
  }

  if (action.type === CLOTURE_RECEPTION_FREIGHTS_SUCCESS) {
    return {
      ...state,
      isActionsFreights: true,
      isLoadingActionsFreights: false,
    }
  }

  if (action.type === CLOTURE_RECEPTION_FREIGHTS_FAILURE) {
    return {
      ...state,
      errorFreights: "une erreur s'est produite merci d'actualiser la page",
      isLoadingActionsFreights: false,
    }
  }

  if (action.type === GET_HISTORIQUE_FREIGHTS) {
    return {
      ...state,
      errorFreights: null,
      historiquesFreights: [],
      isLoadingHistoriquesFreights: true,
    }
  }

  if (action.type === GET_HISTORIQUE_FREIGHTS_SUCCESS) {
    const { result } = action.payload.data

    return {
      ...state,
      historiquesFreights: result || [],
      isLoadingHistoriquesFreights: false,
    }
  }

  if (action.type === GET_HISTORIQUE_FREIGHTS_FAILURE) {
    const errorFreights = onFailure(action.payload)
    return {
      ...state,
      errorFreights,
      isLoadingHistoriquesFreights: false,
    }
  }

  if (action.type === GET_CANAL_VENTE_DASH) {
    return {
      ...state,
      errorCanalVentes: null,
      isLoadingCanalVentes: true,
    }
  }

  if (action.type === GET_CANAL_VENTE_DASH_SUCCESS) {
    const { data, count } = action.payload.data.response

    return {
      ...state,
      canalVentes: data,
      canalVentesCount: count,
      isLoadingCanalVentes: false,
    }
  }

  if (action.type === GET_CANAL_VENTE_DASH_MORE_SUCCESS) {
    const { data, count } = action.payload.data
    return {
      ...state,
      canalVentes: [...state.canalVentes, ...data],
      canalVentesCount: count,
      isLoadingCanalVentes: false,
    }
  }

  if (action.type === GET_CANAL_VENTE_DASH_FAILURE) {
    const errorCanalVentes = onFailure(action.payload)
    return {
      ...state,
      errorCanalVentes,
      isLoadingCanalVentes: false,
    }
  }
  return state
}
