import React, { useEffect } from 'react'

import PropTypes from 'prop-types'

import Filter from './components/filter'
import Box from '@mui/material/Box'
import { StyledCell } from './style'

export default function TableCellCustomized({
  key,
  column,
  alignCell,
  filterCourseFile,
  setFilters,
  filtersValues,
  isOptimisationCoursesFiles,
}) {
  const onChangeFilter = (filterValuesDict) => {
    const filter = {}
    Object.keys(filterValuesDict).forEach((val) => {
      switch (val) {
        case 'keys':
          filter.keys = filterValuesDict[val]
            .map((el) => {
              switch (el) {
                case 'TOUS':
                  return []
                default:
                  return el
              }
            })
            .flat(1)
          break
        case 'receptionNumber':
          filter.receptionNumber = filterValuesDict[val]
            .map((el) => {
              switch (el) {
                case 'TOUS':
                  return []
                default:
                  return el
              }
            })
            .flat(1)
          break
        case 'client':
          filter.client = filterValuesDict[val]
            .map((el) => {
              switch (el) {
                case 'TOUS':
                  return []
                default:
                  return el
              }
            })
            .flat(1)
          break
        case 'city':
          filter.city = filterValuesDict[val]
            .map((el) => {
              switch (el) {
                case 'TOUS':
                  return []
                default:
                  return el
              }
            })
            .flat(1)
          break
        case 'weight':
          filter.weight = filterValuesDict[val]
            .map((el) => {
              switch (el) {
                case 'TOUS':
                  return []
                default:
                  return el
              }
            })
            .flat(1)
          break
        case 'courseType':
          filter.courseType = filterValuesDict[val]
            .map((el) => {
              switch (el) {
                case 'TOUS':
                  return []
                default:
                  return el
              }
            })
            .flat(1)
          break
        case 'endLocation':
          filter.endLocation = filterValuesDict[val]
            .map((el) => {
              switch (el) {
                case 'TOUS':
                  return []
                default:
                  return el
              }
            })
            .flat(1)
          break
        case 'orderGiver':
          filter.orderGiver = filterValuesDict[val]
            .map((el) => {
              switch (el) {
                case 'TOUS':
                  return []
                default:
                  return el
              }
            })
            .flat(1)
          break
        case 'codePostale':
          filter.codePostale = filterValuesDict[val]
            .map((el) => {
              switch (el) {
                case 'TOUS':
                  return []
                default:
                  return el
              }
            })
            .flat(1)
          break
        default:
          break
      }
    })
    return filter
  }
  const filterStateChanged = (filterName, selectedFields) => {
    const filterValuesDict = {}

    if (selectedFields) {
      Object.assign(filterValuesDict, { [filterName]: selectedFields })
    }

    const filter = onChangeFilter(filterValuesDict)
    setFilters({ ...filtersValues, ...filter })
  }
  useEffect(() => {
    if (isOptimisationCoursesFiles) {
      setFilters({ ...filtersValues })
    }
  }, [])

  const onDataChanged = (filterKey, selectedfilter) => {
    filterStateChanged(filterKey, selectedfilter ? selectedfilter : [])
  }

  return (
    <StyledCell key={key} align={alignCell} style={{ minWidth: column.minWidth }}>
      <Box style={{ display: 'flex', placeContent: 'center' }}>
        {column.label}
        {column.filter && (
          <Filter
            onDataChanged={onDataChanged}
            dataList={filterCourseFile[column.codeFilter]}
            filterKey={column.id}
            filtersValues={filtersValues[column.id]}
            id={key}
          />
        )}
      </Box>
    </StyledCell>
  )
}
TableCellCustomized.propTypes = {
  key: PropTypes.number,
  column: PropTypes.object,
  alignCell: PropTypes.string,
  filterCourseFile: PropTypes.object,
  setFilters: PropTypes.func,
  filtersValues: PropTypes.object,
  isOptimisationCoursesFiles: PropTypes.bool,
}
