import React, { useEffect } from 'react'
import MenuItem from '@mui/material/MenuItem'
import TableCell from '@mui/material/TableCell'
import SettingsIcon from '@mui/icons-material/Settings'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import CheckBoxList from './CheckList'
import PropTypes from 'prop-types'
import './index.scss'
import PopHover from '../../../../components/PopHover'
import PrimaryOutlineNoBorderButton from '../../../../components/Buttons/PrimaryOutlineNoBorderButton'
import DragIndicator from '@mui/icons-material/DragIndicator'

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export default function Filter({
  columns,
  handleColumns,
  index,
  OutercolumnList,
  isProgrammerPage,
}) {
  const COLUMN_LIST = isProgrammerPage ? 'columns_list_prog' : 'columns_list'
  const [checkList, setcheckList] = React.useState(columns)
  const [anchorEl, setAnchorEl] = React.useState(null)
  useEffect(() => {
    const columnList = JSON.parse(
      localStorage.getItem(OutercolumnList ? OutercolumnList : COLUMN_LIST)
    )
    if (columnList) setcheckList(columnList)
    else setcheckList(columns)
  }, [columns])
  const handlecheckBoxChange = (event, index) => {
    const list = [...checkList]
    list[index].checked = event.target.checked
    handleColumns(list)
    localStorage.setItem(
      OutercolumnList ? OutercolumnList : COLUMN_LIST,
      JSON.stringify(list)
    )
    setcheckList(list)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleListOrderChange = (list) => {
    handleColumns(list)
    localStorage.setItem(
      OutercolumnList ? OutercolumnList : COLUMN_LIST,
      JSON.stringify(list)
    )
    setcheckList(list)
  }

  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    const items = reorder(checkList, result.source.index, result.destination.index)
    handleListOrderChange(items)
  }

  const open = Boolean(anchorEl)
  return (
    <>
      <TableCell
        key={`column_${index}`}
        align="center"
        style={{
          backgroundColor: '#F7F8F9',
          color: '#2A304C',
          position: 'sticky',
          top: '0',
          right: '0',
          left: 'auto',
          boxShadow: '-5px 0px 5px -3px #ccc',
        }}
        className="shadowed-cell"
      >
        <SettingsIcon
          style={{
            cursor: 'pointer',
            backgroundColor: '#F7F8F9',
            color: '#2A304C',
            fontSize: 28,
          }}
          onClick={(event) => setAnchorEl(event.currentTarget)}
        />
      </TableCell>

      <PopHover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        content={
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div
                  className="drop-down-content"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {checkList.map((item, index) => (
                    <Draggable key={item.id} draggableId={item.id} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <MenuItem
                            sx={{
                              width: 200,
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                            key={index}
                            value={30}
                          >
                            <CheckBoxList
                              item={item}
                              index={index}
                              handleChange={handlecheckBoxChange}
                            />
                            <DragIndicator sx={{ marginLeft: 'auto' }} />
                          </MenuItem>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        }
        actionButtons={
          <>
            <PrimaryOutlineNoBorderButton
              onClick={handleClose}
              label="Fermer"
              style={{ textTransform: 'none', fontWeight: 400 }}
            />
          </>
        }
        className="pophover-container"
      />
    </>
  )
}
Filter.propTypes = {
  columns: PropTypes.array,
  handleColumns: PropTypes.func,
  index: PropTypes.any,
  OutercolumnList: PropTypes.string,
  isProgrammerPage: PropTypes.bool,
}
