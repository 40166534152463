import React, { useEffect, useState } from 'react'
import GoogleMapContainer from '../../../../containers/main/GoogleMapContainer'
import PropTypes from 'prop-types'
import {
  GOOGLE_MAP_CONSTANTS,
  getProgrammerMarkerIcon,
  findPinColor,
} from '../../../../constants/googleMapConstants'
import MarkerToolTip from '../../../../components/MarkerToolTip'
import { getPinHourLabel } from '../../../../utils'
import { calculateCenter } from '../../../../utils/googleMapUtils'
import { ClientPickup } from '../../../../utils/values'
const ProgrammerMap = ({
  shipments,
  getGoogleMapDirection,
  directionRoute,
  pickedDate,
  setCheckedCourses,
  hideImpossibleCourses,
  setUnselectTrigger,
  unselectTrigger,
  selectedCourses,
  removeSelectedCourses,
  addSelectedCourses,
  mapContainerStyle,
  mapId,
  removeAllSelected,
}) => {
  const [markers, setMarkers] = useState([])
  const [selectedDirectionRoute, setDirectionRoute] = useState([])
  const [mapOptions, setMapOption] = useState({
    center: {
      lat: GOOGLE_MAP_CONSTANTS.DEFAULT_LATLNG.lat,
      lng: GOOGLE_MAP_CONSTANTS.DEFAULT_LATLNG.lng,
    },
    zoom: 15,
  })

  useEffect(() => {
    return () => {
      removeDirection()
      //resetDirections()
    }
  }, [])

  useEffect(() => {
    let directRoute = []
    if (directionRoute) {
      directRoute.push(directionRoute)
      setDirectionRoute(directRoute)
    } else {
      setDirectionRoute(null)
    }
  }, [directionRoute])
  // useEffect(() => {
  //   calculateDirection(selectedShipment)
  // }, [selectedShipment.origin, selectedShipment.destination, selectedShipment.id])
  useEffect(() => {
    initMarkers(6)
  }, [shipments.codeCourse])
  const initMarkers = (zoom) => {
    const shipmentsMarker = defaultShipmentsMarker()
    setMarkers(shipmentsMarker)
    setMapOption(calculateCenter(shipmentsMarker, zoom, 0))
  }

  const defaultShipmentsMarker = (selectedCourses) => {
    let markersList = []
    if (shipments) {
      shipments.forEach(
        ({
          codeCourse,
          adresseArrivee,
          adresseDepart,
          ldv,
          prix,
          salesChannel,
          status,
          dateDemarrageMeta,
          dateDemarrage,
          weight,
          volume,
          factures,
          courseMetadata,
          dropOffStart,
          dropOffEnd,
        }) => {
          let hourLabel = ''
          if (dropOffStart && dropOffEnd) {
            const dropOff = getPinHourLabel(
              dateDemarrageMeta,
              dropOffStart,
              dropOffEnd
            )
            hourLabel = dropOff
          }
          if (adresseArrivee) {
            markersList.push({
              position: {
                lat: ClientPickup.includes(courseMetadata?.courseType)
                  ? adresseDepart.latitude
                  : adresseArrivee.latitude,
                lng: ClientPickup.includes(courseMetadata?.courseType)
                  ? adresseDepart.longitude
                  : adresseArrivee.longitude,
              },
              codeCourse: codeCourse,
              isFocused: true,
              infoText: (
                <MarkerToolTip
                  ticket={hourLabel}
                  ldv={ldv}
                  prix={prix}
                  salesChannel={salesChannel}
                  startDate={dateDemarrage}
                  programmerShipmentData={{
                    weight,
                    volume,
                    factures,
                    courseMetadata,
                    status,
                    pickedDate,
                    adresse: ClientPickup.includes(courseMetadata?.courseType)
                      ? adresseDepart
                      : adresseArrivee,
                  }}
                />
              ),
              ticket: hourLabel,
              action: () =>
                calculateDirection({
                  origin: adresseDepart,
                  destination: adresseArrivee,
                  status,
                }),
              icon: getProgrammerMarkerIcon(
                pickedDate,
                courseMetadata,
                (selectedCourses?.length > 0 &&
                  selectedCourses.includes(codeCourse)) ||
                  selectedCourses?.length === 0
              ),
              opacity: 1,
              colorCode: findPinColor(
                pickedDate,
                courseMetadata,
                (selectedCourses?.length > 0 &&
                  selectedCourses.includes(codeCourse)) ||
                  selectedCourses?.length === 0
              ),
              zIndex:
                selectedCourses?.length > 0 && selectedCourses.includes(codeCourse)
                  ? 1
                  : -8,
            })
          }
        }
      )
    }

    return markersList
  }
  const calculateDirection = (selecetedRoute) => {
    if (selecetedRoute) {
      if (selecetedRoute.origin && selecetedRoute.destination) {
        const { origin, destination, id } = selecetedRoute
        getGoogleMapDirection(
          id,
          { lat: origin.latitude, lng: origin.longitude },
          { lat: destination.latitude, lng: destination.longitude }
        )
      }
    }
  }
  const removeDirection = () => {
    initMarkers(9)
    setDirectionRoute([])
  }
  const filterMarkers = () => {
    const possibleMarkers = markers.filter((marker) => {
      if (marker.colorCode !== 'black' && marker.colorCode !== 'grey') return marker
    })
    return possibleMarkers
  }
  // const onSetCheckedCourses = (selected) => {
  //   setCheckedCourses([...selected,...selectedCourses])
  //   // const shipmentsMarker = defaultShipmentsMarker(selected)
  //   // setMarkers(shipmentsMarker)
  // }
  useEffect(() => {
    const shipmentsMarker = defaultShipmentsMarker(selectedCourses)
    setMarkers(shipmentsMarker)
  }, [selectedCourses])

  return (
    <GoogleMapContainer
      mapContainerStyle={mapContainerStyle}
      mapId={mapId}
      mapOptions={mapOptions}
      markers={!hideImpossibleCourses ? markers : filterMarkers()}
      directions={selectedDirectionRoute ? selectedDirectionRoute : []}
      isDrawable={true}
      setCheckedCourses={setCheckedCourses}
      removeSelectedCourses={removeSelectedCourses}
      addSelectedCourses={addSelectedCourses}
      removeAllSelected={removeAllSelected}
      selectedCourses={selectedCourses}
      setUnselectTrigger={setUnselectTrigger}
      unselectTrigger={unselectTrigger}
    />
  )
}

ProgrammerMap.propTypes = {
  shipments: PropTypes.array.isRequired,
  getGoogleMapDirection: PropTypes.func,
  resetDirections: PropTypes.func,
  directionRoute: PropTypes.any,
  setCheckedCourses: PropTypes.any,
  pickedDate: PropTypes.any,
  hideImpossibleCourses: PropTypes.bool,
  setUnselectTrigger: PropTypes.func,
  unselectTrigger: PropTypes.bool,
  selectedCourses: PropTypes.array,
  addSelectedCourses: PropTypes.func,
  removeSelectedCourses: PropTypes.func,
  /** Used for adding style to the container */
  mapContainerStyle: PropTypes.any,
  /** the className of the mapContainer */
  mapId: PropTypes.any,
  removeAllSelected: PropTypes.func,
}
export default ProgrammerMap
