import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import Dialog from '../../../../../../../../components/ModalDialog'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import Divider from '@mui/material/Divider'
import DatePickerLocalisation from '../../../../../../../../components/DatePickerLocalisation'
import moment from 'moment'

import TouchAppIcon from '@mui/icons-material/TouchApp'

import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery'

import { validateUploadImage } from '../../../../../../../../utils/validator/UploadFileDiscussion'
import {
  STEP_PACKAGE_POINTAGE,
  STATUS_PACKAGE_POINTAGE,
} from '../../../../../../../../utils/values'
import BlockViewOtherInfoSTEP from './infoStep'
import BlockViewOtherInfoSTATUS from './infoStatus'
import BlockMDM from './pointageMDM'
import { getStepLabel } from '../../../../../../../../utils/utils'
import { COURSE_SOURCE_ETAT_POINTAGE } from '../../../../../../../../utils/checkActionCourseSource'

function PointagePackageDialog({
  open,
  handleClose,
  connectedUser,
  //Pointage
  onPoint,
  isLoadingPointagePackage,
  //Get data from api
  getPackageStatus,
  packagesStatus,
  isLoadingPackageStatus,
  getPackageLitigeMotif,
  packagesLitigeMotif,
  isLoadingPackageLitigeMotif,
  getPackageLitigeResponsabilite,
  packagesLitigeResponsabilite,
  isLoadingPackageLitigeResponsabilite,
  //Get Packages selected
  packageSelected,
  //get course source
  courseSource,
  courseType,
  //Litige Image
  setErrorUploadFile,
  onUploadImageLitige,
  isLoadingUploadImageLitige,
  setLoadingUploadImageLitige,
  orderPackageLitigeUploadImage,

  //fetch config mdm
  canalconfig,
  getCanalConfigMDM,
  //mdm image
  isLoadingUploadImageMDM,
  onUploadImageMDM,
  setLoadingUploadImageMDM,
  orderPackageMDMUploadImage,
}) {
  const [status, setStatus] = useState('null')
  const [step, setStep] = useState('null')
  const [date, setDate] = useState(new Date())
  const [time, setTime] = useState(moment(new Date()).format('HH:mm'))
  const [codeRayon, setCodeRayon] = useState(undefined)
  const matches = useMediaQuery('(max-width:586px)')
  const [packages, setPackages] = useState(packageSelected)
  const [packageCode, setPackageCode] = useState('')
  const [InfoLabel, setInfoLabel] = useState([])
  const [packageBarcode, setPackageBarcode] = useState('')
  const [showMdm, setShowMdm] = useState(false)
  const [statusPackages, setStatusPackages] = useState(packageSelected)

  //get  DATA from API
  useEffect(() => {
    if (step != 'null') {
      if (
        COURSE_SOURCE_ETAT_POINTAGE.includes(courseSource) &&
        (step == 3 || step == 4)
      ) {
        setStatusPackages(STATUS_PACKAGE_POINTAGE)
      } else if (packagesStatus.length == 0) {
        getPackageStatus()
        setStatusPackages([])
      } else {
        setStatusPackages(packagesStatus)
      }
    }
  }, [step])

  useEffect(() => {
    if (packagesStatus) {
      setStatusPackages(packagesStatus)
    }
  }, [packagesStatus])

  useEffect(() => {
    if (
      !isLoadingPackageStatus &&
      courseSource === 'MDM' &&
      canalconfig.length === 0
    ) {
      getCanalConfigMDM(courseSource)
    }
  }, [courseSource])
  useEffect(() => {
    if (
      (status === 'LR' || status === 'LA' || status === 'LI') &&
      packagesLitigeMotif.length === 0 &&
      !isLoadingPackageLitigeMotif
    ) {
      getPackageLitigeMotif()
    }
    if (
      (status === 'LR' || status === 'LA' || status === 'LI') &&
      packagesLitigeResponsabilite.length === 0 &&
      !isLoadingPackageLitigeResponsabilite
    ) {
      getPackageLitigeResponsabilite()
    }
  }, [status])

  const getInfoByCourseType = (courseType) => {
    const lowerCaseCourseType = courseType.toLowerCase()
    const matchingInfo = canalconfig.find((item) => {
      return item.courseType.toLowerCase() === lowerCaseCourseType && item.infos
    })
    return matchingInfo ? matchingInfo.infos : null
  }
  useEffect(() => {
    if (
      step !== 'null' &&
      status !== 'LR' &&
      status !== 'LA' &&
      status !== 'LI' &&
      status !== 'null'
    ) {
      const labelSelected = getStepLabel(step)
      const resultat = getInfoByCourseType(courseType)
      const options =
        resultat !== null
          ? resultat
              .filter(
                (item) => item.label.toLowerCase() === labelSelected.toLowerCase()
              )
              .map((item) => item.options)
          : []
      if (options.length > 0) {
        setInfoLabel(options)
        setShowMdm(true)
      } else {
        setShowMdm(false)
      }
    }
  }, [step, status])
  //Pointage
  const onClick = () => {
    const packagesPayload = packages.map(
      ({ barcode, code, litigation, pictureAfterPacking, pictureBeforePacking }) => {
        return {
          code,
          barcode,
          deliveryStep: step,
          status: status,
          flashedAt: moment(moment(date).format('YYYY-MM-DD ') + time).format(
            'YYYY-MM-DD HH:mm'
          ),
          idCollaborateur: connectedUser.id,
          source: 'Corner',
          addressBarCode: codeRayon,
          litigation:
            status === 'LR' || status === 'LA' || status === 'LI'
              ? litigation
              : undefined,
          pictureAfterPacking:
            pictureAfterPacking.length > 0 ? pictureAfterPacking : [],
          pictureBeforePacking:
            pictureBeforePacking.length > 0 ? pictureBeforePacking : [],
        }
      }
    )

    onPoint({ packages: packagesPayload })
  }
  //upload image Litige
  const onUpload = (files, codePackage) => {
    setLoadingUploadImageLitige(true)
    setPackageCode(codePackage)
    const error = validateUploadImage(Array.from(files))
    if (error.isError) {
      setErrorUploadFile(error)
      setLoadingUploadImageLitige(false)
    } else {
      const formData = new FormData()
      formData.append('pictures[]', files[0])
      onUploadImageLitige(codePackage, formData)
    }
    files = null
  }
  const onUploadMDM = (files, barcodePackage, value) => {
    setLoadingUploadImageMDM(true)
    setPackageBarcode(barcodePackage)
    const error = validateUploadImage(Array.from(files))
    if (error.isError) {
      setErrorUploadFile(error)
      setLoadingUploadImageMDM(false)
    } else {
      const formData = new FormData()
      formData.append(
        'pictureBeforePacking[]',
        value === 'before' ? files[0] : new File([], [])
      )
      formData.append(
        'pictureAfterPacking[]',
        value === 'after' ? files[0] : new File([], [])
      )
      onUploadImageMDM(barcodePackage, formData)
    }
    files = null
  }

  // Modif litige to package
  const onChangePackageLitigeObservation = (observation, codePackage) => {
    const packMofif = packages.map((pack) => {
      if (pack.code === codePackage) {
        pack = {
          ...pack,
          litigation: {
            ...pack.litigation,
            comment: observation,
          },
        }
      }
      return pack
    })
    setPackages([...packMofif])
  }
  const onChangePackageLitigeMotif = (motif, codePackage) => {
    const packMofif = packages.map((pack) => {
      if (pack.code === codePackage) {
        pack = {
          ...pack,
          litigation: {
            ...pack.litigation,
            motif: motif,
          },
        }
      }
      return pack
    })
    setPackages([...packMofif])
  }
  const onChangePackageLitigeResponsabilite = (responsabilite, codePackage) => {
    const packMofif = packages.map((pack) => {
      if (pack.code === codePackage) {
        pack = {
          ...pack,
          litigation: {
            ...pack.litigation,
            responsible: responsabilite,
          },
        }
      }
      return pack
    })
    setPackages([...packMofif])
  }
  const deleteImageLitigePackages = (codePackage, imageLitige) => {
    const newPackages = packages.map((pack) => {
      if (pack.code === codePackage) {
        const pictures = pack.litigation.pictures
        const indexOfFileSupp = pictures.indexOf(imageLitige)
        pictures.splice(indexOfFileSupp, 1)
        pack = {
          ...pack,
          litigation: {
            ...pack.litigation,
            pictures: pictures,
          },
        }
      }
      return pack
    })
    setPackages([...newPackages])
  }

  useEffect(() => {
    if (orderPackageLitigeUploadImage) {
      const newPackages = packages.map((pack) => {
        if (
          pack.code === orderPackageLitigeUploadImage.codePackage &&
          orderPackageLitigeUploadImage.pictures &&
          orderPackageLitigeUploadImage.pictures.length > 0
        ) {
          const files = pack.litigation.pictures
          files.push(orderPackageLitigeUploadImage.pictures[0])
          pack = {
            ...pack,
            litigation: {
              ...pack.litigation,
              pictures: files,
            },
          }
        }
        return pack
      })
      setPackages([...newPackages])
    }
  }, [orderPackageLitigeUploadImage])

  useEffect(() => {
    if (orderPackageMDMUploadImage) {
      const newPackages = packages.map((pack) => {
        if (
          pack.barcode === orderPackageMDMUploadImage.barcode &&
          ((orderPackageMDMUploadImage.pictureAfterPacking &&
            orderPackageMDMUploadImage.pictureAfterPacking.length > 0) ||
            (orderPackageMDMUploadImage.pictureBeforePacking &&
              orderPackageMDMUploadImage.pictureBeforePacking.length > 0))
        ) {
          let newPack = { ...pack }
          if (
            orderPackageMDMUploadImage.pictureAfterPacking &&
            orderPackageMDMUploadImage.pictureAfterPacking.length > 0
          ) {
            newPack.pictureAfterPacking = [
              ...newPack.pictureAfterPacking,
              orderPackageMDMUploadImage.pictureAfterPacking[0],
            ]
          }
          if (
            orderPackageMDMUploadImage.pictureBeforePacking &&
            orderPackageMDMUploadImage.pictureBeforePacking.length > 0
          ) {
            newPack.pictureBeforePacking = [
              ...newPack.pictureBeforePacking,
              orderPackageMDMUploadImage.pictureBeforePacking[0],
            ]
          }
          return newPack
        }
        return pack
      })
      setPackages(newPackages)
    }
  }, [orderPackageMDMUploadImage])

  const deleteImageMDMPacking = (barcodePackage, imageMDm, value) => {
    const newPackages = packages.map((pack) => {
      if (pack.barcode === barcodePackage) {
        const pictures =
          value === 'before' ? pack.pictureBeforePacking : pack.pictureAfterPacking
        const newPictures = pictures.filter((picture) => picture !== imageMDm)
        return {
          ...pack,
          [value === 'before' ? 'pictureBeforePacking' : 'pictureAfterPacking']:
            newPictures,
        }
      }
      return pack
    })
    setPackages(newPackages)
  }
  //verif validation input
  const verifEmptyInput = () => {
    let validationLitige = false
    if (status === 'LR' || status === 'LA' || status === 'LI') {
      packages.forEach((pack) => {
        if (
          pack.litigation.motif === 'null' ||
          pack.litigation.responsible === 'null' ||
          pack.litigation.pictures.length < 1
        )
          validationLitige = true
        return
      })
    }
    if (showMdm) {
      const requiredmaxBeforePacking =
        InfoLabel[0].picture_before_packing.requiredMax
      const requiredmaxAfterPacking = InfoLabel[0].picture_after_packing.requiredMax
      packages.forEach((pack) => {
        if (
          pack.pictureAfterPacking.length < requiredmaxAfterPacking ||
          pack.pictureBeforePacking.length < requiredmaxBeforePacking
        )
          validationLitige = true
        return
      })
    }
    return step === 'null' || status === 'null' || validationLitige
  }

  return (
    <Dialog
      maxWidthDialog={'md'}
      open={open}
      title={'Pointage packages'}
      iconTitle={
        <TouchAppIcon
          style={{
            width: '20px',
            height: '20px',
            marginRight: '6px',
            color: 'white',
          }}
        />
      }
      content={
        <div
          style={{
            overflow: 'auto',
            width: '600px',
            paddingRight: '16px',
            paddingBottom: '16px',
            '@media (max-width: 680px)': {
              width: 'unset',
            },
          }}
        >
          <div style={{ padding: '15px 24px' }}>
            <div style={{ padding: '10px 0px' }}>
              <div style={{ paddingBottom: '10px', fontWeight: 'bold' }}>Étape*</div>
              <div>
                <TextField
                  id="Listes-Etape-Cloture"
                  select
                  name="Listes-Etape"
                  variant="outlined"
                  onChange={(e) => setStep(e.target.value)}
                  value={step}
                  SelectProps={{
                    MenuProps: {
                      style: { height: 288 },
                    },
                  }}
                >
                  <MenuItem value={'null'} key={0} disabled={true}>
                    {'Listes des étapes'}
                  </MenuItem>
                  <Divider />
                  {STEP_PACKAGE_POINTAGE.map((h, index) => (
                    <MenuItem
                      value={h.code}
                      key={index + 1}
                      id={`etape-Cloture-${index + 1}`}
                    >
                      {h.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </div>
            <div style={{ padding: '10px 0px' }}>
              <div style={{ paddingBottom: '10px', fontWeight: 'bold' }}>État*</div>
              <div>
                <TextField
                  id="Listes-Etat-Cloture"
                  select
                  name="Listes-Etat"
                  variant="outlined"
                  onChange={(e) => setStatus(e.target.value)}
                  value={status}
                  SelectProps={{
                    MenuProps: {
                      style: { height: 288 },
                    },
                  }}
                  disabled={isLoadingPackageStatus || statusPackages.length == 0}
                >
                  <MenuItem value={'null'} key={0} disabled={true}>
                    {'Listes des états'}
                  </MenuItem>
                  <Divider />
                  {statusPackages.map((h, index) => (
                    <MenuItem
                      value={h.code}
                      key={index + 1}
                      id={`etat-Cloture-${index + 1}`}
                    >
                      {h.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
                boxSizing: 'border-box',
              }}
            >
              <div style={{ padding: '10px 0px', width: matches && '100%' }}>
                <div style={{ paddingBottom: '10px', fontWeight: 'bold' }}>
                  Date*
                </div>
                <div>
                  <DatePickerLocalisation
                    disabledDays={[]}
                    openedDays={[]}
                    disablePast={false}
                    handleDateChange={(e) => setDate(e)}
                    withDefaultValue={false}
                    date={date}
                    id="Date-Pointage"
                  />
                </div>
              </div>
              <div style={{ padding: '10px 0px', width: matches && '100%' }}>
                <div style={{ paddingBottom: '10px', fontWeight: 'bold' }}>
                  Heure*
                </div>
                <div style={{ width: matches ? '100%' : '234px' }}>
                  <TextField
                    id="Time-Pointage"
                    type="time"
                    variant="outlined"
                    value={time}
                    onChange={(e) => setTime(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <BlockViewOtherInfoSTEP
              step={step}
              setCodeRayon={setCodeRayon}
              codeRayon={codeRayon}
            />
          </div>
          <BlockViewOtherInfoSTATUS
            packages={packages}
            status={status}
            matches={matches}
            packagesLitigeMotif={packagesLitigeMotif}
            packagesLitigeResponsabilite={packagesLitigeResponsabilite}
            deleteImageLitigePackages={deleteImageLitigePackages}
            isLoadingUploadImageLitige={isLoadingUploadImageLitige}
            packageCode={packageCode}
            onUpload={onUpload}
            onChangePackageLitigeObservation={onChangePackageLitigeObservation}
            onChangePackageLitigeMotif={onChangePackageLitigeMotif}
            onChangePackageLitigeResponsabilite={onChangePackageLitigeResponsabilite}
            isLoadingPackageLitigeResponsabilite={
              isLoadingPackageLitigeResponsabilite
            }
            isLoadingPackageLitigeMotif={isLoadingPackageLitigeMotif}
          />
          {showMdm && (
            <BlockMDM
              packages={packages}
              onUploadMDM={onUploadMDM}
              packageCode={packageBarcode}
              isLoadingUploadImageMDM={isLoadingUploadImageMDM}
              config={InfoLabel}
              deleteImageMDMPacking={deleteImageMDMPacking}
            />
          )}
        </div>
      }
      handleClose={handleClose}
      handleClickAction={onClick}
      disabled={verifEmptyInput() || isLoadingPointagePackage}
      isLoadingButtonAction={isLoadingPointagePackage}
    />
  )
}
PointagePackageDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  onPoint: PropTypes.func,
  packagesStatus: PropTypes.array,
  packagesStep: PropTypes.array,
  isLoadingPointagePackage: PropTypes.bool,
  packageSelected: PropTypes.array,
  onUploadImageLitige: PropTypes.func,
  setErrorUploadFile: PropTypes.func,
  isLoadingUploadImageLitige: PropTypes.bool,
  setLoadingUploadImageLitige: PropTypes.func,
  getPackageLitigeMotif: PropTypes.func,
  getPackageLitigeResponsabilite: PropTypes.func,
  packagesLitigeMotif: PropTypes.array,
  isLoadingPackageLitigeMotif: PropTypes.bool,
  packagesLitigeResponsabilite: PropTypes.array,
  isLoadingPackageLitigeResponsabilite: PropTypes.bool,
  getPackageStatus: PropTypes.func,
  isLoadingPackageStatus: PropTypes.bool,
  orderPackageLitigeUploadImage: PropTypes.object,
  connectedUser: PropTypes.object,
  courseSource: PropTypes.string,
  courseType: PropTypes.string,
  canalconfig: PropTypes.array,
  getCanalConfigMDM: PropTypes.func,
  isLoadingUploadImageMDM: PropTypes.bool,
  onUploadImageMDM: PropTypes.func,
  orderPackageMDMUploadImage: PropTypes.array,
  setLoadingUploadImageMDM: PropTypes.func,
}
export default PointagePackageDialog
