export const checkActionsToursTable = [
  {
    id: 'check',
    label: 'Selectionner',
    name: 'Selectionner',
  },
]
export const labelsToursTable = [
  {
    id: 'order',
    label: 'Ordre',
    minWidth: 20,
    name: 'Order',
  },

  {
    id: 'courseId',
    label: 'Lettre de voiture',
    minWidth: 20,
    name: 'Lettre de voiture',
  },
  {
    id: 'arrival',
    label: 'H. Arrivée',
    minWidth: 20,
    name: 'Heure d’arrivée',
  },
  {
    id: 'finish',
    label: 'H. Départ',
    minWidth: 20,
    name: 'Heure de départ',
  },

  {
    id: 'type',
    label: 'Type',
    minWidth: 20,
    name: 'Type',
  },

  {
    id: 'courseType',
    label: 'Type de course',
    minWidth: 20,
    name: 'Type de course',
  },

  {
    id: 'courseRank',
    label: 'Drop Off',
    minWidth: 20,
    name: 'Drop Off Order',
  },
  {
    id: 'dropoff1',
    label: 'Créneau de livraison ',
    minWidth: 20,
    name: 'Créneau de livraison 1',
  },
  {
    id: 'locationId',
    label: 'Adresse',
    minWidth: 20,
    name: "Addresse d'arrivée",
  },
  {
    id: 'volume',
    label: 'Volume',
    minWidth: 20,
    name: 'Volume',
  },
  {
    id: 'weight',
    label: 'Poids',
    minWidth: 20,
    name: 'Poids',
  },
  {
    id: 'montantPrestataireHT',
    label: 'Prix',
    minWidth: 20,
    name: 'Prix',
  },
  {
    id: 'salesChannel',
    label: 'Canal Vente',
    minWidth: 20,
    name: 'Canal Vente',
  },
  {
    id: 'day',
    label: 'Jour',
    minWidth: 20,
    name: 'Jour',
  },
]

export const settingsTable = [
  {
    id: 'settings',
    label: 'Settings',
    minWidth: 100,
    name: 'Settings',
  },
]

export const labelsToursTableOptimisationFile = [
  {
    id: 'order',
    label: 'Ordre',
    minWidth: 20,
    name: 'Order',
  },
  {
    id: 'receptionNumber',
    label: 'N° cmd',
    minWidth: 20,
    name: 'receptionNumber',
  },
  {
    id: 'arrival',
    label: 'H. Arrivée',
    minWidth: 20,
    name: 'Heure d’arrivée',
  },
  {
    id: 'finish',
    label: 'H. Départ',
    minWidth: 20,
    name: 'Heure de départ',
  },
  // {
  //   id: 'type',
  //   label: 'Type',
  //   minWidth: 20,
  //   name: 'Type',
  // },

  // {
  //   id: 'courseRank',
  //   label: 'Drop Off',
  //   minWidth: 20,
  //   name: 'Drop Off Order',
  // },
  {
    id: 'dropoff1',
    label: 'Créneau 1',
    minWidth: 20,
    name: 'Créneau de livraison 1',
  },
  {
    id: 'dropoff2',
    label: 'Créneau 2',
    minWidth: 20,
    name: 'Créneau de livraison 2',
  },
  {
    id: 'locationId',
    label: 'Adresse',
    minWidth: 20,
    name: "Addresse d'arrivée",
  },
  {
    id: 'city',
    label: 'Ville',
    minWidth: 20,
    name: 'Ville',
  },
  {
    id: 'codePostale',
    label: 'CP',
    minWidth: 20,
    name: 'Code postale',
  },

  {
    id: 'client',
    label: 'Client',
    minWidth: 20,
    name: 'Client',
  },

  {
    id: 'orderGiver',
    label: 'D.O',
    minWidth: 20,
    name: "Donneur d'ordre",
  },
  {
    id: 'volume',
    label: 'Volume',
    minWidth: 20,
    name: 'Volume',
  },
  {
    id: 'weight',
    label: 'Poids',
    minWidth: 20,
    name: 'Poids',
  },
  {
    id: 'courseType',
    label: 'Type',
    minWidth: 20,
    name: 'Type de course',
  },
  {
    id: 'prestaTime',
    label: 'TPS',
    minWidth: 20,
    name: 'Temps prestation',
  },

  {
    id: 'keys',
    label: 'Clés',
    minWidth: 20,
    name: 'Compétences',
  },
  {
    id: 'distance',
    label: 'KM',
    minWidth: 20,
    name: 'distance',
  },
  {
    id: 'montantPrestataireHT',
    label: 'Prix',
    minWidth: 20,
    name: 'Prix',
  },
  // {
  //   id: 'day',
  //   label: 'Jour',
  //   minWidth: 20,
  //   name: 'Jour',
  //

  // },
]
